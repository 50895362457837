<template>
  <div class="feedback-box content h-100">
    <h5 v-if="comments.length" class="px-3 mb-0">All comments</h5>
    <div v-if="comments.length">
      <tab-comment
        v-for="(comment, index) in comments"
        :key="comment.id"
        :index="index"
        :comment="comment"
        :commentLoading="commentLoading"
        :class="{ comment: true, active: activeHighlightIndex === index }"
        ref="comments"
        @click.native="handleClick(index)"
        @update="$emit('updateComment', $event)"
        @delete="$emit('deleteComment', $event)"
      />
    </div>

    <div v-else class="d-flex h-100 p-5">
      <div class="m-auto">
        <empty-state
          class="mt--4"
          title="Highlight content in the submission to add your personal comments to the student essay."
          small
        >
          <b-icon class="mb-4" icon="chat" font-scale="4" />
        </empty-state>
      </div>
    </div>
  </div>
</template>

<script>
import TabComment from "@/components/essay/comments/tab_comment.vue";
import EmptyState from "@/components/global/empty_state.vue";

export default {
  components: {
    TabComment,
    EmptyState,
  },

  props: {
    comments: {
      type: Array,
      required: true,
    },

    activeHighlightIndex: {
      type: Number,
      default: -1,
    },

    commentLoading: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    comments: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          const highlights = this.comments.map((comment, index) => {
            return {
              range: comment.ranges,
              highlightIndex: index + 1,
              refComponent: this.$refs.comments?.[index],
              color: "#F1B500",
              backgroundColor: "#FFFBD9",
            };
          });

          this.$emit("updateHighlights", highlights);
        });
      },
    },
  },

  methods: {
    handleClick(index) {
      this.$emit("updateActiveHighlightIndex", index);
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback-box {
  white-space: pre-wrap;
  border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  height: 100%;
}

.feedback-box.content,
.feedback-box.ql-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
  padding-top: 0px;
}

.feedback-box.content {
  padding-top: 15px;
}

.comment.active {
  background-color: #fffbd9;
}

.mt--4 {
  margin-top: -1rem;
}
</style>
