<script>
import SummarizeView from "@/views/summarize_view.vue";
import FreeToolsWrapper from "@/views/free-tools/free-tools-wrapper.vue";

export default {
  name: "FreeToolsSummarize",
  components: {
    SummarizeView,
    FreeToolsWrapper,
  },
};
</script>

<template>
  <FreeToolsWrapper>
    <SummarizeView requestType="free-tools" />
  </FreeToolsWrapper>
</template>
