<template>
  <div class="d-flex align-items-center mb-4">
    <essay-stats-card
      v-if="studentRank && studentName"
      class="w-100 mr-4"
      :title="`${studentName}’s grade`"
      :count="studentRank"
      :loading="false"
    >
      <template #icon>
        <img class="star" src="../../assets/icons/star.svg" alt="" />
      </template>
      <template #before>
        <b-overlay :show="loading" spinner-small rounding="sm" no-wrap>
          <template #overlay>
            <div />
          </template>
        </b-overlay>
      </template>
    </essay-stats-card>

    <essay-stats-card
      v-else
      class="w-100"
      title="Average score"
      :count="summary?.average || 0"
      :loading="false"
    >
      <template #before>
        <b-overlay :show="loading" spinner-small rounding="sm" no-wrap>
          <template #overlay>
            <b-spinner small variant="info" />
          </template>
        </b-overlay>
      </template>
    </essay-stats-card>
    <!-- TODO: Remove after the release v3.3 or uncomment -->
    <!--<essay-stats-card
      class="w-100 mr-4"
      title="Bottom 25%"
      :count="summary?.bottom || 0"
      :loading="false"
    >
      <template #icon>
        <img src="../../assets/icons/seedling.svg" alt="" />
      </template>
      <template #before>
        <b-overlay :show="loading" spinner-small rounding="sm" no-wrap>
          <template #overlay>
            <b-spinner small variant="info" />
          </template>
        </b-overlay>
      </template>
    </essay-stats-card>
    <essay-stats-card
      class="w-100"
      title="Top 25%"
      :count="summary?.top || 0"
      :loading="false"
    >
      <template #icon>
        <img src="../../assets/icons/medal.svg" alt="" />
      </template>
      <template #before>
        <b-overlay :show="loading" spinner-small rounding="sm" no-wrap>
          <template #overlay>
            <b-spinner small variant="info" />
          </template>
        </b-overlay>
      </template>
    </essay-stats-card>-->
  </div>
</template>

<script>
import EssayStatsCard from "@/components/essay/essay_stats_card.vue";

export default {
  props: {
    summary: {
      type: Object,
    },

    studentRank: {
      type: Number,
    },

    studentName: {
      type: String,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EssayStatsCard,
  },
};
</script>

<style lang="scss" scoped>
.star {
  width: 24px;
  filter: brightness(0) saturate(100%) invert(72%) sepia(85%) saturate(2156%)
    hue-rotate(4deg) brightness(100%) contrast(100%);
}
</style>
