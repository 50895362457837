<template>
  <b-dropdown
    :size="size"
    ref="dropdown"
    :disabled="disabled"
    block
    toggle-class="filter-dropdown"
    menu-class="w-100"
    variant="n"
    :split="split"
    @show="onShow"
    @hide="onHide"
  >
    <template #button-content>
      <slot
        v-if="$scopedSlots['button-content']"
        name="button-content"
        v-bind:toggle="toggle"
      />
      <div v-else class="d-flex align-items-center justify-content-between">
        <span class="text-truncate">{{ text }}</span>
        <b-icon class="ml-auto" icon="chevron-down"></b-icon>
      </div>
    </template>
    <div class="items-wrapper">
      <slot />
    </div>
  </b-dropdown>
</template>

<script>
function isFocusable(el) {
  const cs = window.getComputedStyle(el, null);
  if (
    cs.getPropertyValue("visibility") == "hidden" ||
    cs.getPropertyValue("display") == "none"
  )
    return false;
  const natively =
    'a[href], area[href], details, iframe, :is(button, input:not([type="hidden"]), select, textarea)';

  if (
    el.matches(natively) ||
    (el.hasAttribute("tabindex") &&
      parseInt(el.getAttribute("tabindex")) >= 0) ||
    el.isContentEditable
  )
    return true;
  return false;
}

export default {
  name: "EgDropdown",
  props: {
    text: {
      type: String,
    },
    split: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      openTargetElement: null,
    };
  },

  methods: {
    update() {
      this.$refs.dropdown?.$_popper?.update();
    },

    toggle(e) {
      const isVisible = this.$refs.dropdown.visible;
      isVisible ? this.hide(e) : this.show(e);
    },

    show(e) {
      if (isFocusable(e.target)) {
        this.openTargetElement = e.target;
      }

      this.$refs.dropdown && this.$refs.dropdown.show();
    },

    hide() {
      this.openTargetElement = null;
      this.$refs.dropdown && this.$refs.dropdown.hide();
    },

    onShow(e) {
      if (this.openTargetElement) {
        // twice because need to wait opened popover (part of vue bootstrap)
        this.$nextTick(() => {
          this.$nextTick(() => {
            this.openTargetElement.focus();
          });
        });
      }

      this.$emit("show", e);
    },

    onHide(e) {
      if (this.openTargetElement) {
        e.preventDefault();
        this.openTargetElement = null;
      } else {
        this.openTargetElement = null;
      }

      this.$emit("hide", e);
    },
  },
};
</script>

<style lang="scss" scoped>
.items-wrapper {
  max-height: 300px;
  overflow: auto;
}
.dropdown {
  &::v-deep {
    .dropdown-toggle {
      border: 1px solid #ced4da; // TODO: change to CSS variable

      &::after {
        display: none;
      }
    }
  }
}
</style>
