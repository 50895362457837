<template>
  <div v-if="flag.type === 'plagiarism'">
    <div class="d-flex align-items-center">
      <b-icon variant="danger" class="mr-3" icon="flag" />
      <div class="flag-title">Detected Plagiarism in the essay</div>
    </div>
    <div class="mt-2">
      <div>
        Our detector is <b>highly confident</b> that this submission is
        plagiarized.
      </div>
    </div>
    <div v-if="flag.similarEssay" class="mt-2">
      <b>This submission is similar to:</b>
      <cell-name :item="flag.similarEssay" class="mt-2" />
    </div>
    <div v-if="flag.sentences?.length" class="mt-2">
      <eg-additional-collapse label="Show plagiarized sentences">
        <span v-for="(text, index) in flag.sentences" :key="index">{{
          text
        }}</span>
      </eg-additional-collapse>
    </div>
  </div>
</template>

<script>
import EgAdditionalCollapse from "@/components/global/eg_additional_collapse.vue";

import CellName from "@/components/essay/table/cell_name.vue";

export default {
  props: {
    flag: {
      type: Object,
      required: true,
    },
  },

  components: {
    EgAdditionalCollapse,
    CellName,
  },
};
</script>
