<template>
  <div>
    <eg-virtual-popover
      :x="selectedRangeCoordinate.x"
      :y="selectedRangeCoordinate.y"
      :element-height="38"
      ref="addCommentPopover"
      placement="auto"
      fallback-placement="clockwise"
      triggers="manual"
      boundary="window"
      data-range="0,0"
    >
      <b-icon class="close-icon" icon="x" @click="closeComment" />
      <popover-add-comment @close="closeComment" @save="saveComment" />
      <b-overlay :show="commentLoading" no-wrap> </b-overlay>
    </eg-virtual-popover>
    <HighlightRange
      v-for="(chunk, index) in chunks"
      :key="index"
      :chunk="chunk"
      :activeHighlightIndex="activeHighlightIndex"
      @highlightClicked="!newCommentRange && $emit('highlightClicked', $event)"
    />
  </div>
</template>

<script>
// NOTE: data-range="0,0" on eg-virtual-popover should be because it's child element,
// and we should know where it in range when select text

import EgVirtualPopover from "@/components/global/eg_virtual_popover.vue";
import PopoverAddComment from "@/components/essay/comments/popover_add_comment.vue";
import HighlightRange from "./highlight_range.vue";
import processHighlights from "./processHighlights";

export default {
  name: "HighlightContent",

  components: {
    HighlightRange,
    EgVirtualPopover,
    PopoverAddComment,
  },

  props: {
    content: {
      type: String,
      required: true,
    },

    highlights: {
      type: Array,
      default: () => [],
    },

    activeHighlightIndex: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      commentLoading: false,
      selecting: false,
      newCommentRange: null,
      selectedRangeCoordinate: { x: 0, y: 0 },
    };
  },

  computed: {
    chunks() {
      // NOTE; if we select text for comment - delete all another highlights
      if (this.newCommentRange) {
        return processHighlights(
          this.content,
          [
            {
              highlightIndex: 0,
              type: "selection",
              range: this.newCommentRange,
              color: "#F1B500",
              backgroundColor: "#FFFBD9",
            },
          ],
          [0, this.content.length]
        );
      }

      return processHighlights(
        this.content,
        [
          ...this.highlights,
          ...(this.newCommentRange
            ? [
                {
                  highlightIndex: 0,
                  type: "selection",
                  range: this.newCommentRange,
                  color: "#F1B500",
                  backgroundColor: "#FFFBD9",
                },
              ]
            : []),
        ],
        [0, this.content.length]
      );
    },
  },

  mounted() {
    document.addEventListener("mouseup", this.onMouseup);
  },

  beforeDestroy() {
    document.removeEventListener("mouseup", this.onMouseup);
  },

  methods: {
    closeComment() {
      this.$refs.addCommentPopover?.close();
      this.newCommentRange = null;
      // NOTE because of hide animation, we should clear selectedRangeCoordinate after animation is ended
      setTimeout(() => {
        this.selectedRangeCoordinate = { x: 0, y: 0 };
      }, 100);
    },

    checkIsSelecting() {
      const selection = document.getSelection();

      if (selection.type !== "Range") return;

      const text = selection.toString();
      const range = selection.getRangeAt(0);

      // NOTE close the popup when no text is selected or if select not in content
      if (
        !this.$el.contains(range.commonAncestorContainer) ||
        text.length === 0
      ) {
        return false;
      }

      return true;
    },

    onSelectionEnd() {
      const selection = document.getSelection();
      const text = selection.toString();
      const range = selection.getRangeAt(0);

      let elementDatasetRange = "0,0";

      if (range.commonAncestorContainer.nodeType === Node.TEXT_NODE) {
        elementDatasetRange =
          range.commonAncestorContainer.parentNode.dataset.range;
      } else if (range.startContainer.nodeType === Node.TEXT_NODE) {
        elementDatasetRange = range.startContainer.parentNode.dataset.range;
      } else {
        const startElement = [...range.commonAncestorContainer.childNodes].find(
          (el) => el.contains(range.startContainer)
        );

        elementDatasetRange = startElement.dataset.range;
      }

      const [start] = elementDatasetRange.split(",").map(Number);

      const selectedRange = [
        start + range.startOffset,
        start + range.startOffset + text.length,
      ];

      this.newCommentRange = selectedRange;
      this.selectedRangeCoordinate = this.getCharacterCoordinates(
        selectedRange[1]
      );
      this.$refs.addCommentPopover?.open();
      this.$emit("updateActiveHighlightIndex", -1);
    },

    onMouseup(e) {
      const isSelecting = this.checkIsSelecting();

      if (isSelecting) {
        e.stopPropagation();
        this.onSelectionEnd();
      }
    },

    handleSelectionChange() {
      // NOTE avoid selecting everywhere on page
      const selection = document.getSelection();
      const text = selection.toString();
      const range = selection.getRangeAt(0);

      if (
        !this.$el.contains(range.commonAncestorContainer) ||
        text.length === 0
      ) {
        return;
      }

      this.selecting = true;
    },

    // used by parents to get coordinate of character by character index on the content
    getCharacterCoordinates(charIndex, container = this.$el) {
      if (!container || typeof charIndex !== "number") {
        throw new Error(
          "Invalid arguments: container and charIndex are required."
        );
      }

      let currentIndex = 0; // current text index
      let targetNode = null; // node, where char exists
      let offsetInNode = 0;

      // recursive go throw all text nodes
      function findNode(node) {
        if (node.nodeType === Node.TEXT_NODE) {
          const textLength = node.textContent.length;

          if (currentIndex + textLength >= charIndex) {
            // if index exists in node
            targetNode = node;
            offsetInNode = charIndex - currentIndex;
            return true; // stop finding
          }

          currentIndex += textLength;
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          // go deeper
          for (let child of node.childNodes) {
            if (findNode(child)) return true;
          }
        }

        return false;
      }

      if (!findNode(container)) {
        return null; // if no char in container
      }

      //range need to get coordinates of character
      const range = document.createRange();
      range.setStart(targetNode, offsetInNode);
      range.setEnd(targetNode, Math.min(targetNode.length, offsetInNode + 1)); // one symbol

      const charRect = range.getBoundingClientRect(); // x and y coordinates of character relative to document
      const containerRect = container.getBoundingClientRect();

      // calculate x and y coordinates relative to container
      return {
        x: charRect.x - containerRect.x,
        y: charRect.y - containerRect.y,
      };
    },

    saveComment(comment) {
      this.commentLoading = true;
      this.$emit("addComment", {
        ranges: this.newCommentRange,
        text: comment,
        resolve: () => {
          this.commentLoading = false;
          this.closeComment();
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.close-icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
</style>
