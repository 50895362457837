<template>
  <div class="position-relative" id="essay-content-container">
    <div class="text-right mb-2">
      <b-button
        size="sm"
        variant="outline-info"
        @click="showHighlight = !showHighlight"
      >
        {{ showHighlight ? "Hide" : "Show" }} Highlights
      </b-button>
    </div>

    <div v-for="(comments, index) in groupedComments" :key="comments.range">
      <b-button
        :id="`commentButton-${comments.range}`"
        pill
        variant="outline-secondary"
        class="comment-button"
        size="sm"
        :style="{ transform: `translateY(${comments.topOffset}px)` }"
      >
        <b-icon icon="chat" font-scale="0.85" />
      </b-button>
      <b-popover
        :target="`commentButton-${comments.range}`"
        triggers="focus"
        boundary="window"
        ref="commentPopover"
      >
        <b-icon
          class="close-icon"
          icon="x"
          @click="$refs.commentPopover?.[index]?.$emit('close')"
        />
        <popover-comment
          v-for="(comment, commentIndex) in comments.comments"
          :class="{ 'pb-4': commentIndex !== comments.comments.length - 1 }"
          :key="comment.id"
          :comment="comment"
          @delete="$emit('deleteComment', $event)"
          @update="$emit('updateComment', $event)"
        />
        <b-overlay :show="commentLoading" no-wrap> </b-overlay>
      </b-popover>
    </div>

    <highlight-content
      :content="essay.content"
      :highlights="showHighlight ? highlights : []"
      :activeHighlightIndex="activeHighlightIndex"
      @updateActiveHighlightIndex="$emit('updateActiveHighlightIndex', $event)"
      @addComment="$emit('addComment', $event)"
      @highlightClicked="$emit('highlightClicked', $event)"
      ref="highlightContent"
    />
  </div>
</template>

<script>
import HighlightContent from "@/components/essay/essay/highlight/highlight_content.vue";
import PopoverComment from "@/components/essay/comments/popover_comment.vue";

export default {
  components: {
    HighlightContent,
    PopoverComment,
  },

  props: {
    essay: {
      type: Object,
      required: true,
    },

    comments: {
      type: Array,
      default: () => [],
    },

    highlights: {
      type: Array,
      default: () => [],
    },

    activeHighlightIndex: {
      type: Number,
      default: -1,
    },

    commentLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showHighlight: true,
      groupedComments: [],
    };
  },

  watch: {
    comments: {
      immediate: true,
      handler() {
        // not computed because has a lag on getCharacterCoordinates
        setTimeout(() => {
          this.groupedComments = this.recalculateCommentsTopOffset(
            this.groupCommentsByRange(this.comments)
          );
        }, 0);
      },
    },
  },

  methods: {
    groupCommentsByRange(comments) {
      // avoid mutating original array and infinity recalculation
      comments = [...comments];

      // Sort comments by starting range values
      comments.sort((a, b) => a.ranges[0] - b.ranges[0]);

      const grouped = [];

      for (const comment of comments) {
        const startRange = comment.ranges[0];

        // If the current comment is not included in the last group, create a new one
        if (
          !grouped.length ||
          startRange > grouped[grouped.length - 1].range + 80
        ) {
          grouped.push({
            range: startRange,
            comments: [comment],
          });
        } else {
          // Add a comment to the last group
          grouped[grouped.length - 1].comments.push(comment);
        }
      }

      return grouped;
    },

    getEssayCommentTopOffset(comment) {
      return (
        this.$refs?.highlightContent?.getCharacterCoordinates(comment.range)
          ?.y || 0
      );
    },

    recalculateCommentsTopOffset(comments) {
      return comments.map((comment) => ({
        ...comment,
        topOffset: this.getEssayCommentTopOffset(comment),
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-button {
  display: flex;
  padding: 4px;
  border: none;
  position: absolute;
  left: -22px;

  &:hover {
    background-color: #fffbd9;
  }

  &[aria-describedby],
  &:focus {
    box-shadow: none;
    background-color: #fffbd9;
  }
}

.close-icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
</style>
