function copyRichText(
  getRichTextBlob = () => new Blob([""], { type: "text/html" }),
  getPlainTextBlob = () => new Blob([""], { type: "text/plain" })
) {
  return new Promise((resolve, reject) => {
    if (typeof ClipboardItem !== "undefined") {
      // Shiny new Clipboard API, not fully supported in some browsers Firefox.
      // https://developer.mozilla.org/en-US/docs/Web/API/Clipboard_API#browser_compatibility
      //
      // Safari is strict to the “user activation” events,
      // this means that any async operation imposible before use navigator.clipboard.write.
      // But we has oportunity to use ClipboardItem than can use async function that should return Blob.
      navigator.clipboard
        .write([
          new ClipboardItem({
            "text/html": getRichTextBlob(),
            "text/plain": getPlainTextBlob(),
          }),
        ])
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      // Fallback using the deprecated `document.execCommand`.
      // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand#browser_compatibility
      const cb = (e) => {
        Promise.all([getRichTextBlob(), getPlainTextBlob()]).then(
          ([richTextBlob, plainTextBlob]) => {
            Promise.all([richTextBlob.text(), plainTextBlob.text()]).then(
              ([richText, plainText]) => {
                e.clipboardData.setData("text/html", richText);
                e.clipboardData.setData("text/plain", plainText);
                e.preventDefault();
                resolve();
              }
            );
          }
        );
      };

      try {
        document.addEventListener("copy", cb);
        document.execCommand("copy");
        document.removeEventListener("copy", cb);
      } catch (error) {
        reject(error);
      }
    }
  });
}

export default copyRichText;
