<script>
export default {
  name: "ConfirmDeleteAccountModal",
  emits: ["confirm"],
  data() {
    return {
      confirmation: "",
      phrase: "delete account",
    };
  },
  computed: {
    canDelete() {
      return this.confirmation.toLowerCase() === this.phrase.toLowerCase();
    },
  },
  methods: {
    confirm() {
      this.$emit("confirm");
      this.$bvModal.hide("confirm-delete-account-modal");
    },
  },
  mounted() {
    this.confirmation = "";
  },
};
</script>

<template>
  <b-modal
    id="confirm-delete-account-modal"
    title="Delete account"
    size="md"
    centered
    hide-footer
    no-stacking
    @show="confirmation = ''"
  >
    <p>This data will be deleted permanently and cannot be recovered:</p>
    <ul>
      <li>Essays</li>
      <li>Rubrics</li>
      <li>Classes, Students and Assignments</li>
    </ul>
    <label for="confirmation">Type "Delete account" below to continue</label>
    <b-form-input
      id="confirmation"
      v-model="confirmation"
      placeholder="Delete account"
    />
    <div class="d-flex justify-content-end mt-3">
      <b-button
        variant="outline-info"
        @click="$bvModal.hide('confirm-delete-account-modal')"
      >
        Cancel
      </b-button>
      <b-button
        class="ml-3"
        variant="danger"
        @click="confirm"
        :disabled="!canDelete"
      >
        Delete
      </b-button>
    </div>
  </b-modal>
</template>

<style scoped lang="scss"></style>
