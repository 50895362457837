<template>
  <div>
    <b-alert dismissible fade class="custom-alert-color" show>
      {{ platform?.label }} instructions are now used in grading.
      <b-badge variant="info">✨ NEW</b-badge>
      <br />
      Need to edit Instructions or add Source Materials?
      <span
        class="text-primary"
        @click="showAdditionalOptions = !showAdditionalOptions"
      >
        Click here.
      </span>
    </b-alert>
    <essay-language-select
      :value="selectedLanguageId"
      @input="$emit('input-language', $event)"
    />

    <rubric-intensity-radio-group
      v-if="showIntensity && selectedRubric?.type === 'Advanced'"
      horizontal
      :value="rubricIntensity"
      @input="$emit('input-intensity', $event)"
      :required="true"
    />

    <eg-additional-collapse
      :value="showAdditionalOptions"
      @input="onCollapse"
      label="More assignments options"
    >
      <template v-if="loading">
        <b-skeleton-img></b-skeleton-img>
        <b-skeleton-img></b-skeleton-img>
      </template>
      <assignment-form
        v-else-if="!loading && innerAssignment.id"
        ref="assignmentForm"
        :assignment="innerAssignment"
        hideTitle
        hideClass
        hideActions
      />
    </eg-additional-collapse>
  </div>
</template>

<script>
import importApi from "@/api/requests/import";
import AssignmentForm from "@/components/assignment/assignment_form.vue";

import EgAdditionalCollapse from "@/components/global/eg_additional_collapse.vue";
import EssayLanguageSelect from "@/components/dashboard/components/essay_language_select.vue";
import RubricIntensityRadioGroup from "@/components/global/form/rubric_intensity_radio_group.vue";

export default {
  components: {
    AssignmentForm,
    EgAdditionalCollapse,
    EssayLanguageSelect,
    RubricIntensityRadioGroup,
  },

  props: {
    selectedClassId: {
      type: [String, Number],
      required: true,
    },

    selectedAssignmentId: {
      type: [String, Number],
      required: true,
    },

    selectedLanguageId: {
      type: Number,
    },

    selectedRubric: {
      type: Object,
    },

    showIntensity: {
      type: Boolean,
      default: false,
    },

    rubricIntensity: {
      type: String,
    },

    platform: {
      type: Object,
      required: true,
      validate: (value) => {
        return !!value.icon && !!value.label;
      },
    },
  },

  inject: ["serviceAccount", "serviceName"],

  data() {
    return {
      loading: false,
      innerAssignment: {},
      showAdditionalOptions: false,
    };
  },

  methods: {
    async validate() {
      try {
        await this.$refs.assignmentForm.onSubmit();

        return true;
      } catch (error) {
        return error;
      }
    },

    updateTda(tdaSources) {
      this.innerAssignment.tdaSources = tdaSources;
    },

    onCollapse(value) {
      this.showAdditionalOptions = value;
      value && this.getDraftGoogleAssignment();
    },

    async getDraftGoogleAssignment() {
      if (this.innerAssignment.id) {
        return; // Already have a draft, no need to fetch again.
      }

      this.loading = true;
      try {
        const { data } = await importApi[this.serviceName].getDraftAssignment(
          this.serviceAccount.id,
          this.selectedClassId,
          this.selectedAssignmentId
        );
        this.innerAssignment = { tdaInstructions: "", tdaSources: [], ...data };
        this.$emit("input-assignmentId", this.innerAssignment.id);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$showToastError(error.response?.data?.error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-alert-color {
  background: hsla(189, 53%, 88%, 0.9);
  border-color: hsla(189, 53%, 88%, 1);
}

.text-primary {
  cursor: pointer;
}
</style>
