<script>
import ChartJSBase from "./chart_js_base.vue";

import {
  Chart as ChartJS,
  LineController,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
ChartJS.register(Tooltip);
ChartJS.register(CategoryScale);
ChartJS.register(PointElement);
ChartJS.register(LineElement);
ChartJS.register(LinearScale);
ChartJS.register(LineController);

export default {
  name: "ChartJSLine",

  extends: ChartJSBase,

  data() {
    return {
      type: "line",
    };
  },
};
</script>
