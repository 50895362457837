<template>
  <div :class="{ isExporting }">
    <b-overlay :show="loading" spinner-small rounding="sm" no-wrap>
      <template #overlay>
        <b-spinner small variant="info" />
      </template>
    </b-overlay>

    <div class="d-flex flex-wrap align-items-center">
      <h5 class="mt-auto mb-auto mr-auto">Rubric breakdown</h5>
      <rubric-select
        data-html2canvas-ignore
        class="rubric-select"
        :disabled="rubrics.length === 0"
        :rubrics="rubrics"
        :value="selectedRubric"
        @input="loadGradingCriteria"
      />
      <filter-dropdown
        data-html2canvas-ignore
        class="ml-2"
        :button-label="
          dateRange
            ? `Date range: ${formatDate(
                dateRange.from,
                'MMM YY'
              )} - ${formatDate(dateRange.to, 'MMM YY')}`
            : 'Select date range'
        "
        :disabled="rubrics.length === 0"
        :active="false"
        :right="false"
        title="Date range"
        @apply="
          dateRange = tempDateRange;
          loadGradingCriteria(selectedRubric);
        "
        @delete="
          dateRange = null;
          tempDateRange = null;
          loadGradingCriteria(selectedRubric);
        "
      >
        <template #default="{ resetSignal }">
          <date-range
            v-model="tempDateRange"
            :reset-signal="resetSignal"
          ></date-range>
        </template>
      </filter-dropdown>
    </div>

    <empty-state
      v-if="!loadingRubrics && gradingCriteria.length == 0"
      class="mt-5"
      title="No assignments selected or found during this date range."
    >
      <img
        src="../../assets/icons/chart.svg"
        width="48"
        alt="EssayGrader Empty State"
      />
    </empty-state>

    <eg-selectable-table
      v-else
      class="table"
      :loading="loading || loadingRubrics"
      :items="gradingCriteria"
      :total="gradingCriteria.length"
      :limit="gradingCriteria.length"
      :fields="fields"
      :selectable="false"
    >
      <template #cell(avgScore)="{ item }">
        <div class="d-flex align-items-center avg-score">
          <div class="mr-2 flex-shrink-0">
            {{ item.avgGrade || 0 }} / {{ item.maxGrade }}
          </div>
          <b-progress
            class="w-100 rounded-pill"
            variant="info"
            height="12px"
            :value="item.avgGrade || 0"
            :max="item.maxGrade"
          />
        </div>
      </template>

      <template #cell(graph)="{ item }">
        <chart-js-line
          v-if="item.graph?.length > 1"
          :key="item.name + isExporting"
          :width="200"
          :height="30"
          :chart-options="generateChartOptions(item)"
          :chart-data="generateChartData(item)"
        />
        <div v-else>Requires 2+ assignments</div>
      </template>
    </eg-selectable-table>
  </div>
</template>

<script>
import { getInsightsUsedRubrics, getInsightsRubricBreakdown } from "@/api";
import formatDate from "@/utils/formatDate";
import ChartJsLine from "@/components/global/chartjs/chart_js_line.vue";
import EgSelectableTable from "@/components/global/eg_selectable_table";
import RubricSelect from "@/components/rubrics/rubric_select.vue";
import DateRange from "@/components/essay/filters/date_range.vue";
import FilterDropdown from "@/components/essay/filters/filter_dropdown.vue";
import EmptyState from "@/components/global/empty_state.vue";

export default {
  name: "RubricBreakdown",

  components: {
    DateRange,
    FilterDropdown,
    EgSelectableTable,
    ChartJsLine,
    RubricSelect,
    EmptyState,
  },

  props: {
    classId: {
      type: Number,
    },

    assignmentId: {
      type: Number,
    },

    studentId: {
      type: Number,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    isExporting: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loadingRubrics: false,
      rubrics: [],
      selectedRubric: null,
      gradingCriteria: [],
      tempDateRange: null,
      dateRange: null,
    };
  },

  computed: {
    fields() {
      const fields = [
        {
          key: "name",
          label: "Grading criteria",
        },
        {
          key: "avgScore",
          label: "Avg class score",
        },
      ];

      if (!this.assignmentId) {
        fields.push({
          key: "graph",
          label: "Trend (across all assignments)",
        });
      }

      return fields;
    },
  },

  watch: {
    loading() {
      if (!this.loading) {
        this.init();
      }
    },
  },

  created() {
    this.init();
  },

  methods: {
    formatDate,

    generateChartData(item) {
      return {
        labels: item.graph.map((graphData) => graphData.name),
        datasets: [
          {
            label: "",
            data: item.graph.map((graphData) => graphData.avgScore),
            borderColor: "rgb(111, 66, 193)", // line color
            pointBackgroundColor: "rgb(111, 66, 193)", // pointer color
            tension: 0.1,
          },
        ],
      };
    },

    generateChartOptions(item) {
      return {
        plugins: {
          tooltip: {
            callbacks: {
              displayColor: false,
              label: function (context) {
                return [
                  `Assignment: ${item.graph[context.dataIndex].name}`,
                  `Avg class score for this criteria: ${
                    item.graph[context.dataIndex].avgScore
                  }`,
                ];
              },
            },
          },
          legend: {
            display: false, // hide legend
          },
        },
        maintainAspectRatio: false,
        scales: {
          x: {
            display: () => this.isExporting, // remove x-axis line
            grid: {
              display: false, // remove x-axis grid
            },
          },
          y: {
            display: () => this.isExporting, // remove y-axis line
            grid: {
              display: false, // remove y-axis grid
            },
          },
        },
      };
    },
    async init() {
      if (this.loading || !this.classId) {
        return;
      }

      this.loadingRubrics = true;
      const { data } = await getInsightsUsedRubrics(
        this.classId,
        this.assignmentId,
        this.studentId
      );
      this.rubrics = data.rubrics;
      this.selectedRubric = this.rubrics[0] ? this.rubrics[0] : null;

      if (this.selectedRubric) {
        await this.loadGradingCriteria(this.selectedRubric);
      }

      this.loadingRubrics = false;
    },

    async loadGradingCriteria(value) {
      this.selectedRubric = value;
      this.loadingRubrics = true;
      const { data } = await getInsightsRubricBreakdown({
        classId: this.classId,
        assignmentId: this.assignmentId,
        rubricId: this.selectedRubric.id,
        dateRange: this.dateRange,
      });
      this.gradingCriteria = data.breakdown;
      this.loadingRubrics = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.rubric-select::v-deep button {
  padding: 3px 8px;
}

.avg-score {
  width: 140px;
}

@mixin isExporting() {
  .table {
    margin: 0;

    &::v-deep {
      .b-table-sticky-header {
        max-height: unset;

        tr {
          display: flex;
          flex-direction: column;
        }

        th {
          display: none;
        }

        td {
          border: none;
          padding: 0;
          margin-top: 1rem;

          &:nth-child(1)::before {
            content: "Criteria: ";
          }

          &:nth-child(2) > div {
            width: 65%;

            &::before {
              content: "Avg class score: ";
              flex-shrink: 0;
              margin-right: 8px;
            }
          }

          &:nth-child(3) {
            .chart-js {
              position: relative;
              display: flex;
              flex-direction: column;
              min-width: 0;
              word-wrap: break-word;
              background-color: #fff;
              background-clip: border-box;
              border: 1px solid rgba(0, 0, 0, 0.125);
              border-radius: 0.25rem;
              padding: 15px;
            }

            .chart {
              --chart-width: 100% !important;
              --chart-height: 130px !important;
              max-width: 600px;
            }
          }
        }

        tr.b-table-top-row td::before {
          content: "";
        }
        .b-table tbody tr:last-child {
          border: none;
        }
      }
    }
  }
}

.isExporting {
  @include isExporting();
}

@media (max-width: 768px) {
  @include isExporting();
}
</style>
