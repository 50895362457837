<template>
  <div class="essay-view position-relative">
    <b-overlay :show="loading || error !== ''" no-wrap>
      <template #overlay>
        <div>
          <div v-if="loading" class="text-center">
            <b-spinner class="mb-3"></b-spinner>
            <p>Processing request...</p>
          </div>
          <b-alert v-else variant="danger" show>{{ error }}</b-alert>
        </div>
      </template>
    </b-overlay>
    <section v-if="essay">
      <breadcrumbs :items="breadcrumbs" />
      <b-alert v-if="copyError" class="mb-5" variant="danger" show>
        {{ copyError }}
      </b-alert>

      <essay-page-header
        :essay="essay"
        :pageInfo="pageInfo"
        :getEssay="getEssay"
        @essayStatusChanged="updateStatus"
        @loadingChanged="loading = $event"
        @regradingChange="regrading = $event"
      />

      <b-row v-if="regrading" class="mt-4">
        <b-col>
          <div class="text-center mt-4">
            <b-spinner
              variant="info"
              label="Regrading..."
              style="width: 64px; height: 64px"
            />
            <h2 class="mt-4">Re-grading {{ essay?.name }}...</h2>
          </div>
        </b-col>
      </b-row>
      <b-row v-else class="mt-4">
        <b-col lg="6">
          <div class="box light highlights-box">
            <essay-content
              :essay="essay"
              :comments="comments"
              :commentLoading="commentLoading"
              :highlights="highlights"
              :active-highlight-index="activeHighlightIndex"
              @highlightClicked="highlightClicked"
              @addComment="addComment"
              @updateComment="updateComment"
              @deleteComment="deleteComment"
              @updateActiveHighlightIndex="activeHighlightIndex = $event"
            />
          </div>
        </b-col>
        <b-col lg="6" class="mt-4 mt-lg-0">
          <b-tabs
            v-model="activeTab"
            class="h-100 d-flex flex-column"
            content-class="h-100"
            nav-class-wrapper="mx-3"
            @input="activeHighlightIndex = -1"
          >
            <b-tab title="Feedback report" lazy>
              <feedback-report-v3
                v-if="isV3"
                :essay="essay"
                :active-highlight-index="activeHighlightIndex"
                @updateEssay="essay = $event"
                @updateHighlights="highlights = $event"
                @updateActiveHighlightIndex="activeHighlightIndex = $event"
              />
              <base-report-v2
                v-else
                type="feedback"
                :essay="essay"
                @updated="({ html, type }) => (essay[type] = html)"
              />
            </b-tab>
            <b-tab title="Writing report" lazy>
              <grammar-report-v3
                v-if="isV3"
                :essay="essay"
                :active-highlight-index="activeHighlightIndex"
                @updateEssay="essay = $event"
                @updateHighlights="highlights = $event"
                @updateActiveHighlightIndex="activeHighlightIndex = $event"
              />
              <base-report-v2
                v-else
                type="errors"
                :essay="essay"
                @updated="({ html, type }) => (essay[type] = html)"
              />
            </b-tab>
            <b-tab
              :title="flagsCount > 1 ? 'Flags' : 'AI Detection'"
              :active="false"
              lazy
            >
              <template v-if="flagsCount > 1" #title>
                <div class="d-flex align-items-center">
                  <div class="mr-2">Flags</div>
                  <b-badge
                    v-if="flagsCount > 0"
                    class="flag-badge"
                    :class="detectedClass"
                    pill
                    variant="primary"
                  >
                    <b-icon icon="flag" />
                    {{ flagsCount }} Detected
                  </b-badge>
                  <b-badge v-if="!canGoAiDetector" pill variant="info-light">
                    <b-icon variant="info" icon="award" />
                    Pro
                  </b-badge>
                </div>
              </template>
              <essay-flags
                :essay="essay"
                :active-highlight-index="activeHighlightIndex"
                @updateEssay="essay = $event"
                @updateHighlights="highlights = $event"
                @updateActiveHighlightIndex="activeHighlightIndex = $event"
              />
            </b-tab>
            <b-tab
              class="h-100"
              title="Comments"
              @click="() => getEssayComments()"
              lazy
            >
              <essay-tab-comments
                :comments="comments"
                :commentLoading="commentLoading"
                :active-highlight-index="activeHighlightIndex"
                @updateHighlights="highlights = $event"
                @updateActiveHighlightIndex="activeHighlightIndex = $event"
                @updateComment="updateComment"
                @deleteComment="deleteComment"
              />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>

      <move-essay-modal
        :essayId="essay?.id"
        @essayMoved="redirectToEssays"
        @moveError="setMoveError"
      />
      <essay-regrade-modal
        :essay="essay"
        :essayId="essay?.id"
        @startRegrade="regrading = true"
        @finishRegrade="regrading = false"
      />

      <export-to-classroom-modal :selected-essays="[essay]" />
      <export-to-canvas-modal :selected-essays="[essay]" />

      <export-to-pdf :selected-ids="[essay.id]" />
      <export-to-csv :selected-ids="[essay.id]" />

      <essay-rename-modal :essay="essay" @updated="getEssay" />
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { getEssay, getEssayFlags } from "@/api";
import Breadcrumbs from "@/components/global/breadcrumbs.vue";
import EssayPageHeader from "@/components/essay/essay/essay_page_header.vue";
import MoveEssayModal from "@/components/essay/modals/move_essay_modal.vue";
import EssayRegradeModal from "@/components/essay/essay_regrade_modal.vue";
import ExportToClassroomModal from "@/components/essay/modals/export_to_classroom.vue";
import ExportToCanvasModal from "@/components/essay/modals/export_to_canvas.vue";
import ExportToPdf from "@/components/essay/modals/export_to_pdf.vue";
import ExportToCsv from "@/components/essay/modals/export_to_csv.vue";
import EssayRenameModal from "@/components/essay/modals/essay_rename_modal.vue";
import EssayContent from "@/components/essay/essay/essay_content.vue";
import BaseReportV2 from "@/components/essay/essay/base_report_v2.vue";
import EssayFlags from "@/components/essay/essay/flags/essay_flags.vue";
import EssayTabComments from "@/components/essay/essay/essay_tab_comments.vue";
import FeedbackReportV3 from "@/components/essay/essay/v3/feedback_report_v3.vue";
import GrammarReportV3 from "@/components/essay/essay/v3/grammar_report_v3.vue";
import commentsMixin from "@/components/essay/comments/mixins/commentsMixin";

export default {
  name: "EssayView",

  // this.commentLoading
  // this.comments
  // this.getEssayComments
  // this.addComment
  // this.updateComment
  // this.deleteComment
  mixins: [commentsMixin],

  components: {
    BaseReportV2,
    Breadcrumbs,
    MoveEssayModal,
    EssayRegradeModal,
    ExportToClassroomModal,
    ExportToCanvasModal,
    ExportToPdf,
    ExportToCsv,
    EssayRenameModal,
    EssayFlags,
    EssayContent,
    EssayPageHeader,
    EssayTabComments,
    FeedbackReportV3,
    GrammarReportV3,
  },

  data() {
    return {
      activeTab: 0,
      regrading: false,
      loading: true,
      error: "",
      essay: null,
      pageInfo: null,
      copyError: "",

      highlights: [],
      activeHighlightIndex: -1,
    };
  },

  async created() {
    await this.getEssay();
    this.activeTab = parseInt(this.$route.query.tab) || 0;
  },

  computed: {
    isV3() {
      return (this.essay?.tags || []).includes("v3");
    },

    routerIdParam() {
      return this.$route.params.id;
    },

    canGoAiDetector() {
      return this.$store.getters.userIsNotPausedAndPlanMoreThenPro;
    },

    flagsCount() {
      return (this.essay?.flags || []).length;
    },

    detectedClass() {
      switch (this.flagsCount) {
        case 0:
          return "";

        case 1:
          return "yellow";

        case 2:
          return "orange";

        default:
          return "danger";
      }
    },

    breadcrumbs() {
      return [
        {
          text: "Essays",
          to: "/essays",
        },
        {
          text: this.essay?.name || "Essay",
          active: true,
        },
      ];
    },
  },

  methods: {
    ...mapActions(["setAiDetectorEssayContent"]),

    async highlightClicked(highlight) {
      if (!highlight.refComponent) {
        throw new Error(
          "highlight should has refComponent - link to vue component"
        );
      }

      await this.$nextTick();

      highlight.refComponent?.$el?.scrollIntoView({ behavior: "smooth" });
      this.activeHighlightIndex = this.highlights.findIndex(
        (highlightItem) =>
          highlight.refComponent._uid === highlightItem.refComponent._uid
      );
    },

    async getEssay(params = {}) {
      try {
        // NOTE: No Promise.all because in the first request (getEssay) we get essay 'before'/'after' (in params) this.routerIdParam,
        // then we get flags for this next/prev essay
        const { data } = await getEssay(this.routerIdParam, params);
        const essayId = data.essay?.id;

        const [essayFlags] = await Promise.all([
          getEssayFlags(essayId),
          this.getEssayComments(essayId),
        ]);

        const {
          data: { flags },
        } = essayFlags;

        this.essay = Object.assign(data.essay, { flags });
        this.pageInfo = {
          ...data.pageInfo,
          current: data.current,
          totalCount: data.totalCount,
        };

        this.loading = false;
        this.error = "";
      } catch (error) {
        this.loading = false;
        this.error = error.response?.data?.error;
      }
    },

    redirectToEssays() {
      this.$router.push("/essays?moved=true");
    },

    setMoveError(error) {
      this.error = error;
    },

    showUpgradeModal() {
      this.$bvModal.show("upgrade-modal");
    },

    updateStatus(status) {
      this.essay.status = status;
    },
  },
};
</script>

<style scoped lang="scss">
.essay-view {
  min-height: calc(100vh - var(--navbar-height));
}

.highlights-box {
  max-height: calc(100vh - 256px);
  overflow: auto;
}

.box {
  white-space: pre-wrap;
  padding: 25px;
  border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  height: 100%;
}

.flag-badge {
  display: none;

  &.yellow {
    display: block;
    background-color: hsla(45, 100%, 85%, 0.6);
    color: var(--Text---Low, hsl(45, 74%, 36%));
  }

  &.orange {
    display: block;
    background-color: hsla(30, 88%, 55%, 0.35);
    color: hsla(30, 70%, 41%, 1);
  }

  &.danger {
    display: block;
    background: var(--Danger-bg, hsl(354, 100%, 96%));
    color: hsla(354, 63%, 47%, 1);
  }
}
</style>
