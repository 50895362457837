<template>
  <div class="comment position-relative">
    <div class="title">
      <div class="name">
        {{ comment.author.firstName }} {{ comment.author.lastName }}
      </div>
      <div>{{ date }}</div>
    </div>

    <div v-if="isEdit" class="text mt-2">
      <b-form-textarea
        v-model="newCommentText"
        placeholder="Enter comment..."
        rows="2"
        max-rows="4"
      />
    </div>
    <div v-else class="text mt-2">{{ comment.text }}</div>

    <div v-if="!isEdit" class="d-flex mt-2">
      <b-button
        variant="outline-info"
        size="sm"
        class="ml-auto mr-2"
        @click="
          () => {
            isEdit = true;
            newCommentText = comment.text;
          }
        "
      >
        Edit
      </b-button>
      <b-button
        size="sm"
        variant="outline-info"
        @click="$emit('delete', comment)"
      >
        Delete
      </b-button>
    </div>
    <div v-else class="d-flex mt-2">
      <b-button
        variant="outline-info"
        size="sm"
        class="ml-auto mr-2"
        @click="isEdit = false"
      >
        Cancel
      </b-button>
      <b-button
        size="sm"
        variant="info"
        @click="
          () => {
            isEdit = false;
            $emit('update', { commentId: comment.id, text: newCommentText });
          }
        "
      >
        Save
      </b-button>
    </div>
  </div>
</template>

<script>
// TODO: this and ./tab_comment.vue unify by mixin logic
import formatDate from "@/utils/formatDate";

export default {
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      newCommentText: this.comment.text || "",
      isEdit: false,
    };
  },

  computed: {
    date() {
      return formatDate(this.comment.createdAt);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: var(--Text-Secondary, #6c757d);
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  padding-bottom: 8px;
  border-bottom: 1px solid #dfdfdf;

  .name {
    font-weight: 700;
  }
}

.text {
  color: var(--Text-Body, #212529);
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  textarea {
    overflow-y: auto !important;
  }
}
</style>
