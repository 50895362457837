<template>
  <div class="start-grading-container box">
    <section class="p-0">
      <template>
        <b-row class="mb-1">
          <b-col cols="12">
            <b-button to="/" class="btn btn-link go-back-link" variant="link">
              <b-icon icon="arrow-left" />
              Go back
            </b-button>
          </b-col>
        </b-row>
        <b-row class="start-grading-header mb-3">
          <b-col cols="12">
            <h1>Start grading</h1>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <StartGradingSelector />
            <slot />
          </b-col>
        </b-row>
      </template>
    </section>
  </div>
</template>

<script>
import StartGradingSelector from "./components/start_grading_selector.vue";
export default {
  name: "StartGradingContainer",
  components: { StartGradingSelector },
  props: {
    to: String,
  },
};
</script>
<style scoped lang="scss">
.start-grading-container {
  padding: 32px;
  margin: 20px auto 0 auto;
  max-width: 864px;
  background-color: white;
  font-family: Inter;
  h1 {
    font-family: Inter;
    color: black;
    margin: 0;
  }
}
.go-back-link:not(.disabled) {
  background: transparent;
  border: none;
  outline: none;
  &:focus,
  &:active:focus,
  &:hover,
  &:active {
    background: transparent;
    border: none;
    outline: none;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem var(--purple-100);
  }
}
</style>
