<template>
  <export-to-platform
    v-bind="$attrs"
    platform="canvas"
    :disabled="false"
    :available-options="availableOptions"
    :additional-options="additionalOptions"
    :selected-options="selectedOptions"
    @update:selected-options="updateSelectedOptions"
  >
    <template #reAuth="{ cancel, resolve }">
      <CanvasInstruction @updated="resolve">
        <template #cancel-button>
          <b-button class="mr-3" @click="cancel">Cancel</b-button>
        </template>
      </CanvasInstruction>
    </template>
  </export-to-platform>
</template>

<script>
import CanvasInstruction from "@/components/imports/canvas/canvas_instruction.vue";
import ExportToPlatform from "./export_to_platform";
const defaultAvailableOptions = [
  "content",
  "feedback",
  "errors",
  "comments",
  "exportGrades",
];

export default {
  name: "ExportToCanvasModal",

  components: {
    CanvasInstruction,
    ExportToPlatform,
  },

  data() {
    return {
      selectedOptions: [...defaultAvailableOptions],
      availableOptions: [...defaultAvailableOptions],
      additionalOptions: {
        exportGrades: {
          html: `
          <div>
            <div>Grades</div>
            <div class='small-text text-secondary'>Assigned in Canvas</div>
          </div>
          `,
          value: "exportGrades",
        },
        hideGrades: {
          text: "Hide grades in feedback report",
          value: "hideGrades",
        },
      },
    };
  },

  methods: {
    updateSelectedOptions(newSelectedOptions) {
      this.selectedOptions = newSelectedOptions;

      if (
        !this.selectedOptions.includes("exportGrades") &&
        !this.availableOptions.includes("hideGrades")
      ) {
        this.availableOptions = [...defaultAvailableOptions, "hideGrades"];
        this.selectedOptions = [
          ...new Set([...this.selectedOptions, "hideGrades"]),
        ];
      }

      if (this.selectedOptions.includes("exportGrades")) {
        this.availableOptions = [...defaultAvailableOptions];

        this.selectedOptions = this.selectedOptions.filter(
          (option) => option !== "hideGrades"
        );
      }
    },
  },
};
</script>
