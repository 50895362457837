<template>
  <div class="classroom-view">
    <section>
      <b-row>
        <b-col>
          <StartGradingContainer class="imports-google-classroom-view">
            <b-row class="justify-content-center align-items-center">
              <b-col cols="12" md="9" lg="8">
                <import-connect
                  v-if="!serviceAccount.id || !serviceAccount.isGCAvailable"
                  @input="updateServiceAccount"
                />
                <import-wizard
                  v-if="
                    serviceAccount &&
                    serviceAccount.id &&
                    serviceAccount.isGCAvailable
                  "
                  platform="google"
                />
              </b-col>
            </b-row>
          </StartGradingContainer>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import ImportWizard from "@/components/imports/imports_wizard.vue";
import ImportConnect from "@/components/imports/imports_connect.vue";
import StartGradingContainer from "@/components/start-grading/start_grading_container.vue";

export default {
  components: {
    ImportWizard,
    ImportConnect,
    StartGradingContainer,
  },

  provide() {
    return {
      serviceAccount: this.serviceAccount,
      serviceName: "googleClassroom",
    };
  },

  data() {
    return {
      serviceAccount: {
        id: null,
        isGCAvailable: false,
      },
    };
  },

  methods: {
    updateServiceAccount(account) {
      if (!account.isGCAvailable) {
        return this.$showToastError(
          "Google Classroom permissions have expired or are not available for this account. Please try again and select all permissions."
        );
      }

      this.serviceAccount.id = account.gid;
      this.serviceAccount.isGCAvailable = account.isGCAvailable;
    },
  },
};
</script>
<style scoped lang="scss">
.classroom-view {
  min-height: calc(100vh - var(--main-header-height));
  background-color: var(--grey-100);
}
</style>
