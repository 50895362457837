<template>
  <div class="rubric-container mb-3">
    <b-row class="mb-3">
      <b-col>
        <h5>Rubric</h5>
      </b-col>
      <b-col class="text-right">
        <b-button
          v-if="!selectedRubric"
          variant="info"
          size="sm"
          v-b-toggle.rubric-library-sidebar
        >
          Choose rubric from library
        </b-button>
        <b-button
          v-if="selectedRubric"
          class="ml-4"
          variant="info"
          size="sm"
          v-b-toggle.rubric-library-sidebar
        >
          Choose a different rubric
        </b-button>
      </b-col>
    </b-row>

    <div class="selected-rubric">
      <b-row v-if="selectedRubric" class="selected-rubric-container">
        <b-col class="col-2 col-sm-2 col-md-1 d-flex align-items-center">
          <b-icon icon="file-earmark-text" />
        </b-col>
        <b-col class="col-8 col-sm-10 col-md-8 d-flex align-items-center">
          <div class="rubric-description">
            <div class="rubric-name">{{ selectedRubric?.name }}</div>
            <div v-if="hasGradeOrLanguage" class="grade-plus-language">
              <div
                v-if="Boolean(selectedRubric.gradeLevel)"
                class="grade-level"
              >
                {{ selectedRubric.gradeLevel }}
              </div>
              <div
                v-if="Boolean(selectedRubric.language)"
                class="rubric-language"
              >
                {{ selectedRubric.language.label }}
              </div>
            </div>
          </div>
        </b-col>
        <b-col class="col-12 col-sm-12 col-md-3 text-right">
          <div class="rubric-created-by">
            Created by
            <b>
              {{ formatAuthorValue(selectedRubric?.author, $store) || "Me" }}
            </b>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "EssayRubricSelect",

  props: {
    selectedRubric: Object,
    formatAuthorValue: Function,
  },

  computed: {
    hasGradeOrLanguage() {
      return Boolean(
        this.selectedRubric.gradeLevel || this.selectedRubric.language
      );
    },
  },
};
</script>

<style scoped lang="scss">
.rubric-container {
  display: block;
  padding: 16px;
  background-color: var(--grey-100);
  border-radius: 8px;
  border: 1px solid var(--grey-300);

  h5 {
    color: var(--black-700);
    font-family: Inter;
    margin: 0;
    line-height: 31px;
    font-size: 18px;
    font-weight: 700;
  }

  .selected-rubric {
    gap: 8px;
    padding: 12px 15px;
    background-color: white;
    border: 1px solid var(--grey-300);
    border-radius: 8px;

    svg {
      width: 36px;
      height: 36px;
    }
  }

  .rubric-description {
    flex: 1;
  }
  .grade-plus-language {
    display: flex;
    gap: 8px;
  }
  .rubric-language,
  .grade-level {
    display: flex;
    width: max-content;
    padding: 0 7px;
    line-height: 20px;
    font-size: 12px;
    border-radius: 6px;
    text-transform: capitalize;
  }
  .grade-level {
    background-color: var(--green-100);
    color: var(--green-300);
  }
  .rubric-language {
    background-color: var(--grey-200);
    color: var(--grey-600);
  }
  @media (max-width: 576px) {
    .selected-rubric-container {
      gap: 8px;
    }
  }
}
</style>
