<template>
  <div>
    <empty-state
      key="empty"
      :title="emptyStateInfo.title"
      :description="emptyStateInfo.description"
      class="m-auto"
    >
      <img
        style="max-height: 230px"
        src="@/assets/illustrations/empty-state-flags.svg"
        alt="EssayGrader Empty State"
      />

      <template #buttons>
        <div class="d-flex justify-content-center">
          <b-button
            v-if="canGoAiDetector"
            variant="outline-info"
            class=""
            size="sm"
            @click="$emit('clickFlagsManually')"
          >
            Run manually
          </b-button>
          <div>
            <b-button
              :variant="canGoAiDetector ? 'link' : 'outline-info'"
              class=""
              size="sm"
              @click="$bvModal.show('upgrade-modal')"
            >
              {{
                canGoAiDetector
                  ? "Run detection reports automatically"
                  : "Upgrade subscription"
              }}
            </b-button>
            <b-badge v-if="proPlan" pill variant="info-light">
              <b-icon variant="info" icon="award" />
              Premium
            </b-badge>
          </div>
        </div>
      </template>
    </empty-state>
  </div>
</template>

<script>
import EmptyState from "@/components/global/empty_state.vue";

export default {
  components: { EmptyState },

  computed: {
    canGoAiDetector() {
      return this.$store.getters.userIsNotPausedAndPlanMoreThenPro;
    },

    emptyStateInfo() {
      if (this.canGoAiDetector) {
        return {
          title: "No flags have been detected yet.",
          description:
            "When detection reports are run, EssayGrader will detect if the submission was written with AI or plagiarized. Reports must be run manually for each submission on Pro plans.",
        };
      }

      return {
        title: "Get more insights with detection reports",
        description:
          "When detection reports are run, EssayGrader will detect if the submission was written with AI or plagiarized. Reports must be run manually for each submission on Pro plans. Reports run automatically on Premium plans.",
      };
    },

    proPlan() {
      return this.$store.getters.proPlan;
    },
  },
};
</script>
