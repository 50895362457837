<template>
  <b-form-group>
    <template #label>Feedback length</template>
    <div class="d-flex">
      <ToggleButton
        v-for="radioOption in radioOptions"
        :key="radioOption.value"
        class="mr-3 btn d-flex align-center"
        :horizontal="true"
        :value="radioOption.value"
        :label="radioOption.label"
        @click="handleChange(radioOption.value)"
        :pressed="value === radioOption.value"
      />
    </div>
  </b-form-group>
</template>

<script>
import ToggleButton from "@/components/global/eg_toggle_button.vue";
import { FEEDBACK_LENGTH } from "@/constants";

export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  components: { ToggleButton },
  data() {
    return {
      radioOptions: FEEDBACK_LENGTH,
    };
  },
  methods: {
    handleChange(valueId) {
      this.$emit("input", valueId);
    },
  },
};
</script>
