<template>
  <div>
    <b-row>
      <b-col cols="12" class="d-flex justify-content-center">
        <div class="connect-import-schema" />
      </b-col>
    </b-row>
    <b-card-text class="mt-4">
      <h2 class="mb-4">Connecting to {{ socialName }}</h2>
      When you connect to {{ socialName }}, EssayGrader will be able to import
      student assignments so they can be graded without manually uploading them,
      and export essay feedback back to {{ socialName }}.
    </b-card-text>
    <b-card-text>
      <strong>Don't forget!</strong>

      <slot name="extra-instructions">
        <ul>
          <li>
            Allow EssayGrader to access all Google Classroom assignment data by
            selecting "Select all".
          </li>
          <li>
            We will not make any changes to Google Classroom without your
            permission.
          </li>
          <li>
            The use and transfer to any other app of information received from
            Google APIs will adhere to
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy"
              target="_blank"
            >
              Google API Services User Data Policy</a
            >, including the Limited Use requirements.
          </li>
        </ul>
      </slot>
    </b-card-text>
    <div class="text-right mt-2">
      <slot name="continue-button">
        <google-button
          type="permissions"
          :scope="permissionScope"
          @done="$emit('input', $event)"
        >
          Continue with Google
        </google-button>
      </slot>
    </div>
  </div>
</template>

<script>
import { CLASSROOM_SCOPE } from "@/constants";
import GoogleButton from "@/components/auth/google_button.vue";

export default {
  name: "GoogleClassroomConnect",

  components: {
    GoogleButton,
  },

  props: {
    value: {
      type: String,
    },

    socialName: {
      type: String,
      default: "Google Classroom",
    },
  },

  data() {
    return {
      permissionScope: CLASSROOM_SCOPE,
    };
  },
};
</script>

<style scoped lang="scss">
h2 {
  font-size: 30px;
  font-weight: 900;
  line-height: 119%;
  font-family: "Roboto", sans-serif;
}

.card-text {
  font-size: 16px;
  line-height: 24px;
  strong {
    font-size: 17px;
  }
}
.connect-import-schema {
  height: 191px;
  max-width: 400px;
  width: 100%;
  background-image: url("@/assets/icons/connect-1.svg"),
    url("@/assets/icons/connect-2.svg");
  background-repeat: no-repeat;
  background-position: left bottom, right -100px;
  background-size: 220px auto, 321px auto;
}

.custom-img {
  max-width: 100%;
  width: 100%;
  max-height: 150px;
  object-fit: none;
}
</style>
