<template>
  <b-modal
    :id="`export-to-${platform}-modal`"
    :title="`Export feedback to ${LMS_PROVIDERS[platform].label}`"
    class="export-to-platform-modal"
    size="lg"
    centered
    @show="onShow"
    @hide="onHide"
  >
    <template #modal-footer="{ close }">
      <div class="d-flex align-items-center justify-content-end">
        <b-button variant="secondary" @click="close">{{ closeText }}</b-button>

        <template v-if="!disabled">
          <b-button
            v-if="!processed || (processed && resultStatus === 'warning')"
            class="ml-2"
            variant="info"
            :disabled="showAll ? false : exporting || selectedIds.length === 0"
            @click="
              !processed ? exportSelectedToPlatform() : retryAllFailedExports()
            "
          >
            {{ !processed ? "Export" : "Retry all failed exports" }}
          </b-button>
          <b-button
            v-if="processed && resultStatus === 'error'"
            class="ml-2"
            variant="info"
            @click="retryExport"
          >
            Retry export
          </b-button>
          <b-button
            v-if="processed && resultStatus === 'auth'"
            class="ml-2"
            variant="info"
            :disabled="
              exporting ||
              resultData.some((essay) => essay.reAuthStatus === false)
            "
            @click="() => exportSelectedToPlatform()"
          >
            Retry all failed exports
          </b-button>
        </template>
      </div>
    </template>

    <b-overlay :show="disabled" no-wrap opacity="0.96">
      <template #overlay>
        <div class="text-center">
          <p>
            {{ LMS_PROVIDERS[platform].label }} exports are temporarily disabled
            while we investigate issues with the
            {{ LMS_PROVIDERS[platform].label }} platform. As a workaround, you
            can export feedback as PDF and attach the pdf as a comment in
            student assignments in {{ LMS_PROVIDERS[platform].label }}. We
            apologize for the inconvenience and are trying to fix this as soon
            as we can.
          </p>
        </div>
      </template>
    </b-overlay>

    <div v-if="reAuthItem && $scopedSlots.reAuth">
      <slot
        name="reAuth"
        :cancel="
          () => {
            reAuthItem = null;
          }
        "
        :resolve="onReAuth"
      />
    </div>

    <div v-else>
      <div v-if="exporting">
        <div
          class="d-flex flex-column align-items-center justify-content-center w-100"
          style="height: 300px"
        >
          <b-spinner
            class="my-5"
            style="width: 51px; height: 51px"
            variant="info"
          />

          <h4 class="text-center">
            Exporting to {{ LMS_PROVIDERS[platform].label }}...
          </h4>
        </div>
      </div>

      <div v-if="!exporting">
        <div v-if="!processed" class="text mb-3">
          Customize export to include:
          <export-options
            class="mt-2"
            v-model="innerSelectedOptions"
            :available-options="availableOptions"
            :additional-options="additionalOptions"
            @input="$emit('update:selected-options', $event)"
          />
        </div>

        <div
          v-else
          class="d-flex flex-column align-items-center justify-content-center w-100"
        >
          <b-icon
            :icon="result.icon"
            :variant="result.variant"
            :style="{ width: '48px', height: '48px' }"
          />
          <h4 class="text-center mt-2">
            {{ result.label }}
          </h4>

          <div v-if="result.text" class="text text-center mb-3">
            {{ result.text }}
          </div>

          <eg-selectable-table
            v-if="resultStatus === 'auth'"
            :selectable="false"
            :items="resultData"
            :total="resultData.length"
            :limit="resultData.length"
            :fields="reAuthFields"
            class="w-100"
          >
            <template #cell(reAuthStatus)="{ item }">
              <div>
                <b-icon
                  :variant="item.reAuthStatus ? 'success' : 'danger'"
                  :icon="
                    item.reAuthStatus
                      ? 'check-circle-fill'
                      : 'exclamation-circle-fill'
                  "
                />

                {{
                  item.reAuthStatus ? "Connected" : "Needs Re-authentication"
                }}
              </div>
            </template>

            <template #cell(reAuthAction)="{ item }">
              <div v-if="!item.reAuthStatus" class="text-right">
                <google-button
                  v-if="platform === 'google'"
                  type="permissions"
                  :scope="permissionScope"
                  @done="onReAuthGoogle(item, $event)"
                >
                  Re-authenticate
                </google-button>

                <b-button v-else variant="info" @click="reAuthItem = item">
                  Re-authenticate
                </b-button>
              </div>
            </template>
          </eg-selectable-table>
        </div>

        <div v-if="show && !result.text">
          <div class="w-100">
            <div v-if="!processed" class="mb-2">
              The following essays will be exported to
              {{ LMS_PROVIDERS[platform].label }}:
            </div>
            <!-- When processed we use exportedEssays hat contains full data no need to show pagination for this total should be 0 -->
            <eg-selectable-table
              :selectable="false"
              :items="processed ? exportedEssays : showedEssays"
              :fields="fields"
              :total="processed ? exportedEssays.length : +count"
              :limit="processed ? exportedEssays.length : +limit"
              :current-page="currentPage"
              class="w-100"
              @onChangePage="(currentPage = $event) && fetchEssays()"
            >
              <template #cell(createdAt)="{ value, item }">
                <div v-if="item.exportable">
                  {{ formatDate(value) }}
                </div>
                <div v-else class="light-grey-text">
                  Not
                  <img
                    style="width: 12px; margin-bottom: 1px"
                    :src="LMS_PROVIDERS[platform].icon"
                    :alt="LMS_PROVIDERS[platform].label"
                  />
                  imported
                </div>
              </template>
              <template #cell(exportedAt)="{ value, item }">
                <div
                  v-if="
                    item.exportable || (processed && resultStatus === 'success')
                  "
                >
                  {{ value ? formatDate(value) : "Never" }}
                </div>
                <div v-else class="light-grey-text">
                  <b-icon variant="danger" icon="exclamation-circle-fill" />
                  Cannot be exported
                </div>
              </template>
            </eg-selectable-table>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import capitalize from "@/utils/capitalize";
import { exportToPlatform } from "@/api/requests/export";
import formatDate from "@/utils/formatDate";
import ExportOptions from "./export_options";
import EgSelectableTable from "@/components/global/eg_selectable_table";
import GoogleButton from "@/components/auth/google_button.vue";
import { CLASSROOM_SCOPE, LMS_PROVIDERS } from "@/constants";
import fetchEssays from "@/mixins/fetchEssays";

const results = (data) => ({
  success: {
    icon: "check-circle-fill",
    label: "Export complete!",
    variant: "success",
  },
  warning: {
    icon: "exclamation-triangle-fill",
    label: "Some exports failed",
    variant: "warning",
  },
  error: {
    icon: "exclamation-circle-fill",
    label: "Export failed",
    variant: "danger",
    text: "Something went wrong.",
  },
  auth: {
    icon: "exclamation-circle-fill",
    label: "Authentication credentials expired",
    variant: "danger",
    text: `Re-authenticate your account ${
      data.email ? data.email : ""
    } to export essays.`,
  },
});
export default {
  name: "ExportToPlatformModal",

  components: {
    EgSelectableTable,
    GoogleButton,
    ExportOptions,
  },

  // essays
  // limit
  // count
  // currentPage
  // defaultFilters
  // tableLoading
  // fetchEssays
  // resetData
  mixins: [fetchEssays],

  props: {
    availableOptions: {
      type: Array,
      default: () => [
        "content",
        "feedback",
        "errors",
        "comments",
        "hideGrades",
      ],
    },

    additionalOptions: {
      type: Object,
      default: () => ({}),
    },

    selectedOptions: {
      type: Array,
      default: () => ["content", "feedback", "errors", "comments"],
    },

    exportFilters: {
      type: Object,
      default: () => ({}),
    },

    selectedEssays: {
      type: Array,
      default: () => [],
    },

    platform: {
      type: String,
      required: true,
      validate: (value) => Object.keys(LMS_PROVIDERS).includes(value),
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      LMS_PROVIDERS,
      showAll: false,
      innerSelectedOptions: [],
      permissionScope: CLASSROOM_SCOPE,
      show: false,
      reAuthItem: null,
      exporting: false,
      processed: false,
      exportedEssays: [],
      results,
      resultStatus: "success",
      resultData: {},

      reAuthFields: [
        { key: "failedEmail", label: "Email" },
        { key: "reAuthStatus", label: "Status" },
        { key: "reAuthAction", label: "" },
      ],
    };
  },

  watch: {
    selectedOptions: {
      immediate: true,
      handler(newValue) {
        this.innerSelectedOptions = newValue;
      },
    },
  },

  computed: {
    showedEssays() {
      if (this.processed) return this.exportedEssays;

      return this.showAll ? this.essays : this.selectedEssays;
    },

    selectedIds() {
      return this.showedEssays
        .filter((essay) => essay.exportable && essay.platform === this.platform)
        .map((essay) => essay.id);
    },

    result() {
      return this.results(this.resultData)[this.resultStatus];
    },

    fields() {
      const studentName = {
        key: "student",
        label: "Student name",
        formatter: (value, key, item) => {
          const student = item.Student;

          return student
            ? `${student.firstName} ${student.lastName}`
            : `Essay name: ${item.name}`;
        },
      };
      const creationTime = {
        key: "createdAt",
        label: "Imported on",
      };
      const updatedTime = {
        key: "exportedAt",
        label: "Exported last",
      };

      const actionLink = {
        key: "action",
        label: this.processed
          ? `${capitalize(this.LMS_PROVIDERS?.[this.platform]?.key || "")} Doc`
          : "",
        formatter: (value, key, item) => {
          if (item.success === undefined || item.success) {
            return {
              link: item.exportLink,
              label: "View",
            };
          }

          return {
            action: (item) => {
              this.retryEssayExportByIds([item.id]);
            },
            label: "Retry export",
            props: {
              variant: "outline-info",
            },
          };
        },
      };

      if (!this.processed) {
        return [studentName, creationTime, updatedTime, actionLink];
      }

      return [
        {
          key: "icon",
          label: "",
          formatter: (value, key, item) => {
            return {
              icon: item.success
                ? "check-circle-fill"
                : "exclamation-circle-fill",
              style: {
                color: item.success ? "#28a745" : "#dc3545",
              },
            };
          },
        },
        studentName,
        updatedTime,
        actionLink,
      ];
    },

    closeText() {
      return this.processed ? "Close" : "Cancel";
    },
  },

  methods: {
    formatDate,

    onReAuth() {
      this.resultData.forEach((_, index) => {
        this.$set(this.resultData[index], "reAuthStatus", true);
      });
    },

    onReAuthGoogle(reAuthEssay, { isGCAvailable, email }) {
      if (!isGCAvailable) {
        this.$showToastError("All permissions are required to export essays.");
      }

      const reAuthEssayIndex = this.resultData.findIndex(
        (essay) => essay.id === reAuthEssay.id || essay.failedEmail === email
      );

      if (reAuthEssayIndex > -1) {
        this.$set(
          this.resultData[reAuthEssayIndex],
          "reAuthStatus",
          isGCAvailable
        );
      }
    },

    retryExport() {
      this.processed = false;
      this.resultStatus = "success";
    },

    updateExportingStatuses() {
      const { authErrorEssays, failedEmailEssays, successEssays } =
        this.exportedEssays.reduce(
          (acum, essay) => {
            if (essay.success) {
              acum.successEssays.push(essay);

              return acum;
            } else if (essay.failedEmail) {
              acum.authErrorEssays.push(essay);

              return acum;
            } else {
              acum.failedEmailEssays.push(essay);

              return acum;
            }
          },
          { authErrorEssays: [], failedEmailEssays: [], successEssays: [] }
        );

      if (authErrorEssays.length) {
        this.resultStatus = "auth";
        this.resultData = authErrorEssays;
      } else if (successEssays.length === 0) {
        this.resultStatus = "error";
        this.resultData = failedEmailEssays;
      } else if (successEssays.length < this.exportedEssays.length) {
        this.resultStatus = "warning";
      } else {
        this.resultStatus = "success";
      }
    },

    retryAllFailedExports() {
      const failedExportEssayIds = this.exportedEssays
        .filter((exportedEssay) => !exportedEssay.success)
        .map((exportedEssay) => exportedEssay.id);

      return this.retryEssayExportByIds(failedExportEssayIds);
    },

    async retryEssayExportByIds(essayIds) {
      try {
        const data = await this.exportToPlatform(essayIds);
        data.forEach((essay) => {
          const exportedEssay = this.exportedEssays.find((exportedEssay) => {
            return exportedEssay.id === essay.id;
          });

          // change data in exportedEssays by link
          this.$set(exportedEssay, "success", essay.success);
          this.$set(exportedEssay, "exportLink", essay.exportLink);
        });

        this.updateExportingStatuses();
      } catch (error) {
        // ignore errors and continue
      }
    },

    async exportSelectedToPlatform(essayIds = this.selectedIds) {
      try {
        const data = await this.exportToPlatform(essayIds);
        this.exportedEssays = data;

        this.updateExportingStatuses();
      } catch (error) {
        // ignore errors and continue
      }
    },

    async exportToPlatform(essayIds = this.selectedIds) {
      this.exporting = true;
      try {
        const { data } = await exportToPlatform(this.platform, {
          essaysIds: this.showAll ? [] : essayIds,
          fields: this.innerSelectedOptions,
          filters: this.showAll ? this.exportFilters : {},
        });
        this.exporting = false;
        this.processed = true;

        return data;
      } catch (error) {
        this.exporting = false;
        if (
          error.response.data.code === "oauthAuthencationRequired" ||
          error.response.data.error.includes("reconnect")
        ) {
          this.processed = true;
          this.resultStatus = "auth";

          if (error.response.data.meta.accountsPingResult) {
            this.resultData = error.response.data.meta.accountsPingResult.map(
              (account) => ({
                failedEmail: account.email,
                reAuthStatus: account.success,
              })
            );
          }

          throw error;
        }

        this.$showToastError(error.message);
      }
    },

    async onShow() {
      this.show = true;
      this.resetEssaysData();
      this.showAll = this.selectedEssays.length === 0;

      if (this.selectedEssays.length === 0) {
        this.defaultFilters = this.exportFilters;
        this.fetchEssays();
      }
    },

    onHide() {
      this.show = false;
      this.processed = false;
      this.reAuthItem = null;
      this.$emit("hide");
    },
  },
};
</script>

<style lang="scss" scoped>
.export-to-platform-modal {
  &::v-deep {
    .modal-body {
      min-height: 330px;
    }
  }
}
.small-checkbox.custom-checkbox.b-custom-control-sm {
  min-height: 1rem;
}

.icon-box-arrow-up-right {
  margin-bottom: 1px;
  margin-left: 1px;
}

.assignment-select {
  &::v-deep {
    .items-wrapper {
      max-height: 130px;
    }
  }
}
</style>
