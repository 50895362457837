var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ isExporting: _vm.isExporting }},[_c('b-overlay',{attrs:{"show":_vm.loading,"spinner-small":"","rounding":"sm","no-wrap":""},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('b-spinner',{attrs:{"small":"","variant":"info"}})]},proxy:true}])}),_c('div',{staticClass:"d-flex flex-wrap align-items-center"},[_c('h5',{staticClass:"mt-auto mb-auto mr-auto"},[_vm._v("Rubric breakdown")]),_c('rubric-select',{staticClass:"rubric-select",attrs:{"data-html2canvas-ignore":"","disabled":_vm.rubrics.length === 0,"rubrics":_vm.rubrics,"value":_vm.selectedRubric},on:{"input":_vm.loadGradingCriteria}}),_c('filter-dropdown',{staticClass:"ml-2",attrs:{"data-html2canvas-ignore":"","button-label":_vm.dateRange
          ? `Date range: ${_vm.formatDate(
              _vm.dateRange.from,
              'MMM YY'
            )} - ${_vm.formatDate(_vm.dateRange.to, 'MMM YY')}`
          : 'Select date range',"disabled":_vm.rubrics.length === 0,"active":false,"right":false,"title":"Date range"},on:{"apply":function($event){_vm.dateRange = _vm.tempDateRange;
        _vm.loadGradingCriteria(_vm.selectedRubric);},"delete":function($event){_vm.dateRange = null;
        _vm.tempDateRange = null;
        _vm.loadGradingCriteria(_vm.selectedRubric);}},scopedSlots:_vm._u([{key:"default",fn:function({ resetSignal }){return [_c('date-range',{attrs:{"reset-signal":resetSignal},model:{value:(_vm.tempDateRange),callback:function ($$v) {_vm.tempDateRange=$$v},expression:"tempDateRange"}})]}}])})],1),(!_vm.loadingRubrics && _vm.gradingCriteria.length == 0)?_c('empty-state',{staticClass:"mt-5",attrs:{"title":"No assignments selected or found during this date range."}},[_c('img',{attrs:{"src":require("../../assets/icons/chart.svg"),"width":"48","alt":"EssayGrader Empty State"}})]):_c('eg-selectable-table',{staticClass:"table",attrs:{"loading":_vm.loading || _vm.loadingRubrics,"items":_vm.gradingCriteria,"total":_vm.gradingCriteria.length,"limit":_vm.gradingCriteria.length,"fields":_vm.fields,"selectable":false},scopedSlots:_vm._u([{key:"cell(avgScore)",fn:function({ item }){return [_c('div',{staticClass:"d-flex align-items-center avg-score"},[_c('div',{staticClass:"mr-2 flex-shrink-0"},[_vm._v(" "+_vm._s(item.avgGrade || 0)+" / "+_vm._s(item.maxGrade)+" ")]),_c('b-progress',{staticClass:"w-100 rounded-pill",attrs:{"variant":"info","height":"12px","value":item.avgGrade || 0,"max":item.maxGrade}})],1)]}},{key:"cell(graph)",fn:function({ item }){return [(item.graph?.length > 1)?_c('chart-js-line',{key:item.name + _vm.isExporting,attrs:{"width":200,"height":30,"chart-options":_vm.generateChartOptions(item),"chart-data":_vm.generateChartData(item)}}):_c('div',[_vm._v("Requires 2+ assignments")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }