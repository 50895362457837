<template>
  <span
    :ref="`chunk-${chunk.highlightIndex}`"
    :style="{
      '--border-color': chunk.color,
      '--background-color': chunk.backgroundColor,
    }"
    :class="{
      'position-relative': true,
      [chunk.type]: true,
      'range-text': chunk.highlightIndex !== undefined,
      hovered,
      selection: isSelected,
    }"
    @click="handleClick($event, chunk)"
    @mouseover="onMouseover(chunk)"
    @mouseleave="onMouseleave(chunk)"
  >
    <eg-svg-text
      v-if="chunk.highlightIndex !== undefined && chunk.type !== 'selection'"
      :text="svgHighlightIndexText"
      :size="10"
      class="number"
      :data-number="chunk.highlightIndex"
    />

    <span :data-range="chunk.range"
      >{{ chunk.text
      }}<template v-if="chunk.children && chunk.children.length"
        ><HighlightRange
          v-for="(child, index) in chunk.children"
          :chunk="child"
          :key="index"
          :activeHighlightIndex="activeHighlightIndex"
          @highlightClicked="$emit('highlightClicked', $event)" /></template
    ></span>
  </span>
</template>

<script>
import EgSvgText from "@/components/global/svgText/eg_svg_text.vue";

export default {
  name: "HighlightRange",

  components: {
    EgSvgText,
  },

  props: {
    chunk: {
      type: Object,
      required: true,
    },
    activeHighlightIndex: {
      type: Number,
      default: -1,
    },
  },

  data() {
    return {
      hovered: false,
    };
  },

  computed: {
    isSelected() {
      return this.chunk.highlightIndex === this.activeHighlightIndex + 1;
    },

    svgHighlightIndexText() {
      const hasNeighbors =
        this.chunk?.children?.[0]?.highlightIndex !== undefined;

      return `${this.chunk.highlightIndex}${hasNeighbors ? "," : ""}`;
    },
  },

  watch: {
    isSelected() {
      if (this.isSelected && this.chunk.type !== "selection") {
        this.$el.scrollIntoView({ behavior: "smooth" });
      }
    },
  },

  methods: {
    onMouseover(chunk) {
      if (chunk.highlightIndex === undefined) return;

      this.hovered = true;
      this.$emit("highlightHovered", chunk);
    },

    onMouseleave(chunk) {
      if (chunk.highlightIndex === undefined) return;

      this.hovered = false;
      this.$emit("highlightUnhovered", chunk);
    },

    handleClick(e, chunk) {
      if (chunk.highlightIndex === undefined && chunk.type !== "selection")
        return;

      this.$emit("highlightClicked", chunk);
      e.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
.number {
  user-select: none;
  position: relative;
  top: -8px;
  left: 0px;
}

.range-text {
  cursor: pointer;
  border-bottom: 2px solid transparent;
  border-color: var(--border-color);
  position: relative;

  // can be from chunk.type === 'selection' and added if this.isSelected
  &.selection {
    background-color: var(--background-color);
    border-color: transparent;
  }

  &.hovered .range-text:not(span.hovered) {
    border-color: transparent;
  }

  &.hovered:not(:has(span.hovered)) {
    background-color: var(--background-color);
  }
}
</style>
