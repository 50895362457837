<template>
  <router-link
    v-if="student"
    class="student-link d-flex align-items-center justify-content-between"
    :to="`/essays?studentId=${student.id}`"
    v-b-tooltip.hover
    :title="fullName"
  >
    <div v-if="student.avatar" class="student-avatar d-flex flex-shrink-0 mr-1">
      <img :src="student.avatar" alt="Student avatar" />
    </div>
    <slot name="prefix" /> {{ fullName }} <slot name="suffix" />
  </router-link>
</template>

<script>
export default {
  props: {
    student: {
      type: Object,
      required: true,
    },
  },

  computed: {
    fullName() {
      return `${this.student.firstName} ${this.student.lastName}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.student-avatar {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--light);

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}
</style>
