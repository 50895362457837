import { render, staticRenderFns } from "./ConfirmDeleteAccountModal.vue?vue&type=template&id=782449ce&scoped=true&"
import script from "./ConfirmDeleteAccountModal.vue?vue&type=script&lang=js&"
export * from "./ConfirmDeleteAccountModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "782449ce",
  null
  
)

export default component.exports