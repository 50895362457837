import api from "@/api/api";

export const register = (payload) => {
  return api.post("/register", payload);
};

export const login = (payload) => {
  return api.post("/login", payload);
};

export const googleLoginCallback = (googleResp) => {
  return api.get(
    `/auth/google/callback?${new URLSearchParams(googleResp).toString()}`
  );
};

export const googlePermissionsCallback = (googleResp) => {
  return api.get(
    `/auth/google/permissions?${new URLSearchParams(googleResp).toString()}`
  );
};

export const resetPasswordV2 = (payload) => {
  return api.post("/password-reset", payload);
};

export const forgotPassword = (payload) => {
  return api.post("/forgot-password", payload);
};

export const deleteAccount = (myId) => {
  return api.delete(`/users/${myId}`);
};
