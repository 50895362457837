import { reGradeEssay } from "@/api";

export default {
  data() {
    return {
      regradedEssay: {
        id: 1,
      },
      loadingEssay: false,
      loadingFeedback: false,
    };
  },

  methods: {
    async regrade(essay) {
      // TODO: Remove after the release v3.0
      // if (!essay.Rubric || essay.Rubric.type === "Simple") {
      //   await this.regradeRequest(essay.id);
      //
      //   return true;
      // }

      this.regradedEssay = essay;
      this.$nextTick(() => {
        this.$bvModal.show("essay-regrade-modal");
      });

      return false;
    },

    async regradeRequest(essayId, payload = {}) {
      try {
        this.loadingFeedback = true;

        await reGradeEssay(essayId, payload);

        this.$bvToast.toast("The essay is queued to be regraded.", {
          title: "Success",
          variant: "success",
        });
        this.loadingFeedback = false;
      } catch (error) {
        this.loadingFeedback = false;
      }
    },
  },
};
