import CellName from "@/components/essay/table/cell_name.vue";

export default (flag) => ({
  name: "FlagPlagiarismSuggestions",

  components: {
    CellName,
  },

  data: function () {
    return {
      flag,
    };
  },

  render: function (h) {
    return h(
      "div",
      {
        class: ["d-flex"],
      },
      [
        h(
          "div",
          {
            class: ["flex-shrink-0", "mr-2"],
          },
          `This essay is similar to:`
        ),
        h(
          "div",
          {
            class: ["overflow-hidden"],
          },
          this.flag.similarEssays.map((similarEssay) => {
            return h("cell-name", {
              class: ["mb-2"],
              props: {
                item: similarEssay,
                showGrade: true,
              },
            });
          })
        ),
      ]
    );
  },
});
