<template>
  <div class="feedback-box content">
    <grammar-flags-base-items
      :grouped-items="groupedItems"
      :types="types"
      :is-no-ranges="isNoRanges"
      :activeHighlightIndex="activeHighlightIndex"
      next-step-title="Suggestions:"
      @updateActiveHighlightIndex="$emit('updateActiveHighlightIndex', $event)"
      @updateHighlights="$emit('updateHighlights', $event)"
      @delete="deleteEssayTabData"
    />

    <base-item
      :item="positiveFeedback"
      :index="-3"
      @update="updateGrammarPositive"
    />
    <base-item :item="overAllCriteria" :index="-2" @update="updateEssay" />
  </div>
</template>

<script>
import BaseItem from "@/components/essay/essay/v3/base_item.vue";
import GrammarFlagsBaseItems from "@/components/essay/essay/v3/grammar_flags_base_items.vue";
import generateBaseReportV3Mixin from "@/components/essay/essay/v3/mixins/base_report_v3";
import { ERROR_BREAKDOWN_TYPES } from "@/constants";

export default {
  components: {
    BaseItem,
    GrammarFlagsBaseItems,
  },

  // this.activeHighlightIndex
  // this.items
  // this.essay
  // this.isNoRanges
  // this.deleteEssayTabData
  // this.updateEssay
  mixins: [generateBaseReportV3Mixin("grammar")],

  data() {
    return {
      types: ERROR_BREAKDOWN_TYPES,
    };
  },
  computed: {
    positiveFeedback() {
      return {
        title: "Positive feedback",
        analysis: this.essay?.summary?.grammar?.positive,
        suggestions: "",
      };
    },

    overAllCriteria() {
      return {
        title: "Overall improvement plan",
        analysis: this.essay?.summary?.grammar?.analysis,
        suggestions: this.essay?.summary?.grammar?.suggestions,
        isList: true,
      };
    },

    groupedItems() {
      return this.items.reduce((groupedItems, item) => {
        if (!groupedItems[item.type]) {
          groupedItems[item.type] = [];
        }

        groupedItems[item.type].push({
          ...item,
          analysis: `<strong>Error: </strong>${item.analysis}${
            item.impact ? `<p><strong>Impact: </strong>${item.impact}</p>` : ""
          }`,
        });

        return groupedItems;
      }, {});
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback-box {
  white-space: pre-wrap;
  padding: 16px;
  border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  height: 100%;
  max-height: calc(100vh - 298px);
  overflow: auto;
}

.feedback-box.content,
.feedback-box.ql-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
  padding-top: 0px;
}

.feedback-box.content {
  padding-top: 15px;
}
</style>
