import api from "@/api/api";
import sampleFreeToolsResponse from "@/api/samples/freeTools";

export const getFreeToolsRubrics = () => {
  return api.get("/rubrics", { params: { isUsedInFreeTools: true } });
};

export const freeToolGrade = async (payload) => {
  try {
    return api.post("/free-tools/grade", payload, {
      baseURL: process.env.VUE_APP_EG_TOOLS_URL,
    });
  } catch (error) {
    return { data: sampleFreeToolsResponse };
  }
};

export const freeToolAiDetection = async (payload) => {
  return api.post("/ai-tools/ai-detection", payload);
};

export const freeToolAiSummary = async (payload) => {
  return api.post("/ai-tools/summary", payload, {
    baseURL: process.env.VUE_APP_EG_TOOLS_URL,
  });
};

export const createLetterOfRecommendation = (payload) => {
  return api.post("/ai-tools/letter-of-recommendation", payload);
};

export const createTongueTwisters = (payload) => {
  return api.post("/ai-tools/tongue-twister", payload);
};
