<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="`0 0 ${metrics.width} ${metrics.height}`"
    :height="size"
  >
    <path :fill="fill" :stroke="stroke" :d="d" />
  </svg>
</template>

<script>
import textToSVG from "./textToSvg.js";

export default {
  props: {
    text: {
      type: [Number, String],
      required: true,
    },
    fill: {
      type: String,
      default: "black",
    },
    stroke: {
      type: String,
      default: "transparent",
    },
    size: {
      type: Number,
      default: 16,
    },
  },

  data() {
    return {
      metrics: {
        width: 0,
        height: 0,
      },
      d: "",
    };
  },

  watch: {
    text() {
      this.init();
    },
  },

  async created() {
    //TODO: move load on top - need to load font once
    this.textToSVG = await textToSVG();
    this.init();
  },

  methods: {
    init() {
      const options = {
        x: 0,
        y: 0,
        fontSize: this.size,
        anchor: "top",
      };

      this.metrics = this.textToSVG.getMetrics(this.text.toString(), options);
      this.d = this.textToSVG.getD(this.text.toString(), options);
    },
  },
};
</script>
