<template>
  <div v-if="flag.type === 'usedAI'">
    <div class="d-flex align-items-center">
      <b-icon variant="danger" class="mr-3" icon="flag" />
      <div class="flag-title">AI Usage Report</div>
    </div>
    <div class="mt-2">
      <div>
        Our detector is <b>highly confident</b> that the text
        <b
          >is
          {{ flag.summary.aiUsageScore > 50 ? "" : "not" }}
          written</b
        >
        by AI.
      </div>
    </div>

    <div class="mt-2">
      <b>Detection summary:</b>
      <ul>
        <li>
          There is a
          <b>{{ Math.round(flag.summary.aiUsageScore) }}%</b>
          chance this text was entirely written by AI.
        </li>
        <li>
          <b>{{ flag.summary.sentencesAffected }}</b>
          sentences were likely written by AI.
        </li>
        <li>
          Burstiness score (the amount of variation in the perplexity of the
          document) of
          <b>{{ flag.summary.burstinessScore }}</b
          >.
        </li>
      </ul>
    </div>
    <div class="mt-2">
      <eg-additional-collapse label="Show sentences written by AI">
        <ul>
          <li v-for="(sentence, index) in flag.sentences" :key="index">
            {{ sentence }}
          </li>
        </ul>
      </eg-additional-collapse>
    </div>
  </div>
</template>

<script>
import EgAdditionalCollapse from "@/components/global/eg_additional_collapse.vue";

export default {
  components: {
    EgAdditionalCollapse,
  },

  props: {
    flag: {
      type: Object,
      required: true,
    },
  },
};
</script>
