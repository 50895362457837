<template>
  <b-row>
    <b-col lg="6" md="9">
      <div>
        <h1
          v-b-tooltip.hover
          :title="essay.name"
          class="mb-1 essay-title text-truncate"
        >
          {{ essay.name }}
        </h1>
      </div>
    </b-col>
    <b-col lg="6" md="3" class="print-feedback-column">
      <div class="text-right">
        <essay-actions-dropdown
          :essay="essay"
          variant="outline-info"
          @move="showEssayMovedModal"
          @delete="deleteSingleEssay"
          @regrade="regradeHandler"
          @print="printEssayFeedback"
          @export="({ type }) => $bvModal.show(`export-to-${type}-modal`)"
          @rename="$bvModal.show(`essay-rename-modal`)"
          @copy="copyData($event)"
        />
      </div>
    </b-col>
    <b-col>
      <div class="d-flex align-items-center">
        <b-badge v-if="essayClass" class="mr-2" pill variant="secondary-light">
          <b-icon icon="folder"></b-icon>
          {{ essayClass }}
        </b-badge>
        <b-badge
          v-if="essay.Rubric"
          class="mr-2"
          pill
          variant="secondary-light"
        >
          <b-icon icon="book"></b-icon>
          {{ essay.Rubric.name }}
        </b-badge>
        <b-badge
          v-else-if="essay.type"
          class="mr-2"
          pill
          variant="secondary-light"
        >
          <b-icon icon="book"></b-icon>
          {{ formatDefaultType(essay) }}
        </b-badge>

        <b-badge
          v-if="essay.intensity"
          class="mr-2"
          pill
          variant="secondary-light"
        >
          <b-icon icon="bookshelf"></b-icon>
          {{ getIntensityDisplayText(essay.intensity) }}
        </b-badge>

        <student-link
          v-if="essay.Student"
          class="light-grey-text small-text mr-2"
          :student="essay.Student"
        >
          <template #prefix>By</template>
        </student-link>

        <span class="light-grey-text small-text"
          >Graded on {{ gradedDate }}</span
        >

        <div class="d-flex align-items-center ml-auto">
          <b-button
            class="mr-2"
            :variant="isReviewed ? 'secondary' : 'outline-info'"
            @click="handleClickReview"
          >
            <b-icon
              class="mr-0 mr-lg-2"
              icon="check2-circle"
              aria-hidden="true"
            />
            <span>{{ isReviewed ? "Reviewed" : "Mark as reviewed" }}</span>
          </b-button>

          <div v-if="pageInfo" class="pagination d-flex align-items-center">
            <b-button
              :disabled="paginationIsLoading || !pageInfo.hasPreviousPage"
              variant="outline-secondary"
              @click="goPrev"
            >
              <b-icon scale="1" icon="arrow-left"></b-icon>
            </b-button>
            <div v-if="!isReviewed" class="mx-2">
              Not reviewed:
              {{ pageInfo.current }}
              of
              {{ pageInfo.totalCount }}
            </div>
            <div v-else class="mx-2">{{ nextEssayReviewLabel }}</div>
            <b-button
              :disabled="paginationIsLoading || !pageInfo.hasNextPage"
              variant="outline-secondary"
              @click="goNext"
            >
              <b-icon icon="arrow-right"></b-icon>
            </b-button>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import Vue from "vue";
import hotkeys from "hotkeys-js";
import regradeMixin from "@/mixins/regradeMixin";
import { deleteEssay, updateEssay } from "@/api";
import { getIntensityDisplayText, formatDefaultType } from "@/utils/format";
import formatDate from "@/utils/formatDate";
import EssayActionsDropdown from "@/components/essay/essay_actions_dropdown.vue";
import StudentLink from "@/components/students/student_link.vue";
import feedbackReport from "@/components/essay/essay/v3/feedback_report_v3.vue";
import grammarReport from "@/components/essay/essay/v3/grammar_report_v3.vue";
import strIsHTML from "@/utils/strIsHTML";
import copyRichText from "@/utils/copyRichText";

export default {
  components: {
    EssayActionsDropdown,
    StudentLink,
  },

  mixins: [
    // this.regrade
    regradeMixin,
  ],

  props: {
    essay: {
      type: Object,
      required: true,
    },
    getEssay: {
      type: Function,
      required: true,
    },
    pageInfo: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      paginationIsLoading: false,
    };
  },

  computed: {
    essayClass() {
      return this.essay.class;
    },
    isReviewed() {
      return this.essay.status === "Reviewed";
    },
    gradedDate() {
      return formatDate(this.essay.createdAt);
    },
    nextEssayReviewLabel() {
      return this.pageInfo.hasNextPage || this.pageInfo.hasPreviousPage
        ? "Next essay needing review"
        : "All essays reviewed";
    },
  },

  async created() {
    hotkeys("right", this.goNext);
    hotkeys("left", this.goPrev);
  },

  beforeDestroy() {
    hotkeys.unbind("right", this.goNext);
    hotkeys.unbind("left", this.goPrev);
  },
  methods: {
    formatDefaultType,
    getIntensityDisplayText,

    async copyData(type) {
      // NOTE: copyRichText should run firstly and immediately, any async operation should be inside copyRichText
      // first we create a pomise that wait when vue component fully loaded, and save it to reuse for richText and plainText
      let vueInstancePromise = null;
      const component = type === "grammar" ? grammarReport : feedbackReport;

      const generateVueInstance = () => {
        vueInstancePromise = new Promise((resolve, reject) => {
          try {
            new Vue({
              propsData: {
                essay: this.essay,
              },

              extends: component,

              provide: {
                specialForCopyPaste: true,
              },

              watch: {
                loading() {
                  if (this.loading === false) {
                    resolve(this);
                  }
                },
              },
            }).$mount();
          } catch (error) {
            reject(error);
          }
        });

        return vueInstancePromise;
      };

      const getRichTextBlob = () => {
        return (
          vueInstancePromise ? vueInstancePromise : generateVueInstance()
        ).then((vm) => {
          let richText = vm.$el.innerHTML;

          if (strIsHTML(richText)) {
            let div = document.createElement("div");
            div.innerHTML = richText;
            richText = div.innerHTML;
            div = null;
          }

          return new Blob([richText], { type: "text/html" });
        });
      };

      const getPlainTextBlob = () => {
        return (
          vueInstancePromise ? vueInstancePromise : generateVueInstance()
        ).then((vm) => {
          return new Blob([vm.$el.innerText], { type: "text/plain" });
        });
      };

      try {
        await copyRichText(getRichTextBlob, getPlainTextBlob);
        // destroy vue instance after copy
        vueInstancePromise && vueInstancePromise.then((vm) => vm?.$destroy());
        vueInstancePromise = null;

        this.$bvToast.toast(
          `The essay ${type} section was copied successfully.`,
          {
            title: "Copy",
            variant: "success",
            autoHideDelay: 3000,
            solid: true,
          }
        );
      } catch (error) {
        this.$showToastError(
          `There was an error copying the essay ${type} section. Please try again.`
        );
      }
    },

    showEssayMovedModal() {
      this.$bvModal.show("move-essay-modal");
    },

    async regradeHandler() {
      this.$emit("regradingChange", true);
      await this.regrade(this.essay);
      this.$emit("regradingChange", false);
    },

    async deleteSingleEssay() {
      try {
        this.loading = true;

        await deleteEssay(this.essay.id);

        this.$router.push("/essays?deleted=true");
      } catch (error) {
        this.loading = false;
        this.error = error.response?.data?.error;
      }
    },

    printEssayFeedback() {
      window.print();
    },

    handleClickReview() {
      if (this.isReviewed) return;

      this.changeStatus({ status: "Reviewed" });
    },

    async changeStatus({ status }) {
      try {
        this.$emit("loadingChanged", true);

        await updateEssay(this.essay.id, {
          status,
        });

        this.$emit("essayStatusChanged", status);

        this.$bvToast.toast(`The essay was marked as ${status}.`, {
          title: "Success",
          variant: "success",
          solid: true,
          autoHideDelay: 5000,
        });
        this.$emit("loadingChanged", false);
      } catch (error) {
        this.$showToastError("Something went wrong. Please try again.");
        this.$emit("loadingChanged", false);
      }
    },

    async goNext() {
      if (
        this.paginationIsLoading ||
        !this.pageInfo ||
        !this.pageInfo.hasNextPage
      )
        return;

      this.paginationIsLoading = true;
      await this.getEssay({ after: this.pageInfo.endCursor });
      await this.$router.push(`/essay/${this.essay.id}`);
      this.paginationIsLoading = false;
    },

    async goPrev() {
      if (
        this.paginationIsLoading ||
        !this.pageInfo ||
        !this.pageInfo.hasPreviousPage
      )
        return;

      this.paginationIsLoading = true;
      await this.getEssay({ before: this.pageInfo.startCursor });
      await this.$router.push(`/essay/${this.essay.id}`);
      this.paginationIsLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.essay-title {
  overflow-wrap: break-word;
  color: #000;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
}

.pagination {
  .btn {
    padding: 7px 8px;
    line-height: 1;
  }
  .b-icon.bi {
    font-size: 16px;
  }
}

.btn-ai {
  display: inline;

  img {
    display: inline;
    width: 20px;
    height: 20px;

    &.hover {
      display: none;
    }
  }

  &:hover {
    img {
      display: none;

      &.hover {
        display: inline;
      }
    }
  }

  @media (max-width: 991px) {
    span {
      display: none;
    }
  }
}
</style>
