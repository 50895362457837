import api from "@/api/api";
import store from "@/store";

export const feedback = (payload) => {
  return api.post("/essays", { essays: payload });
};

export const extractFileData = (formData) => {
  return api.post("/extract", formData, {
    headers: {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${store.state.token}`,
    },
  });
};

export const getEssay = (essayId, params = {}) => {
  return api.get(`/essays/${essayId}/cursor`, { params });
};

export const getEssayFlags = (essayId) => {
  return api.get(`/essays/${essayId}/flags`);
};

export const createFlagsManually = (essayId) => {
  return api.post(`/essays/${essayId}/flags`, {
    flags: [{ type: "usedAI" }, { type: "plagiarism" }],
  });
};

export const updatesEssay = (essayId, updates = {}) => {
  return api.patch(`/essays/${essayId}`, updates);
};

export const reGradeEssay = (essayId, payload) => {
  return api.put(`/essays/${essayId}/re-grade`, payload);
};

export const updateEssay = (id, payload) => {
  return api.patch(`/essays/${id}`, payload);
};

export const deleteEssay = (essaysIds, filters) => {
  if (!Array.isArray(essaysIds)) {
    essaysIds = [essaysIds];
  }

  let payload = {};

  if (essaysIds.length > 0) {
    payload.essaysIds = essaysIds;
  } else {
    payload.filters = filters;
  }

  return api.delete("/essays", { data: payload });
};

export const getAllEssays = ({
  limit = 50,
  page,
  sortBy = "createdAt",
  sortDesc,
  filters,
}) => {
  if (sortBy === "Rubric") sortBy = "rubric";

  const sort = `${sortBy}:${sortDesc ? "DESC" : "ASC"}`;
  const params = { page, sort, limit };
  const filtersKeys = Object.keys(filters);

  if (filters && filtersKeys.some((key) => filters[key])) {
    filtersKeys.forEach((key) => {
      if (filters[key]) {
        if (key === "createdAt") {
          params[`${key}From`] = filters[key]["from"];
          params[`${key}To`] = filters[key]["to"];

          return;
        }

        params[key] = filters[key];
      }
    });
  }

  return api.get("/essays", {
    params,
  });
};

export const getCounters = () => {
  return api.get("/essays/counters");
};

// TODO: generate crud by essay and type
export const getEssayFeedback = (essayId) => {
  return api.get(`/essays/${essayId}/feedback-report`);
};

export const updateEssayFeedbackRecord = (essayId, recordId, payload) => {
  return api.patch(`/essays/${essayId}/feedback-report/${recordId}`, payload);
};

export const deleteEssayFeedbackRecord = (essayId, recordId) => {
  return api.delete(`/essays/${essayId}/feedback-report/${recordId}`);
};

export const getEssayGrammar = (essayId) => {
  return api.get(`/essays/${essayId}/grammar-report`);
};

export const updateEssayGrammarRecord = (essayId, recordId, payload) => {
  return api.patch(`/essays/${essayId}/grammar-report/${recordId}`, payload);
};

export const deleteEssayGrammarRecord = (essayId, recordId) => {
  return api.delete(`/essays/${essayId}/grammar-report/${recordId}`);
};

export const getEssayComments = (essayId) => {
  return api.get(`/essays/${essayId}/comments`);
};

export const addComment = (essayId, { ranges, text }) => {
  return api.post(`/essays/${essayId}/comments`, { text, ranges });
};

export const updateComment = (essayId, commentId, text) => {
  return api.patch(`/essays/${essayId}/comments/${commentId}`, { text });
};

export const deleteComment = (essayId, commentId) => {
  return api.delete(`/essays/${essayId}/comments/${commentId}`);
};
