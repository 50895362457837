<template>
  <b-dropdown
    class="essay-actions-dropdown"
    :variant="variant"
    no-caret
    boundary="viewport"
    dropleft
  >
    <template #button-content>
      <b-icon icon="three-dots-vertical" aria-hidden="true"></b-icon>
    </template>
    <b-dropdown-item v-if="$listeners.rename" @click="$emit('rename', essay)">
      Edit essay name
    </b-dropdown-item>
    <b-dropdown-item v-if="$listeners.regrade" @click="$emit('regrade', essay)">
      Re-grade
    </b-dropdown-item>
    <b-dropdown-item v-if="$listeners.move" @click="$emit('move', essay.id)">
      Move to
    </b-dropdown-item>
    <b-dropdown-item
      v-if="$listeners.copy && isV3"
      @click="$emit('copy', 'feedback')"
    >
      Copy Feedback report
    </b-dropdown-item>
    <b-dropdown-item
      v-if="$listeners.copy && isV3"
      @click="$emit('copy', 'grammar')"
    >
      Copy Writing report
    </b-dropdown-item>

    <b-dropdown-divider v-if="showExportSection"></b-dropdown-divider>
    <b-dropdown-item v-if="$listeners.print" @click="$emit('print', essay)">
      Print feedback
    </b-dropdown-item>
    <b-dropdown-item
      v-if="
        $listeners.export &&
        essay.exportable &&
        essay.platform?.includes('google')
      "
      @click="$emit('export', { type: 'google', essay })"
    >
      <div>Export to Google Classroom</div>
      <div class="small-text" v-if="essay.exportedAt">
        Last exported on {{ formatDate(essay.exportedAt) }}
      </div>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="
        $listeners.export &&
        essay.exportable &&
        essay.platform?.includes('canvas')
      "
      @click="$emit('export', { type: 'canvas', essay })"
    >
      <div>Export to Canvas</div>
      <div class="small-text" v-if="essay.exportedAt">
        Last exported on {{ formatDate(essay.exportedAt) }}
      </div>
    </b-dropdown-item>
    <b-dropdown-item
      v-if="$listeners.export"
      @click="$emit('export', { type: 'pdf', essay })"
    >
      <div>Export to PDF</div>
    </b-dropdown-item>
    <b-dropdown-divider v-if="showExportSection"></b-dropdown-divider>

    <b-dropdown-item
      v-if="$listeners.delete"
      variant="danger"
      @click="onDelete"
    >
      Delete
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import formatDate from "@/utils/formatDate";
import deleteConfirmationModalMixin from "@/mixins/deleteConfirmationModal";

export default {
  name: "EssayActionsDropdown",

  // this.showDeleteConfirmationModal
  mixins: [deleteConfirmationModalMixin],

  props: {
    essay: {
      type: Object,
      required: true,
    },
    variant: {
      type: String,
      required: false,
      default: "outline-secondary",
    },
  },

  computed: {
    showExportSection() {
      return this.$listeners.print || this.$listeners.export;
    },

    isV3() {
      return (this.essay?.tags || []).includes("v3");
    },
  },

  methods: {
    formatDate,

    async onDelete() {
      try {
        const value = await this.showDeleteConfirmationModal({
          title: "Are you sure you want to delete this essay?",
          message:
            "Essays cannot be accessed after deletion. This action cannot be undone.",
        });
        value && this.$emit("delete", [this.essay.id]);
      } catch (error) {
        this.$showToastError(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.essay-actions-dropdown {
  &::v-deep {
    .dropdown-toggle-no-caret {
      padding: 5px 3px;
      @media (min-width: 992px) {
        padding: 0.375rem 0.75rem;
      }
    }
  }
}

.small-text {
  font-size: 12px;
  color: var(--gray);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: revert;
}
</style>
