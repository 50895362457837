<script>
import AiDetectorView from "@/views/ai_detector_view.vue";
import FreeToolsWrapper from "@/views/free-tools/free-tools-wrapper.vue";

export default {
  name: "AiDetection",
  components: {
    AiDetectorView,
    FreeToolsWrapper,
  },
};
</script>

<template>
  <FreeToolsWrapper>
    <AiDetectorView requestType="free-tools" />
  </FreeToolsWrapper>
</template>
