<script>
export default {
  name: "SubscriptionPauseModal",
  props: {
    username: {
      type: String,
      required: true,
    },
    pauseSubscription: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      selectedMonths: 1,
      options: [
        { value: 1, text: "1 month" },
        { value: 2, text: "2 months" },
        { value: 3, text: "3 months" },
        { value: 4, text: "4 months" },
        { value: 5, text: "5 months" },
        { value: 6, text: "6 months" },
      ],
    };
  },
  methods: {
    pause() {
      this.pauseSubscription(this.selectedMonths)
        .then(() => {
          this.$bvModal.hide("subscription_pause_modal");
          this.$bvToast.toast("Your plan was successfully paused.", {
            title: "Success!",
            variant: "success",
            solid: true,
          });
          document.getElementById("app").scrollIntoView({ behavior: "smooth" });
        })
        .catch(() => {}); // error handled on pauseSubscription
    },
  },
};
</script>

<template>
  <b-modal
    id="subscription_pause_modal"
    :title="`${username} take a pause!`"
    size="lg"
    ok-variant="danger"
    centered
    no-stacking
    hide-footer
  >
    <p>
      We get it - there are times during a school year when you don't need to
      use all the advanced AI features. You've got choices. Pause your plan for
      up to 6 months. The pause will begin immediately, and you will not be
      charged during your pause.
    </p>

    <div class="d-flex align-items-center gap-2">
      <div class="mr-3">Pause your plan for:</div>
      <b-form-select
        class="mr-3 w-auto"
        v-model="selectedMonths"
        :options="options"
      />
      <b-button variant="info" @click="pause"> Pause plan </b-button>
    </div>
  </b-modal>
</template>
