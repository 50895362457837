<template>
  <div v-if="flag.type === 'closeToGrade'">
    <h5>Grade was close to passing grade: {{ flag.summary.grade }}%</h5>

    <div class="mt-2">
      <div class="pseudo-graph py-3">
        <div class="line">
          <div
            class="grade-value"
            :style="{ left: `${flag.summary.grade}%` }"
          ></div>
          <div
            v-if="flag.summary.threshold"
            class="passing-value"
            :style="{ left: `${flag.summary.threshold}%` }"
          ></div>
        </div>
      </div>
      <div class="legend d-flex align-items-center justify-content-center">
        <div class="d-flex align-items-center">
          <div class="grade-value mr-2"></div>
          <div>Grade: {{ flag.summary.grade }}%</div>
        </div>
        <div
          v-if="flag.summary.threshold"
          class="d-flex align-items-center ml-3"
        >
          <div class="passing-value mr-2"></div>
          <div>Passing grade: ≥ {{ flag.summary.threshold }}%</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    flag: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.grade-value {
  --grade-value-size: 8px;
  width: var(--grade-value-size);
  height: var(--grade-value-size);
  border-radius: 50%;
  background-color: var(--info);
}

.passing-value {
  --passing-value-size: 11px;
  background-color: var(--danger);
  width: 3px;
  border-radius: 2px;
  height: var(--passing-value-size);
}

.line {
  --graph-line-height: 8px;
  width: 100%;
  height: var(--graph-line-height);
  border-radius: var(--graph-line-height);
  background-color: #dfdfdf;
  position: relative;

  .grade-value,
  .passing-value {
    position: absolute;
    transform: translateY(calc(-50% + var(--graph-line-height) / 2));
  }

  .grade-value {
    --grade-value-size: 16px;
  }
  .passing-value {
    --passing-value-size: 20px;
  }
}
</style>
