function processHighlights(content, highlights, parentRange) {
  let sortedHighlights = [...highlights].sort((a, b) => {
    // sort by start range
    if (a.range[0] !== b.range[0]) {
      return a.range[0] - b.range[0];
    }

    // if start the same, sort by end range in reverse order
    return b.range[1] - a.range[1];
  });

  const result = [];
  let lastEnd = parentRange[0];

  for (let index = 0; index < sortedHighlights.length; index++) {
    const highlight = sortedHighlights[index];
    const { highlightIndex, range } = highlight;

    if (highlightIndex === undefined || range === undefined) {
      console.error(
        "highlightIndex and range is required in highlight:",
        highlight
      );
      continue;
    }

    const [start, end] = highlight.range;

    // add text before current highlight's start range if needed
    if (start > lastEnd) {
      result.push({
        range: [lastEnd, start],
        text: content.slice(lastEnd, start),
        children: [],
      });
    }

    // get children for current highlight
    const children = sortedHighlights.filter(
      (h) => h !== highlight && h.range[0] >= start && h.range[0] < end
    );

    // delete current highlight and children from sortedHighlights (because they are processed in this iteration)
    sortedHighlights = sortedHighlights.filter(
      (h) => h === highlight || !(h.range[0] >= start && h.range[0] < end)
    );

    const hasChildren = children.length > 0;
    // current highlight
    const node = {
      ...highlight,
      text: hasChildren ? "" : content.slice(start, end),
      children: hasChildren
        ? processHighlights(content, children, highlight.range)
        : [],
    };

    result.push(node);

    lastEnd = Math.max(
      end,
      Math.max(...children.map((child) => child.range[1]))
    );
  }

  // Add the last part if needed
  const parentEnd = parentRange[1];

  if (lastEnd <= parentEnd) {
    result.push({
      range: [lastEnd, parentEnd],
      text: content.slice(lastEnd, parentEnd),
      children: [],
    });
  }

  return result;
}

export default processHighlights;

// TEST CASES
// TODO: when add unit tests, move this cases to test files

// const content = "0123456789-123456789";
// let highlights = [
//   { highlightIndex: 1, range: [1, 10], color: "red" },
//   { highlightIndex: 2, range: [3, 8], color: "purple" },
// ];

// const processedHighlights = processHighlights(content, highlights, [
//   0,
//   content.length,
// ]);

// const result = [
//   { range: [0, 1], text: "0", children: [] },
//   {
//     range: [1, 10],
//     color: "red",
//     highlightIndex: 1,
//     text: "",
//     children: [
//       { range: [1, 3], text: "12", children: [] },
//       {
//         range: [3, 8],
//         color: "purple",
//         highlightIndex: 2,
//         text: "34567",
//         children: [],
//       },
//       { range: [8, 10], text: "89", children: [] },
//     ],
//   },
//   { range: [10, 20], text: "-123456789", children: [] },
// ];
// console.log("🚀 ~ processedHighlights:", processedHighlights);
// console.log("🚀 ~ processedHighlights:", JSON.stringify(processedHighlights));
// console.log(
//   "🚀 ~ processedHighlights: === ",
//   JSON.stringify(processedHighlights) === JSON.stringify(result)
// );
// console.log("===========");
// console.log("===========");
// console.log("===========");

// highlights.push({ highlightIndex: 3, range: [4, 9], color: "aqua" });

// const processedHighlights1 = processHighlights(content, highlights, [
//   0,
//   content.length,
// ]);

// const result1 = [
//   { range: [0, 1], text: "0", children: [] },
//   {
//     range: [1, 10],
//     color: "red",
//     highlightIndex: 1,
//     text: "",
//     children: [
//       { range: [1, 3], text: "12", children: [] },
//       {
//         range: [3, 8],
//         color: "purple",
//         highlightIndex: 2,
//         text: "",
//         children: [
//           {
//             range: [3, 4],
//             text: "3",
//             children: [],
//           },
//           {
//             range: [4, 9],
//             color: "aqua",
//             highlightIndex: 3,
//             text: "45678",
//             children: [],
//           },
//         ],
//       },
//       { range: [9, 10], text: "9", children: [] },
//     ],
//   },
//   { range: [10, 20], text: "-123456789", children: [] },
// ];
// console.log("🚀 ~ processedHighlights:", JSON.stringify(processedHighlights1));
// console.log(
//   "🚀 ~ processedHighlights: === ",
//   JSON.stringify(processedHighlights1) === JSON.stringify(result1)
// );
// console.log("===========");
// console.log("===========");
// console.log("===========");

// highlights.push({ highlightIndex: 4, range: [5, 8], color: "coral" });

// const processedHighlights2 = processHighlights(content, highlights, [
//   0,
//   content.length,
// ]);

// const result2 = [
//   { range: [0, 1], text: "0", children: [] },
//   {
//     range: [1, 10],
//     color: "red",
//     highlightIndex: 1,
//     text: "",
//     children: [
//       { range: [1, 3], text: "12", children: [] },
//       {
//         range: [3, 8],
//         color: "purple",
//         highlightIndex: 2,
//         text: "",
//         children: [
//           {
//             range: [3, 4],
//             text: "3",
//             children: [],
//           },
//           {
//             range: [4, 9],
//             color: "aqua",
//             highlightIndex: 3,
//             text: "",
//             children: [
//               {
//                 range: [4, 5],
//                 text: "4",
//                 children: [],
//               },
//               {
//                 range: [5, 8],
//                 color: "coral",
//                 highlightIndex: 4,
//                 text: "567",
//                 children: [],
//               },
//               {
//                 range: [8, 9],
//                 text: "8",
//                 children: [],
//               },
//             ],
//           },
//         ],
//       },
//       { range: [9, 10], text: "9", children: [] },
//     ],
//   },
//   { range: [10, 20], text: "-123456789", children: [] },
// ];
// console.log("🚀 ~ processedHighlights:", JSON.stringify(processedHighlights2));
// console.log(
//   "🚀 ~ processedHighlights: === ",
//   JSON.stringify(processedHighlights2) === JSON.stringify(result2)
// );
// console.log("===========");
// console.log("===========");
// console.log("===========");

// highlights.push({ highlightIndex: 5, range: [5, 13], color: "green" });

// const processedHighlights3 = processHighlights(content, highlights, [
//   0,
//   content.length,
// ]);

// const result3 = [
//   { range: [0, 1], text: "0", children: [] },
//   {
//     range: [1, 10],
//     color: "red",
//     highlightIndex: 1,
//     text: "",
//     children: [
//       { range: [1, 3], text: "12", children: [] },
//       {
//         range: [3, 8],
//         color: "purple",
//         highlightIndex: 2,
//         text: "",
//         children: [
//           {
//             range: [3, 4],
//             text: "3",
//             children: [],
//           },
//           {
//             range: [4, 9],
//             color: "aqua",
//             highlightIndex: 3,
//             text: "",
//             children: [
//               {
//                 range: [4, 5],
//                 text: "4",
//                 children: [],
//               },
//               {
//                 range: [5, 13],
//                 color: "green",
//                 highlightIndex: 4,
//                 text: "",
//                 children: [
//                   {
//                     range: [5, 8],
//                     color: "coral",
//                     highlightIndex: 5,
//                     text: "567",
//                     children: [],
//                   },
//                   {
//                     range: [8, 13],
//                     text: "89-12",
//                     children: [],
//                   },
//                 ],
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   { range: [13, 20], text: "3456789", children: [] },
// ];
// console.log("🚀 ~ processedHighlights1:", JSON.stringify(processedHighlights3));
// console.log(
//   "🚀 ~ processedHighlights: === ",
//   JSON.stringify(processedHighlights3) === JSON.stringify(result3)
// );
// console.log("===========");
// console.log("===========");
// console.log("===========");

// const processedHighlights4 = processHighlights(
//   content,
//   [
//     { highlightIndex: 2, range: [1, 10], color: "red" },
//     { highlightIndex: 3, range: [3, 8], color: "purple" },
//     { highlightIndex: 1, range: [3, 15], color: "aqua" },
//   ],
//   [0, content.length]
// );

// const result4 = [
//   { range: [0, 1], text: "0", children: [] },
//   {
//     range: [1, 10],
//     color: "red",
//     highlightIndex: 2,
//     text: "",
//     children: [
//       { range: [1, 3], text: "12", children: [] },
//       {
//         range: [3, 15],
//         color: "aqua",
//         highlightIndex: 3,
//         text: "",
//         children: [
//           {
//             range: [3, 8],
//             color: "purple",
//             highlightIndex: 1,
//             text: "34567",
//             children: [],
//           },
//           {
//             range: [8, 15],
//             text: "89-1234",
//             children: [],
//           },
//         ],
//       },
//     ],
//   },
//   { range: [15, 20], text: "56789", children: [] },
// ];
// console.log("🚀 ~ processedHighlights:", JSON.stringify(processedHighlights4));
// console.log(
//   "🚀 ~ processedHighlights: === ",
//   JSON.stringify(processedHighlights4) === JSON.stringify(result4)
// );
// console.log("===========");
// console.log("===========");
// console.log("===========");

// const processedHighlights5 = processHighlights(
//   content,
//   [
//     { highlightIndex: 1, range: [5, 10], color: "red" },
//     { highlightIndex: 2, range: [15, 17], color: "aqua" },
//   ],
//   [0, content.length]
// );

// const result5 = [
//   { range: [0, 5], text: "01234", children: [] },
//   {
//     range: [5, 10],
//     color: "red",
//     highlightIndex: 1,
//     text: "56789",
//     children: [],
//   },
//   { range: [10, 15], text: "-1234", children: [] },
//   {
//     range: [15, 17],
//     color: "aqua",
//     highlightIndex: 2,
//     text: "56",
//     children: [],
//   },
//   { range: [17, 20], text: "789", children: [] },
// ];
// console.log("🚀 ~ processedHighlights:", JSON.stringify(processedHighlights5));
// console.log(
//   "🚀 ~ processedHighlights: === ",
//   JSON.stringify(processedHighlights5) === JSON.stringify(result5)
// );
// console.log("===========");
// console.log("===========");
// console.log("===========");
