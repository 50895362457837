<template>
  <div :class="{ isExporting }">
    <!-- Only for pdf -->
    <div v-if="isExporting">
      <slot name="pdfTitle" />
    </div>

    <div class="d-flex flex-wrap align-items-center">
      <slot name="selects" />
    </div>

    <empty-state
      v-if="!loading && !insight"
      class="mt-5"
      :title="emptyTitle"
      :description="emptyDescription"
    >
      <img
        src="../../assets/icons/chart.svg"
        width="48"
        alt="EssayGrader Empty State"
      />
    </empty-state>

    <b-row v-else :class="{ 'flex-column': isExporting }">
      <b-col class="pb-4">
        <slot name="block-summary" :loading="loading" :insight="insight">
          <insight-summary :summary="insight?.summary" :loading="loading" />
        </slot>

        <!-- Only for pdf -->
        <!--    TODO: Remove after the release v3.3 or uncomment   -->
        <!--<b-card body-class="p-4" class="mt-4" v-if="isExporting">
          <slot
            name="block-scores"
            :loading="loading"
            :insight="insight"
            :is-exporting="isExporting"
          >
            <average-class-scores
              :assignment-class-scores="insight?.assignmentClassScores"
              :is-exporting="isExporting"
              :loading="loading"
            />
          </slot>
        </b-card>-->

        <b-card body-class="p-4" class="mb-4 canvas-p-0">
          <slot
            name="block-rubric"
            :loading="loading"
            :insight="insight"
            :is-exporting="isExporting"
          >
            <rubric-breakdown
              :loading="loading"
              :classId="classId"
              :assignmentId="assignmentId"
              :is-exporting="isExporting"
            />
          </slot>
        </b-card>

        <b-card body-class="p-4" class="mb-4 canvas-p-0">
          <slot name="block-errors" :loading="loading" :insight="insight">
            <errors-breakdown
              :loading="loading"
              :classId="classId"
              :assignmentId="assignmentId"
              :studentId="studentId"
              :is-exporting="isExporting"
            />
          </slot>
        </b-card>
      </b-col>
      <b-col>
        <!--  TODO: Remove after the release v3.3 or uncomment  -->
        <!--<b-card body-class="p-4" v-if="!isExporting">
          <slot
            name="block-scores"
            :loading="loading"
            :insight="insight"
            :is-exporting="isExporting"
          >
            <average-class-scores
              :assignment-class-scores="insight?.assignmentClassScores"
              :is-exporting="isExporting"
              :loading="loading"
            />
          </slot>
        </b-card>-->

        <b-card body-class="p-4" class="mb-4 canvas-p-0">
          <slot
            name="block-recommendation"
            :loading="loading"
            :insight="insight"
          >
            <recommendation-improve
              :loading="loading"
              :classId="classId"
              :assignmentId="assignmentId"
              :studentId="studentId"
            />
          </slot>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { getInsights } from "@/api";
// import AverageClassScores from "./average_class_scores.vue";
import ErrorsBreakdown from "./errors_breakdown.vue";
import InsightSummary from "./insight_summary.vue";
import RubricBreakdown from "./rubric_breakdown.vue";
import RecommendationImprove from "./recommendation_improve.vue";
import EmptyState from "@/components/global/empty_state.vue";

export default {
  name: "InsightResults",

  components: {
    // AverageClassScores,
    ErrorsBreakdown,
    InsightSummary,
    RecommendationImprove,
    RubricBreakdown,
    EmptyState,
  },

  inject: ["setIsExportable"],

  props: {
    canInit: {
      type: Boolean,
      required: true,
    },

    classId: {
      type: Number,
    },

    assignmentId: {
      type: Number,
    },

    studentId: {
      type: Number,
    },

    isExporting: {
      type: Boolean,
      default: false,
    },

    emptyTitle: {
      type: String,
      default: "No classes have been created yet.",
    },

    emptyDescription: {
      type: String,
      default:
        "At least one class needs to exist to show data. Try analyzing metrics at the assignment or student level.",
    },
  },

  data() {
    return {
      insight: null,
      loading: true,
    };
  },

  watch: {
    classId() {
      this.init();
    },

    assignmentId() {
      this.init();
    },

    studentId() {
      this.init();
    },
  },

  created() {
    this.init();
  },

  methods: {
    async init() {
      if (!this.canInit) {
        this.loading = false;

        return;
      }

      if (!this.classId && !this.assignmentId && !this.studentId) {
        this.loading = false;

        return;
      }

      this.loading = true;
      const { data } = await getInsights(
        this.classId,
        this.assignmentId,
        this.studentId
      );

      this.setIsExportable(true);
      this.insight = data;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.essay-stats-card::v-deep .card-body {
  padding: 1.5rem;
}
::v-deep .essay-stats-card {
  .card-body {
    justify-items: center;
  }
}

@mixin isExporting() {
  .canvas-p-0 {
    border: none;
    background: none;

    .card-body {
      padding: 0 !important;
    }
  }
}

.isExporting {
  @include isExporting();
}

@media (max-width: 768px) {
  @include isExporting();
}
</style>
