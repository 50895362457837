var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{ref:`chunk-${_vm.chunk.highlightIndex}`,class:{
    'position-relative': true,
    [_vm.chunk.type]: true,
    'range-text': _vm.chunk.highlightIndex !== undefined,
    hovered: _vm.hovered,
    selection: _vm.isSelected,
  },style:({
    '--border-color': _vm.chunk.color,
    '--background-color': _vm.chunk.backgroundColor,
  }),on:{"click":function($event){return _vm.handleClick($event, _vm.chunk)},"mouseover":function($event){return _vm.onMouseover(_vm.chunk)},"mouseleave":function($event){return _vm.onMouseleave(_vm.chunk)}}},[(_vm.chunk.highlightIndex !== undefined && _vm.chunk.type !== 'selection')?_c('eg-svg-text',{staticClass:"number",attrs:{"text":_vm.svgHighlightIndexText,"size":10,"data-number":_vm.chunk.highlightIndex}}):_vm._e(),_c('span',{attrs:{"data-range":_vm.chunk.range}},[_vm._v(_vm._s(_vm.chunk.text)),(_vm.chunk.children && _vm.chunk.children.length)?_vm._l((_vm.chunk.children),function(child,index){return _c('HighlightRange',{key:index,attrs:{"chunk":child,"activeHighlightIndex":_vm.activeHighlightIndex},on:{"highlightClicked":function($event){return _vm.$emit('highlightClicked', $event)}}})}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }