<template>
  <div class="next-steps p-3 mt-2">
    <strong class="next-step-color d-flex align-items-center">
      <b-icon icon="lightbulb-fill" font-scale="0.63" /> {{ title }}
    </strong>
    <template v-if="typeof text === 'string'">
      <div v-if="isList" class="mt-1">
        <ul>
          <li v-for="(item, index) in listItems" :key="index">{{ item }}</li>
        </ul>
      </div>
      <div v-else class="mt-1">{{ text }}</div>
    </template>
    <component v-else :is="text"></component>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: [String, Function],
      required: true,
    },
    isList: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    listItems() {
      return this.text.split("\n");
    },
  },
};
</script>

<style lang="scss" scoped>
.next-steps {
  border-radius: 4px;
  border: 1px solid #a0dce6;
  background: rgba(23, 162, 184, 0.04);
}

.next-step-color {
  color: #17a2b8;
}
</style>
