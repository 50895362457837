<template>
  <div class="position-relative">
    <b-overlay :show="studentLoading" no-wrap>
      <template #overlay>
        <b-spinner small variant="info" />
      </template>
    </b-overlay>
    <eg-search-select
      :items="allStudents"
      :value="selectedStudent"
      titleKey="name"
      entity="student"
      :allow-deselect="allowDeselect"
      @input="selectStudent"
    />
  </div>
</template>

<script>
import { getStudents } from "@/api";
import EgSearchSelect from "@/components/global/eg_search_select.vue";

export default {
  components: {
    EgSearchSelect,
  },

  props: {
    classId: {
      type: Number,
    },

    value: {
      type: [Object, Number],
    },

    valueIsId: {
      type: Boolean,
      default: false,
    },

    students: {
      type: Array,
    },

    size: {
      type: String,
      default: "md",
    },

    allowDeselect: {
      type: Boolean,
      default: false,
    },

    autoSelectFirst: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      studentLoading: false,
      allStudents: [],
    };
  },

  computed: {
    selectedStudent() {
      return this.valueIsId
        ? this.allStudents.find((c) => c.id === this.value)
        : this.value;
    },
  },

  watch: {
    classId: "init",
  },

  methods: {
    selectStudent(studentData) {
      this.$emit("input", this.valueIsId ? studentData.id : studentData);
    },

    async fetchStudents() {
      this.studentLoading = true;
      try {
        const { data } = await getStudents({
          classId: this.classId,
          limit: 1000,
        });

        this.allStudents = data.rows.map(({ id, firstName, lastName }) => ({
          id,
          firstName,
          lastName,
          name: `${firstName} ${lastName || ""}`,
        }));

        if (this.autoSelectFirst && this.allStudents.length > 0) {
          this.selectStudent(this.allStudents[0]);
        }

        this.studentLoading = false;
      } catch (error) {
        this.studentLoading = false;
        this.$showToastError(error.response?.data?.error);
      }
    },

    init() {
      if (this.students) {
        return (this.allStudents = this.students);
      }

      this.fetchStudents();
    },
  },

  async created() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.dropdown-item-wrapper {
  &::v-deep {
    .dropdown-item {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
