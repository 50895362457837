<script>
import capitalize from "@/utils/capitalize";

export default {
  name: "ConfirmMonthlyUpgradeModal",
  props: {
    newPlan: {
      type: String,
      required: true,
    },
    proceedWithUpgrade: {
      type: Function,
      required: true,
    },
    newPlanPrice: {
      type: String,
      required: true,
    },
  },
  computed: {
    newPlanDisplayName() {
      return capitalize(this.newPlan);
    },
    modalTitle() {
      return `Upgrade to the monthly ${this.newPlanDisplayName} plan`;
    },
  },
  methods: {
    upgrade() {
      this.proceedWithUpgrade(this.newPlan, false);
      this.$bvModal.hide("confirm_monthly_upgrade_modal");
    },
  },
};
</script>

<template>
  <b-modal
    id="confirm_monthly_upgrade_modal"
    :title="modalTitle"
    size="lg"
    centered
    hide-footer
    no-stacking
  >
    <p>
      Would you like to upgrade to the monthly {{ newPlanDisplayName }} at the
      rate of {{ newPlanPrice }} instead?
    </p>

    <div class="d-flex justify-content-end">
      <b-button
        variant="outline-info"
        @click="$bvModal.hide('confirm_monthly_upgrade_modal')"
      >
        Cancel
      </b-button>
      <b-button class="ml-3" variant="info" @click="upgrade">
        Upgrade
      </b-button>
    </div>
  </b-modal>
</template>
