import {
  getEssayComments,
  deleteComment,
  updateComment,
  addComment,
} from "@/api";
import deleteConfirmationModalMixin from "@/mixins/deleteConfirmationModal";

export default {
  data() {
    return {
      commentLoading: false,
      comments: [],
    };
  },
  // this.showDeleteConfirmationModal
  mixins: [deleteConfirmationModalMixin],

  methods: {
    async getEssayComments(essayId = this.essay.id) {
      const { data } = await getEssayComments(essayId);
      this.comments = data.comments;
    },

    // TODO not use init, update this.groupedComments in client side
    async addComment({ ranges, text, resolve = () => {} }) {
      this.commentLoading = true;
      await addComment(this.essay.id, { ranges, text });
      this.getEssayComments();
      this.commentLoading = false;
      resolve();
    },

    async updateComment({ commentId, text }) {
      this.commentLoading = true;
      await updateComment(this.essay.id, commentId, text);
      this.getEssayComments();
      this.commentLoading = false;
    },

    async deleteComment(comment) {
      const canDelete = await this.showDeleteConfirmationModal({
        title: "Are you sure you want to delete this comment?",
        message:
          "This action cannot be undone, but you can create a new comment.",
      });

      if (!canDelete) return;

      this.commentLoading = true;
      await deleteComment(this.essay.id, comment.id);
      this.getEssayComments();
      this.commentLoading = false;
    },
  },
};
