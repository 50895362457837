var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.loading || _vm.innerLoading,"spinner-small":"","rounding":"sm","no-wrap":""},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('b-spinner',{attrs:{"small":"","variant":"info"}}),_c('div',[_vm._v("Generating…")])],1)]},proxy:true}])}),_vm._m(0),(
      !(_vm.loading || _vm.innerLoading) &&
      !(_vm.recommendations.focusOn && _vm.recommendations.spendLessTimeOn)
    )?_c('empty-state',{staticClass:"mt-5",attrs:{"title":"No recommendations found"}},[_c('img',{attrs:{"src":require("../../assets/icons/chart.svg"),"width":"48","alt":"EssayGrader Empty State"}})]):_vm._e(),(_vm.recommendations?.focusOn)?_c('div',{staticClass:"mt-3"},[_vm._m(1),_c('recommendation-improve-block',{attrs:{"items":_vm.recommendations?.focusOn}})],1):_vm._e(),(_vm.recommendations?.spendLessTimeOn)?_c('div',{staticClass:"mt-3"},[_vm._m(2),_c('recommendation-improve-block',{attrs:{"items":_vm.recommendations?.spendLessTimeOn}})],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('h5',{staticClass:"mr-auto"},[_vm._v("Recommendations to improve lesson plan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"mr-2",attrs:{"src":require("../../assets/icons/bullseye-arrow.svg"),"alt":""}}),_c('h5',{staticClass:"subtitle"},[_vm._v("Focus on...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"mr-2",attrs:{"src":require("../../assets/icons/party.svg"),"alt":""}}),_c('h5',{staticClass:"subtitle green"},[_vm._v("Spend less time on...")])])
}]

export { render, staticRenderFns }