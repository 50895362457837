<template>
  <div class="feedback-box content">
    <base-item
      v-for="(item, index) in items"
      ref="items"
      :activeHighlightIndex="activeHighlightIndex"
      :index="index"
      :key="item.id"
      :item="item"
      :formatTitle="formatTitle"
      @updateActiveHighlightIndex="$emit('updateActiveHighlightIndex', index)"
      @update="updateEssayTabData"
      @delete="deleteEssayTabData"
    />

    <base-item
      :item="overAllCriteria"
      :formatTitle="formatTitle"
      :index="-2"
      @update="updateEssay"
    />
  </div>
</template>

<script>
import BaseItem from "@/components/essay/essay/v3/base_item.vue";
import generateBaseReportV3Mixin from "@/components/essay/essay/v3/mixins/base_report_v3";

export default {
  components: {
    BaseItem,
  },

  // this.specialForCopyPaste
  // this.activeHighlightIndex
  // this.items
  // this.essay
  // this.isNoRanges
  // this.updateEssayTabData
  // this.deleteEssayTabData
  // this.updateEssay
  mixins: [generateBaseReportV3Mixin("feedback")],

  computed: {
    gradeInfo() {
      return this.items.reduce(
        (acum, feedback) => {
          return {
            grade: acum.grade + Number(feedback.grade),
            maxGrade: acum.maxGrade + feedback.criteria.maxGrade,
          };
        },
        {
          grade: 0,
          maxGrade: 0,
        }
      );
    },

    overAllCriteria() {
      return {
        id: 2,
        criteria: {
          name: "Overall Grade",
          maxGrade: parseFloat(this.gradeInfo.maxGrade.toFixed(2)),
        },
        grade: parseFloat(this.gradeInfo.grade.toFixed(2)),
        percentage: parseFloat(
          ((this.gradeInfo.grade / this.gradeInfo.maxGrade) * 100 || 0).toFixed(
            2
          )
        ),
        analysis: this.essay?.summary?.feedback?.analysis,
        suggestions: this.essay?.summary?.feedback?.suggestions,
      };
    },
  },

  watch: {
    items() {
      if (this.isNoRanges) return;

      this.$nextTick(() => {
        const highlights = this.items.reduce((acc, feedback, index) => {
          if (!feedback.ranges) {
            return acc;
          }

          for (
            let rangeIndex = 0;
            rangeIndex < feedback.ranges.length;
            rangeIndex++
          ) {
            acc.push({
              range: feedback.ranges[rangeIndex],
              highlightIndex: index + 1,
              refComponent: this.$refs.items?.[index],
              color: "#F1B500",
              backgroundColor: "#FFFBD9",
            });
          }

          return acc;
        }, []);

        this.$emit("updateHighlights", highlights);
      });
    },
  },

  methods: {
    formatTitle(item) {
      return `${item.criteria.name}: ${parseFloat(item.grade)}/${
        item.criteria.maxGrade
      } ${item.percentage !== undefined ? `(${item.percentage}%)` : ""}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback-box {
  white-space: pre-wrap;
  padding: 16px;
  border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  height: 100%;
  max-height: calc(100vh - 298px);
  overflow: auto;
}

.feedback-box.content,
.feedback-box.ql-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
  padding-top: 0px;
}

.feedback-box.content {
  padding-top: 15px;
}
</style>
