import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import api from "@/api/api";
import { BASIC_PLAN, LITE_PLAN, PRO_PLAN, PREMIUM_PLAN } from "../constants";
import configModule from "./modules/config";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  plugins: [
    createPersistedState({
      paths: ["user", "token", "loggedIn", "featureFlags"],
    }),
  ],
  modules: {
    config: configModule,
  },
  state: {
    user: null,
    token: null,
    loggedIn: false,
    featureFlags: {},

    aiDetectorEssayContent: "",
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
      state.loggedIn = true;
    },
    logout(state) {
      state.token = null;
      state.user = null;
      state.loggedIn = false;
    },
    setFeatureFlags(state, flags = {}) {
      state.featureFlags = flags;
    },
    setAiDetectorEssayContent(state, essayContent = "") {
      state.aiDetectorEssayContent = essayContent;
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit("setUser", user);
    },
    setToken({ commit }, token) {
      commit("setToken", token);
      api.defaults.headers["Authorization"] = `Bearer ${token}`;
    },
    logout({ commit }) {
      commit("logout");
    },
    setFeatureFlags({ commit }, flags) {
      commit("setFeatureFlags", flags);
    },
    setAiDetectorEssayContent({ commit }, essayContent) {
      commit("setAiDetectorEssayContent", essayContent);
    },
  },
  getters: {
    isPaused(state) {
      return state.user?.isPaused;
    },
    pausedStorageKey(state) {
      return `${state?.user?.id}-hidePausedAlert`;
    },
    userIsNotPausedAndPlanMoreThenPro(state, getters) {
      return !getters.isPaused && (getters.proPlan || getters.premiumPlan);
    },
    basicPlan(state) {
      return state.user?.plan === BASIC_PLAN;
    },
    litePlan(state) {
      return state.user?.plan === LITE_PLAN;
    },
    proPlan(state) {
      return state.user?.plan === PRO_PLAN;
    },
    premiumPlan(state) {
      return state.user?.plan === PREMIUM_PLAN;
    },
    manualCustomer(state) {
      return (
        state.user?.plan !== BASIC_PLAN && state.user?.stripeCustomerId === null
      );
    },
    isTrial(state) {
      return state.user?.trialEndsIn > 0;
    },
    trialEndsInDays(state) {
      return Math.floor(state.user.trialEndsIn / (24 * 60 * 60));
    },
    trialProgress(state) {
      return Number(
        (state.user.trialEndsIn / state.user.trialSpan) * 100
      ).toFixed(2);
    },
  },
});
