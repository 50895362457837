export default (flag) => ({
  name: "FlagPlagiarismAnalysis",

  data: function () {
    return {
      flag,
    };
  },

  render: function (h) {
    return h("div", [
      "Our detector is ",
      h("b", "highly confident"),
      " that this essay was plagiarized.",
    ]);
  },
});
