<template>
  <div>
    <b-dropdown
      id="export-dropdown"
      :disabled="disabled"
      class="export-dropdown mr-2"
      size="sm"
      right
      variant="outline-info"
      text="Export"
    >
      <b-dropdown-item @click="exportTo('google')">
        Google Classroom
      </b-dropdown-item>
      <b-dropdown-item @click="exportTo('canvas')"> Canvas </b-dropdown-item>
      <b-dropdown-item @click="exportTo('pdf')"> PDF </b-dropdown-item>
      <b-dropdown-item @click="exportTo('pdf', 'merged-pdf')">
        Export as one single PDF file
      </b-dropdown-item>
      <b-dropdown-item @click="exportTo('csv')"> CSV </b-dropdown-item>
    </b-dropdown>
    <b-tooltip :disabled="!disabled" target="export-dropdown">
      Select 1 or more essays to use this action
    </b-tooltip>

    <span id="delete-button-wrapper">
      <b-button
        size="sm"
        variant="danger"
        :disabled="disabled"
        @click="onClickDelete"
      >
        Delete
      </b-button>
    </span>
    <b-tooltip :disabled="!disabled" target="delete-button-wrapper">
      Select 1 or more essays to use this action
    </b-tooltip>
  </div>
</template>

<script>
import deleteConfirmationModalMixin from "@/mixins/deleteConfirmationModal";

export default {
  name: "EssayActions",

  // this.showDeleteConfirmationModal
  mixins: [deleteConfirmationModalMixin],

  props: {
    selectedAll: {
      type: Boolean,
      default: false,
    },

    selectedIds: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
  },

  methods: {
    exportTo(type, modalType) {
      this.$emit("export", {
        type,
        selectedIds: this.selectedIds,
        mergedPdf: modalType === "merged-pdf",
      });
    },

    onClickDelete() {
      this.showDeleteConfirmationModal({
        title: `Are you sure you want to delete ${
          this.selectedAll ? "all" : this.selectedIds.length
        } essay${this.selectedAll || this.selectedIds.length > 1 ? "s" : ""}?`,
        message: `Essays cannot be accessed after deletion. Queued essays won't be deleted. This action cannot be undone.`,
      })
        .then((value) => {
          if (value) {
            this.$emit("delete", this.selectedIds);
          }
        })
        .catch((err) => {
          console.log(err);
          // An error occurred
        });
    },
  },
};
</script>
