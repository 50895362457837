export default {
  methods: {
    showDeleteConfirmationModal({
      title = "Are you sure you want to delete this item?",
      message = "This action cannot be undone.",
    }) {
      return this.$bvModal.msgBoxConfirm(message, {
        title,
        size: "md",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "Yes, delete",
        cancelVariant: "outline-secondary",
        cancelTitle: "No, keep them",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true,
      });
    },
  },
};
