<template>
  <b-form-checkbox-group
    class="export-options"
    :checked="value"
    :options="options"
    name="export-options"
    :stacked="stacked"
    @change="handleExportChange"
  />
</template>

<script>
const allOptions = {
  content: {
    html: `
    <div>
      <div>Annotations</div>
      <div class='small-text text-left text-secondary'>This will include the submission in the export with annotations added</div>
    </div>
    `,
    value: "content",
  },
  comments: {
    html: `
    <div>
      <div>Comments</div>
      <div class='small-text text-left text-secondary'>Exports any comments you have added to the submission</div>
    </div>
    `,
    value: "comments",
  },
  feedback: {
    html: `
    <div>
      <div>Feedback report</div>
      <div class='small-text text-left text-secondary'>Attached as a PDF to the comments on the assignment</div>
    </div>
    `,
    value: "feedback",
  },
  errors: {
    html: `
    <div>
      <div>Writing report</div>
      <div class='small-text text-left text-secondary'>Attached as a PDF to the comments on the assignment</div>
    </div>
    `,
    value: "errors",
  },
  hideGrades: { text: "Hide grades", value: "hideGrades" },
  status: { text: "Status", value: "status" },
  class: { text: "Class", value: "class" },
  name: { text: "Essay Name", value: "name" },
  studentName: { text: "Student Name", value: "studentName" },
  assignment: { text: "Assignment", value: "assignment" },
  rubric: { text: "Rubric", value: "rubric" },
  grade: { text: "Grade", value: "grade" },
};

export default {
  props: {
    availableOptions: {
      type: Array,
      default: () => ["content", "feedback", "errors", "hideGrades"],
    },

    value: {
      type: Array,
      default: () => [],
    },

    stacked: {
      type: Boolean,
      default: false,
    },

    additionalOptions: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    options() {
      return this.availableOptions.map((key) => {
        return {
          disabled: this.value.length === 1 && this.value.includes(key),
          ...{ ...allOptions, ...this.additionalOptions }[key],
        };
      });
    },
  },

  methods: {
    handleExportChange(value) {
      this.$emit("input", value);
    },
  },
};
</script>
