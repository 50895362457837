import { render, staticRenderFns } from "./popover_comment.vue?vue&type=template&id=3717e8b6&scoped=true&"
import script from "./popover_comment.vue?vue&type=script&lang=js&"
export * from "./popover_comment.vue?vue&type=script&lang=js&"
import style0 from "./popover_comment.vue?vue&type=style&index=0&id=3717e8b6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3717e8b6",
  null
  
)

export default component.exports