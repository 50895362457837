<template>
  <eg-form-group
    label="Language"
    description="Vocabulary will be assessed based on the language selected when the essay is graded."
    required
  >
    <div class="py-2 flex-gap d-flex flex-wrap">
      <ToggleButton
        v-for="language in languageOptions"
        :key="language.value"
        :label="language.text"
        :value="language.value"
        @click="handleChange(language.value)"
        :pressed="value === language.value"
        variant="info"
        horizontal
      >
      </ToggleButton>
    </div>
  </eg-form-group>
</template>

<script>
import { getLanguages } from "@/api";
import EgFormGroup from "../../global/eg_form_group.vue";
import ToggleButton from "@/components/global/eg_toggle_button.vue";

const selectedLanguageIdFromStorage =
  localStorage.getItem("selectedLanguageId");

export default {
  components: {
    EgFormGroup,
    ToggleButton,
  },

  props: {
    value: {
      type: Number,
    },
  },

  data() {
    return {
      languageOptions: [],
    };
  },

  created() {
    this.getLanguages();
  },

  methods: {
    handleChange(languageId) {
      this.$emit("input", languageId);
      localStorage.setItem("selectedLanguageId", languageId);
    },

    async getLanguages() {
      try {
        const { data } = await getLanguages();
        this.languageOptions = data.rows.map((language) => ({
          value: language.id,
          text: language.label,
        }));

        if (!selectedLanguageIdFromStorage) {
          this.$emit(
            "input",
            this.languageOptions[0] ? this.languageOptions[0].value : null
          );

          return;
        }

        this.$emit("input", +selectedLanguageIdFromStorage);
      } catch (error) {
        this.$showToastError(error.message);
      }
    },
  },
};
</script>
