import api from "@/api/api";

const generateParams = (classId, assignmentId, studentId) => {
  let params = {};

  if (studentId) {
    params.studentId = studentId;
  } else if (assignmentId) {
    params.assignmentId = assignmentId;
  } else if (classId) {
    params.classId = classId;
  }

  return params;
};
// TODO: may add as global axios interceptors
const addMinRequestTime = async (request, minRequestTime = 300) => {
  const [response] = await Promise.all([
    request,
    new Promise((resolve) => setTimeout(resolve, minRequestTime)),
  ]);

  return response;
};

export const getInsights = (classId, assignmentId, studentId) => {
  return addMinRequestTime(
    api.get("/insights", {
      params: generateParams(classId, assignmentId, studentId),
    })
  );
};

export const getRecommendations = (classId, assignmentId, studentId) => {
  return addMinRequestTime(
    api.get("/insights/recommendations", {
      params: generateParams(classId, assignmentId, studentId),
    })
  );
};

export const getErrorsBreakdown = (classId, assignmentId, studentId) => {
  return addMinRequestTime(
    api.get("/insights/errors/breakdown", {
      params: generateParams(classId, assignmentId, studentId),
    })
  );
};

export const getInsightsUsedRubrics = (classId, assignmentId, studentId) => {
  return addMinRequestTime(
    api.get("/insights/rubrics", {
      params: generateParams(classId, assignmentId, studentId),
    })
  );
};

export const getInsightsRubricBreakdown = ({
  classId,
  assignmentId,
  studentId,
  rubricId,
  dateRange,
}) => {
  const params = generateParams(classId, assignmentId, studentId);

  if (rubricId) {
    params.rubricId = rubricId;
  }

  if (dateRange) {
    if (dateRange.from) {
      params.dateFrom = dateRange.from;
    }

    if (dateRange.from) {
      params.dateTo = dateRange.to;
    }
  }

  return addMinRequestTime(api.get("/insights/rubrics/breakdown", { params }));
};
