<script>
export default {
  name: "PricingCard",
  props: {
    activePlan: {
      type: Boolean,
      required: false,
      default: false,
    },
    nextBillingDate: {
      type: Date,
      required: false,
      default: null,
    },
    showUpgrade: {
      type: Boolean,
      required: false,
      default: false,
    },
    showManualUpgrade: {
      type: Boolean,
      required: false,
      default: false,
    },
    showYearly: {
      type: Boolean,
      required: false,
      default: false,
    },
    canceled: {
      type: String,
      required: false,
      default: "",
    },
    planName: {
      type: String,
      required: true,
    },
    planDescription: {
      type: String,
      required: true,
    },
    planPrice: {
      type: String,
      required: true,
    },
    planItems: {
      type: Array,
      required: true,
    },
  },
  computed: {
    canceledText() {
      if (this.canceled) {
        return `Scheduled to cancel on ${this.canceled}`;
      }
      return "";
    },
    nextBillingDateText() {
      if (this.nextBillingDate) {
        return this.nextBillingDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
      }

      return null;
    },
  },
};
</script>

<template>
  <b-card
    no-body
    style="max-width: 20rem"
    class="mb-4"
    data-testid="pricing-card"
  >
    <template #header>
      <h4 class="mb-0" data-testid="plan-name">
        <b-icon
          v-if="activePlan"
          class="mr-2"
          icon="check-circle-fill"
        ></b-icon>
        {{ planName }}
      </h4>
    </template>

    <b-card-body>
      <p v-if="canceled" class="background-black">{{ canceledText }}</p>
      <b-card-title
        v-if="activePlan"
        class="text-purple"
        data-testid="active-plan-price"
      >
        ${{ planPrice }}<span v-if="showYearly">/year</span>
        <span v-else>/mo</span>
      </b-card-title>
      <b-card-title
        v-else
        class="text-purple"
        data-testid="inactive-plan-price"
      >
        {{ planPrice }}
        <span v-if="showYearly" class="yearly-notice">(billed yearly)</span>
      </b-card-title>
      <b-card-text>
        <p v-if="nextBillingDateText">
          Next Billing Date: {{ this.nextBillingDateText }}
        </p>
        {{ planDescription }}
      </b-card-text>
      <b-button v-if="showUpgrade" variant="info" @click="$emit('upgrade')">
        Upgrade to {{ planName }}
      </b-button>
      <a v-if="showManualUpgrade" href="mailto:hello@essaygrader.ai">
        Email hello@essaygrader.ai to upgrade.
      </a>
    </b-card-body>

    <b-list-group flush>
      <b-list-group-item
        v-for="(item, index) in planItems"
        :key="index"
        data-testid="plan-item"
      >
        {{ item }}
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>

<style scoped lang="scss">
.yearly-notice {
  font-size: 50%;
  color: #979ea5 !important;
}
</style>
