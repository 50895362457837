<template>
  <div class="insight-view p-3">
    <b-overlay :show="showExportingOverlay" :opacity="1" no-center no-fade>
      <template #overlay>
        <div class="text-center position-fixed overlay-content">
          <b-icon
            key="icon"
            icon="stopwatch"
            variant="info"
            font-scale="3"
          ></b-icon>
          <h5 key="text" class="mt-2">Creating PDF...</h5>
        </div>
      </template>

      <div class="d-flex flex-wrap align-items-center">
        <h1>Performance metrics</h1>
        <div class="ml-auto">
          <b-button
            variant="info-light"
            :disabled="!isExportable"
            @click="exportToPdfAndSave"
          >
            Export as PDF
          </b-button>
          <b-button
            variant="info"
            class="ml-2"
            :disabled="!isExportable"
            @click="print"
          >
            Print
          </b-button>
        </div>
      </div>

      <b-tabs v-model="activeTab" ref="tabs" no-fade lazy class="mb-3">
        <b-tab title="Class">
          <base-insight-results
            class="mt-3"
            :class-id="selectedClass?.id"
            :can-init="selectedClass?.id !== undefined"
            :is-exporting="isExporting"
          >
            <template #pdfTitle>
              <h1>Metrics for {{ selectedClass?.name }}</h1>
            </template>

            <template #selects>
              <class-select
                data-html2canvas-ignore
                class="mb-3 mr-3"
                v-model="selectedClass"
                :show-create-entity="false"
                :allow-deselect="false"
                auto-select-first
                with-search
              />
            </template>
          </base-insight-results>
        </b-tab>

        <b-tab title="Assignment">
          <assignment-insight-results
            class="mt-3"
            :assignment-id="selectedAssignment?.id"
            :is-exporting="isExporting"
          >
            <template #pdfTitle>
              <h1>Metrics for {{ selectedAssignment?.title }}</h1>
            </template>
            <template #selects>
              <assignment-select
                data-html2canvas-ignore
                v-model="selectedAssignment"
                :show-create-entity="false"
                :allow-deselect="false"
                with-search
                auto-select-first
                no-label
              />
            </template>
          </assignment-insight-results>
        </b-tab>
        <!--    TODO: Remove after the release v3.3 or uncomment    -->
        <!--<b-tab title="Student">
          <student-insight-results
            class="mt-3"
            :class-id="selectedClass?.id"
            :student-id="selectedStudent?.id"
            :student-name="selectedStudent?.firstName"
            :is-exporting="isExporting"
          >
            <template #pdfTitle>
              <h1>
                Metrics for {{ selectedClass?.name }},
                {{ selectedStudent?.lastName }} {{ selectedStudent?.firstName }}
              </h1>
            </template>
            <template #selects>
              <class-select
                data-html2canvas-ignore
                class="custom-select-max-width mb-3 mr-3"
                v-model="selectedClass"
                :show-create-entity="false"
                :allow-deselect="false"
                auto-select-first
                with-search
              />

              <student-select
                data-html2canvas-ignore
                class="custom-select-max-width mb-3 mr-3"
                v-model="selectedStudent"
                :class-id="selectedClass?.id"
                :allow-deselect="false"
                auto-select-first
              />
            </template>
          </student-insight-results>
        </b-tab>-->
      </b-tabs>
    </b-overlay>
  </div>
</template>

<script>
// import StudentSelect from "@/components/students/student_select";
import ClassSelect from "@/components/classes/class_select";
import AssignmentSelect from "@/components/essay/assignment_select.vue";
import BaseInsightResults from "@/components/insight/base_insight_results.vue";
import AssignmentInsightResults from "@/components/insight/assignment_insight_results.vue";
// import StudentInsightResults from "@/components/insight/student_insight_results.vue";
import formatDate from "@/utils/formatDate";
export default {
  components: {
    AssignmentSelect,
    ClassSelect,
    AssignmentInsightResults,
    BaseInsightResults,
    // StudentInsightResults,
    // StudentSelect,
  },

  provide() {
    return {
      // Each children component can set the export status using this method
      // if any send a "true" value, it means export is possible
      setIsExportable: this.setIsExportable,
    };
  },

  data() {
    return {
      activeTab: 0,
      selectedClass: null,
      selectedAssignment: null,
      selectedStudent: null,
      isExporting: false,
      showExportingOverlay: false,
      isExportable: false,
    };
  },

  watch: {
    activeTab() {
      // Reset export disabled state when switching tabs
      this.isExportable = false;
    },
  },

  methods: {
    setIsExportable(value) {
      this.isExportable = this.isExportable || value;
    },

    generateFileName() {
      let selectedEntityPart = "_";

      switch (this.activeTab) {
        case 0:
          if (this.selectedClass) {
            selectedEntityPart += `${this.selectedClass.name}_`;
          }
          break;
        case 1:
          if (this.selectedAssignment) {
            selectedEntityPart += `${this.selectedAssignment.title}_`;
          }
          break;
        case 2:
          if (this.selectedClass) {
            selectedEntityPart += `${this.selectedClass.name}_`;
          }

          if (this.selectedStudent) {
            selectedEntityPart += `${this.selectedStudent.firstName}_${this.selectedStudent.lastName}_`;
          }
          break;

        default:
          break;
      }

      return `EssayGrader_Metrics${selectedEntityPart}${formatDate(
        new Date(),
        "MMM-D-YYYY"
      )}`;
    },

    async exportToPdfAndSave() {
      const doc = await this.exportToPdf();
      doc.save(`${this.generateFileName()}.pdf`);
    },

    async exportToPdf() {
      const { jsPDF } = await import("jspdf");
      const { default: html2canvas } = await import("html2canvas");
      const doc = new jsPDF({
        orientation: "portrait", // 'portrait'/'landscape'
        unit: "px", // ('mm', 'cm', 'in', 'px')
        format: "a4", // ('a4', 'letter', ...)
      });
      const pageHeight = doc.internal.pageSize.height - 30; // Page height including top and bottom margins (15px top and bottom)
      const pageWidth = doc.internal.pageSize.width - 30; // Page width including margins (15px left and right)
      const element = this.$refs.tabs
        .getTabs()
        ?.find((tab) => tab.localActive)?.$el;

      if (!element) {
        this.$showToastError("No active tab found, please try again");
      }

      // NOTE First show loading during export
      this.showExportingOverlay = true;
      // Wait for the loading overlay to be shown
      await this.$nextTick();

      // NOTE then change layout, needed for rerender chart with axis and labels
      this.isExporting = true;
      // TODO: emit chart mounted event and handle here, then continue, right now it's just enough time waiting
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000);
      });

      const canvas = await html2canvas(element, {
        x: 0,
        windowWidth: 666,
      });

      const imgWidth = pageWidth;

      let y = 0; // Current vertical position image on page (we use the same big image% then move it on next page if needed)

      while (y < canvas.height) {
        // Create a temporary canvas for the current page
        const pageCanvas = document.createElement("canvas");
        const pageCtx = pageCanvas.getContext("2d");

        // Setting the size of the temporary canvas
        pageCanvas.width = canvas.width;
        pageCanvas.height = pageHeight * (canvas.width / pageWidth);

        // Copying part of the original canvas to a temporary canvas
        pageCtx.drawImage(
          canvas,
          0,
          y,
          canvas.width,
          pageCanvas.height,
          0,
          0,
          pageCanvas.width,
          pageCanvas.height
        );

        const imgData = pageCanvas.toDataURL("image/png");
        doc.addImage(imgData, "PNG", 15, 15, imgWidth, pageHeight);
        y += pageCanvas.height;

        if (y < canvas.height) {
          doc.addPage();
        }
      }

      this.isExporting = false;
      this.showExportingOverlay = false;

      return doc;
    },

    async print() {
      const { default: printJS } = await import("print-js");
      const doc = await this.exportToPdf();

      printJS({
        printable: doc.output("datauristring").split(",")[1],
        type: "pdf",
        base64: true,
      });
    },
  },
};
</script>

<style lang="scss">
.overlay-content {
  top: 35%;
  left: calc(50% - 64px);
}
</style>
