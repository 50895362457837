<template>
  <div>
    <div
      v-for="(itemsFromGroup, type, groupedIndex) in groupedItems"
      :key="type"
      :style="{ '--type-color': types[type].color }"
    >
      <div class="d-flex align-items-center">
        <div class="color-square mr-2"></div>
        <h5 class="mb-0">{{ types[type].title }}</h5>
      </div>

      <slot name="additionalContentBeforeList" :type="type" />

      <component
        v-if="itemsFromGroup.length"
        :is="collapsable ? 'eg-additional-collapse' : 'div'"
        label="Show sentences"
      >
        <base-item
          v-for="(item, index) in itemsFromGroup"
          ref="items"
          :class="['grammar-item', type]"
          :style="{ '--type-background': types[type].background }"
          :activeHighlightIndex="activeHighlightIndex"
          :index="calculateIndex(groupedIndex, index)"
          :key="item.id"
          :item="item"
          nextStepsKey="suggestions"
          :nextStepTitle="nextStepTitle"
          :formatTitle="() => ''"
          v-on="$listeners"
        />
      </component>
    </div>
  </div>
</template>

<script>
import BaseItem from "@/components/essay/essay/v3/base_item.vue";
import EgAdditionalCollapse from "@/components/global/eg_additional_collapse.vue";

export default {
  components: {
    BaseItem,
    EgAdditionalCollapse,
  },

  props: {
    groupedItems: {
      type: Object,
      required: true,
    },

    types: {
      type: Object,
      required: true,
    },

    activeHighlightIndex: {
      type: Number,
      required: true,
    },

    isNoRanges: {
      type: Boolean,
      default: false,
    },

    collapsable: {
      type: Boolean,
      default: false,
    },

    nextStepTitle: {
      type: String,
      default: "Pro tip",
    },
  },

  watch: {
    groupedItems: {
      immediate: true,
      handler() {
        if (this.isNoRanges) return;

        this.$nextTick(() => {
          const highlights = Object.keys(this.groupedItems).reduce(
            (acum, type, groupedIndex) => {
              const itemsFromGroup = this.groupedItems[type];

              for (let index = 0; index < itemsFromGroup.length; index++) {
                const item = itemsFromGroup[index];
                const realIndex = this.calculateIndex(groupedIndex, index);

                if (!item.ranges) {
                  return acum;
                }

                for (
                  let rangeIndex = 0;
                  rangeIndex < item.ranges.length;
                  rangeIndex++
                ) {
                  acum.push({
                    range: item.ranges[rangeIndex],
                    highlightIndex: realIndex + 1,
                    refComponent: this.$refs.items?.[realIndex],
                    color: this.types[type].color,
                    backgroundColor: this.types[type].background,
                  });
                }
              }

              return acum;
            },
            []
          );
          this.$emit("updateHighlights", highlights);
        });
      },
    },
  },

  methods: {
    calculateIndex(groupedIndex, index) {
      return (
        Object.keys(this.groupedItems)
          .slice(0, groupedIndex)
          .reduce((sum, key) => sum + this.groupedItems[key].length, 0) + index
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.color-square {
  width: 12px;
  height: 12px;
  border-radius: 4px;
  background-color: var(--type-color, #000);
}

.grammar-item.item-active::v-deep .content {
  background-color: var(--type-background, #fffbd9);
  margin: -5px -16px;
  padding: 5px 16px;
}

.grammar-item.spelling {
  &:nth-child(1) {
    margin-top: 16px;
  }

  &::v-deep .actions {
    position: absolute;
    top: -4px;
    right: 0;
  }
}
</style>
