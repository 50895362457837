import {
  getEssayFeedback,
  getEssayGrammar,
  updateEssayFeedbackRecord,
  deleteEssayFeedbackRecord,
  updateEssayGrammarRecord,
  deleteEssayGrammarRecord,
  updateEssay,
} from "@/api";
import capitalize from "@/utils/capitalize";

const api = {
  getEssayFeedback,
  updateEssayFeedbackRecord,
  deleteEssayFeedbackRecord,

  getEssayGrammar,
  updateEssayGrammarRecord,
  deleteEssayGrammarRecord,
};

export default (type) => ({
  props: {
    essay: {
      type: Object,
      required: true,
    },

    activeHighlightIndex: {
      type: Number,
      default: -1,
    },
  },

  // special for copy/paste (hide all actions)
  provide: {
    specialForCopyPaste: false,
  },

  data() {
    return {
      items: [],
      loading: true,
    };
  },

  computed: {
    isNoRanges() {
      return (this.essay?.tags || []).includes("no-ranges");
    },
  },

  async created() {
    await this.getEssayTabData();
    this.loading = false;
  },

  watch: {
    // Watch for changes to essay.id and reload data when it changes
    "essay.id": {
      handler: async function (newId, oldId) {
        if (newId !== oldId) {
          this.loading = true;
          await this.getEssayTabData();
          this.loading = false;
        }
      },
      immediate: false,
    },
  },

  methods: {
    async getEssayTabData() {
      const { data } = await api[`getEssay${capitalize(type)}`](this.essay.id);
      this.items = data[type];
    },

    async updateEssayTabData({ data, resolve }) {
      const { itemId, ...restData } = data;
      await api[`updateEssay${capitalize(type)}Record`](
        this.essay.id,
        itemId,
        restData
      );

      this.items.forEach((item) => {
        if (item.id === itemId) {
          Object.assign(item, restData);
        }
      });

      resolve();
    },

    async deleteEssayTabData({ itemId }) {
      await api[`deleteEssay${capitalize(type)}Record`](this.essay.id, itemId);
      this.items = this.items.filter((item) => item.id !== itemId);
    },

    async updateEssayField(updates) {
      try {
        const result = await updateEssay(this.essay.id, updates);
        this.$bvToast.toast(`The essay was successfully saved.`, {
          title: "Success",
          variant: "success",
          solid: true,
        });

        return result;
      } catch (error) {
        this.$showToastError(
          `There was a problem saving the essay. Please try again.`
        );

        return {};
      }
    },

    async updateEssay({ data, resolve }) {
      const isFeedback = type === "feedback";
      const summaryField = isFeedback ? "feedback" : "grammar";
      const summary = {
        [summaryField]: { ...this.essay.summary[summaryField], ...data },
      };

      const result = await this.updateEssayField({ summary });

      if (result?.data?.summary) {
        this.$emit("updateEssay", {
          ...this.essay,
          summary: result?.data?.summary,
        });
      }

      resolve();
    },

    async updateGrammarPositive({ data, resolve }) {
      const { analysis } = data;
      const summary = {
        grammar: { ...this.essay.summary.grammar, positive: analysis },
      };

      const result = await this.updateEssayField({ summary });

      if (result?.data?.summary) {
        this.$emit("updateEssay", {
          ...this.essay,
          summary: result?.data?.summary,
        });
      }

      resolve();
    },
  },
});
