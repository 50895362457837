<template>
  <base-insight-results
    :assignment-id="assignmentId"
    :can-init="assignmentId !== undefined"
    v-bind="$attrs"
    empty-title="No assignments have been created yet."
    empty-description="At least one assignment needs to exist to show data."
  >
    <template #pdfTitle>
      <slot name="pdfTitle" />
    </template>

    <template #selects>
      <slot name="selects" />
    </template>
    <!--  TODO: Remove after the release v3.3 or uncomment  -->
    <!--<template #block-scores="{ insight, isExporting, loading }">
      <score-distribution
        :score-distribution="insight?.scoreDistribution"
        :is-exporting="isExporting"
        :loading="loading"
      />
    </template>-->
  </base-insight-results>
</template>

<script>
import BaseInsightResults from "@/components/insight/base_insight_results.vue";
// import ScoreDistribution from "@/components/insight/score_distribution.vue";
export default {
  name: "InsightResults",

  components: {
    BaseInsightResults,
    // ScoreDistribution,
  },

  props: {
    assignmentId: {
      type: Number,
    },
  },
};
</script>
