<template>
  <b-toast
    id="payment-toast"
    :variant="variant"
    :no-close-button="!isClosable"
    :no-auto-hide="!isClosable"
    solid
    :visible="visible"
  >
    <template #toast-title>
      <div class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">Please wait...</strong>
      </div>
    </template>

    <div class="text-center">
      <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
      <p>
        {{ message }}
      </p>
    </div>
  </b-toast>
</template>

<script>
import { getUser } from "@/api";
import {
  CONVERSION_PRICE_KEY,
  LSK_PAYMENT_PROCESSING_SESSION_ID,
} from "@/constants";
import fireConfetti from "@/utils/fireConfetti";

const PULL_INTERVAL = 5000;
const titles = {
  waiting: "Please wait...",
  success: "Payment successful",
};
const messages = {
  waiting:
    "We received your payment. Hang tight, while we update your account info.",
  success: "Congratulations! You have successfully upgraded. Happy grading!",
};

export default {
  data() {
    return {
      visible: false,
      variant: "info",
      isClosable: false,
      title: titles.waiting,
      message: messages.waiting,
    };
  },

  watch: {
    "$route.query.session_id": {
      immediate: true,
      handler() {
        const session_id =
          this.$route.query.session_id ||
          localStorage.getItem(LSK_PAYMENT_PROCESSING_SESSION_ID);

        if (!session_id || this.visible) return;

        localStorage.setItem(LSK_PAYMENT_PROCESSING_SESSION_ID, session_id);
        this.startProcessing();
      },
    },
  },

  methods: {
    startProcessing() {
      this.showToast();
      try {
        this.$route.query.session_id && this.$router.replace({ query: null });
      } catch (error) {
        // NOTE: This is a workaround to prevent the router from throwing an error
        // do nothing
      }
      this.userPull();
    },

    showToast() {
      // this.$bvToast.show("payment-toast");
      this.visible = true;
    },

    async userPull() {
      const { data } = await getUser("me");
      await this.$store.dispatch("setUser", data);

      if (data.plan !== "basic") {
        this.reportConversion();
        this.createTrustpilotInvitation();
        localStorage.removeItem(LSK_PAYMENT_PROCESSING_SESSION_ID);
        this.variant = "success";
        this.isClosable = true;
        this.title = titles.success;
        this.message = messages.success;
        fireConfetti();
      } else {
        setTimeout(() => {
          this.userPull();
        }, PULL_INTERVAL);
      }
    },

    // Report conversion and delete conversion price from local storage
    reportConversion() {
      const conversion_price = localStorage.getItem(CONVERSION_PRICE_KEY);
      localStorage.removeItem(CONVERSION_PRICE_KEY);

      this.$trackEvent("plan_purchased", {
        plan: this.$store.state.user.plan,
        conversion_price,
      });

      if (typeof gtag === "function") {
        window.gtag("event", "conversion", {
          send_to: "AW-16545029854/0VZ6CN7H8ccZEN69pNE9",
          value: conversion_price,
          currency: "USD",
        });
      }

      window.uetq = window.uetq || [];
      window.uetq.push("event", "purchase", {
        revenue_value: conversion_price,
        currency: "USD",
      });
    },

    createTrustpilotInvitation() {
      // Check if Trustpilot script is loaded
      if (typeof tp !== "function") {
        return;
      }

      // Get user data from store
      const user = this.$store.state.user;
      if (!user?.email || !user?.name) {
        console.warn("Missing user data for Trustpilot invitation");
        return;
      }

      const trustpilot_invitation = {
        recipientEmail: user.email,
        recipientName: user.name,
        referenceId: `Order_${Date.now()}`, // Generate unique reference ID
        source: "InvitationScript",
      };

      try {
        // eslint-disable-next-line no-undef
        tp("createInvitation", trustpilot_invitation);
      } catch (error) {
        // do nothing
      }
    },
  },
};
</script>
