<template>
  <div>
    <div class="essays-table-filters p-3">
      <div class="sort">
        <table-sort-dropdown
          @sort="$emit('filter-changed', $event)"
          :available-sort="[...availableSorts, 'grade']"
        />
      </div>
      <div class="divider"></div>
      <div class="filters d-flex align-items-center">
        <div v-for="(filter, index) in selectedFilters" :key="filter.key">
          <filter-dropdown
            :button-label="
              allAvailableFilters[filter.key]?.filterName ||
              allAvailableFilters[filter.key]?.title
            "
            :active="filter.isActive"
            :right="index > 3"
            :title="allAvailableFilters[filter.key].title"
            @apply="applyFilter(index)"
            @delete="deleteFilter(index)"
          >
            <component
              :is="allAvailableFilters[filter.key].component"
              v-model="filter.value"
              :rubrics="rubrics"
              value-is-id
              no-label
              @keypress.enter="applyFilter(index)"
            ></component>
          </filter-dropdown>
        </div>
      </div>
      <b-dropdown
        v-if="notShowedAvailableFilters.length"
        class="essay-actions-dropdown"
        no-caret
        variant="link"
        size="sm"
      >
        <template #button-content>
          <div class="text-info ml-3">+ Add filter</div>
        </template>
        <b-dropdown-item
          v-for="filterKey in notShowedAvailableFilters"
          :key="filterKey"
          :value="filterKey"
          @click="addFilter(filterKey)"
        >
          {{
            allAvailableFilters[filterKey]?.filterName ||
            allAvailableFilters[filterKey]?.title
          }}
        </b-dropdown-item>
      </b-dropdown>

      <div v-if="showSelectAll" class="desktop divider"></div>
      <div
        v-if="showSelectAll && selectedAll"
        class="desktop selected-text btn-sm"
      >
        All essays {{ isFiltered ? "that match this search" : "" }} are selected
      </div>
      <b-button
        v-if="showSelectAll && !selectedAll"
        size="sm"
        variant="link"
        class="desktop text-info"
        @click="toggleSelectedAll"
      >
        <template v-if="selectedAll"> Deselect </template>
        <template v-else>
          Select all {{ total }} essays
          <span v-if="isFiltered">that match this search</span>
        </template>
      </b-button>

      <div v-if="isFiltered" class="divider"></div>
      <b-button
        v-if="isFiltered"
        variant="link"
        class="clear-all px-1 ml-2"
        @click="clearAll"
      >
        Clear all
      </b-button>

      <essay-actions
        :disabled="!selectedIds.length"
        class="desktop ml-auto"
        :selected-all="selectedAll"
        :selected-ids="selectedIds"
        @export="$emit('export', $event)"
        @delete="$emit('delete', $event)"
      />
    </div>

    <div
      v-if="
        (showSelectAll && selectedAll) ||
        (showSelectAll && selectedAll) ||
        selectedIds.length
      "
      class="tablet essays-table-filters p-3"
    >
      <div v-if="showSelectAll && selectedAll" class="selected-text btn-sm">
        All essays {{ isFiltered ? "that match this search" : "" }} are selected
      </div>
      <b-button
        v-if="showSelectAll && !selectedAll"
        size="sm"
        variant="link"
        class="text-info"
        @click="toggleSelectedAll"
      >
        <template v-if="selectedAll"> Deselect </template>
        <template v-else>
          Select all {{ total }} essays
          <span v-if="isFiltered">that match this search</span>
        </template>
      </b-button>

      <essay-actions
        v-if="selectedIds.length"
        class="ml-auto"
        :selected-all="selectedAll"
        :selected-ids="selectedIds"
        @export="$emit('export', $event)"
        @delete="$emit('delete', $event)"
      />
    </div>
  </div>
</template>

<script>
import { getRubrics } from "@/api";
import TableSortDropdown from "./table_sort_dropdown.vue";
import InputFilter from "./input_filter.vue";
import DateRange from "./date_range.vue";
import RubricSelect from "@/components/rubrics/rubric_select";
import ClassSelect from "@/components/classes/class_select";
import StudentSelect from "@/components/students/student_select.vue";
import AssignmentSelect from "@/components/essay/assignment_select";
import EssayActions from "./essay_actions.vue";
import FilterDropdown from "./filter_dropdown.vue";

const allAvailableFilters = {
  name: {
    filterName: "Essay name",
    title: "Essay name contains",
    key: "name",
    component: "input-filter",
  },
  class: { title: "Class", key: "ClassId", component: "class-select" },
  rubric: { title: "Rubric", key: "RubricId", component: "rubric-select" },
  createdAt: {
    title: "Graded date range",
    key: "createdAt",
    component: "date-range",
  },
  studentName: {
    filterName: "Student Name",
    title: "Student Name contains",
    key: "studentName",
    component: "input-filter",
  },
  studentId: {
    filterName: "Student",
    title: "Student",
    key: "studentId",
    component: "student-select",
  },
  assignment: {
    title: "Assignment",
    key: "AssignmentId",
    component: "assignment-select",
  },
};
export default {
  name: "EssaysTableFilters",

  props: {
    selectedIds: {
      type: Array,
      default: () => [],
    },
    availableSorts: {
      type: Array,
      default: () => [
        "name",
        "class",
        "rubric",
        "assignment",
        "createdAt",
        "studentName",
      ],
    },
    availableFilters: {
      type: Array,
      default: () => [
        "name",
        "class",
        "rubric",
        "assignment",
        "createdAt",
        "studentName",
      ],
    },
    total: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    itemsToSelectCounts: {
      type: Number,
      default: 10000,
    },
    selectedAll: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    InputFilter,
    DateRange,
    RubricSelect,
    ClassSelect,
    StudentSelect,
    AssignmentSelect,
    TableSortDropdown,
    EssayActions,
    FilterDropdown,
  },

  data() {
    return {
      allAvailableFilters,
      selectedFilters: [{ key: "name", value: "", isActive: false }],
      error: "",
      rubrics: [],
    };
  },

  computed: {
    showSelectAll() {
      return (
        this.total > this.limit &&
        this.selectedIds.length === this.itemsToSelectCounts
      );
    },

    notShowedAvailableFilters() {
      const selectedFiltersKeys = this.selectedFilters.map(
        (filter) => filter.key
      );

      return this.availableFilters.filter(
        (filter) => !selectedFiltersKeys.includes(filter)
      );
    },

    groupedRubrics() {
      return this.rubrics.reduce((acc, rubric) => {
        if (!acc[rubric.type]) {
          acc[rubric.type] = [];
        }
        acc[rubric.type].push(rubric);
        return acc;
      }, {});
    },

    isFiltered() {
      return this.selectedFilters.some((filter) => filter.isActive);
    },

    routeQuery() {
      return this.$route?.query;
    },
  },

  watch: {
    routeQuery: {
      handler() {
        if (this.routeQuery.studentId !== undefined) {
          let index = this.selectedFilters.findIndex(
            (filter) => filter.key === "studentId"
          );

          if (index === -1) {
            this.addFilter("studentId");
            index = this.selectedFilters.length - 1;
          }

          if (
            this.selectedFilters[index].value !==
            Number(this.routeQuery.studentId)
          ) {
            this.selectedFilters[index].value = Number(
              this.routeQuery.studentId
            );
            this.applyFilter(index);
          }

          // NOTE: may need to reset url...?
          // this.$router.replace("/essays");
        }
      },
      immediate: true,
    },
  },

  methods: {
    emitFilters() {
      this.$emit("filter-changed", {
        filters: this.selectedFilters
          .filter((filter) => {
            return filter.isActive;
          })
          .reduce((acc, filter) => {
            const filterKey = this.allAvailableFilters[filter.key].key;
            if (!filterKey) {
              console.log(`No filter key for ${filter.key}`);

              return acc;
            }

            acc[filterKey] = filter?.value?.id ? filter.value.id : filter.value;

            return acc;
          }, {}),
      });
    },

    applyFilter(index) {
      this.selectedFilters[index].isActive = true;
      this.emitFilters();
      // hide dropdown
      this.$refs.filterDropdowns?.[index]?.hide();
    },

    addFilter(filterKey) {
      this.selectedFilters.push({
        key: filterKey,
        value: null,
        isActive: false,
      });
    },

    deleteFilter(index) {
      const isActive = this.selectedFilters[index].isActive;
      this.selectedFilters.splice(index, 1);

      if (isActive) {
        this.emitFilters();
      }
    },

    clearAll() {
      this.selectedFilters.forEach((filter) => {
        filter.isActive = false;
      });
      this.$emit("filter-changed", { filters: {} });
    },

    async fetchRubrics() {
      try {
        const { data } = await getRubrics();
        this.rubrics = data;
      } catch (error) {
        this.error = error.response?.data?.error;
      }
    },

    toggleSelectedAll() {
      this.$emit("selectedAll", !this.selectedAll);
    },
  },

  created() {
    this.fetchRubrics();
  },
};
</script>

<style lang="scss" scoped>
.essays-table-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  border-radius: 0.25rem;
  border: 1px solid hsla(0, 0%, 87%, 1);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  .desktop,
  &.desktop {
    @media (max-width: 768px) {
      display: none;
    }
  }

  .tablet,
  &.tablet {
    display: none;

    @media (max-width: 768px) {
      display: flex;
    }
  }

  @media (max-width: 768px) {
    flex-wrap: nowrap;
    margin-bottom: 1rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  & > .form-group {
    flex: 0 1 100%;
    max-width: 100%;

    @media (min-width: 768px) {
      flex: 0 1 215px;
      max-width: 215px;
    }
  }

  &::v-deep .btn-link:hover {
    text-decoration: unset;
  }
}

.selected-text {
  border: 1px solid transparent;
}

/* .search-input-wrapper {
  position: relative;

  .clear-input {
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
  }
} */

.clear-all {
  color: #6c757d;
  flex: 0 1 75px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  flex-shrink: 0;
}

/* .export-to-gc {
  width: 220px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  flex-shrink: 0;
  margin-left: auto;

  svg {
    height: 14px;
    width: 14px;
    margin-bottom: 2px;
  }
} */

.filters {
  gap: 8px;
}

.text-info:focus {
  box-shadow: none;
  text-decoration: none;
}

.divider {
  margin: 0 8px;
  height: 31px;
  border-left: 1px solid var(--secondary);
}
</style>
