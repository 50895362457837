<template>
  <div v-if="flag.type === 'closeToGrade'">
    <div class="d-flex align-items-center">
      <b-icon variant="danger" class="mr-3" icon="flag" />
      <div class="flag-title">
        Grade was close to passing grade: {{ flag.grade }}%
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    flag: {
      type: Object,
      required: true,
    },
  },
};
</script>
