export default (flag) => ({
  name: "FlagUsedAIAnalysis",

  data: function () {
    return {
      flag,
    };
  },

  render: function (h) {
    let rank = "highly";
    if (this.flag.summary.aiUsageScore < 33) {
      rank = "low";
    } else if (this.flag.summary.aiUsageScore < 66) {
      rank = "medium";
    }

    return h("div", [
      "Our detector is ",
      h("b", `${rank} confident`),
      " that the text is written by AI.",
    ]);
  },
});
