import api from "@/api/api";

export const getStudents = ({ classId, limit = 1000 }) => {
  const params = {
    limit,
  };

  if (classId) {
    params.classId = classId;
  }

  return api.get("/students", { params });
};
