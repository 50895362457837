var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"comment position-relative d-flex py-3 gap-3"},[_c('div',{staticClass:"w-100 pl-3"},[(_vm.isEdit)?_c('div',{staticClass:"text mt-2"},[_c('b-form-textarea',{attrs:{"placeholder":"Enter comment...","rows":"2","max-rows":"4"},model:{value:(_vm.newCommentText),callback:function ($$v) {_vm.newCommentText=$$v},expression:"newCommentText"}})],1):_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"index mr-2"},[_vm._v(_vm._s(_vm.index + 1))]),_c('div',[_c('div',{staticClass:"text mb-2"},[_vm._v(_vm._s(_vm.comment.text))]),_c('div',{staticClass:"info-text"},[_c('span',[_vm._v("Commented by ")]),_c('b',[_vm._v(_vm._s(_vm.comment.author.firstName)+" "+_vm._s(_vm.comment.author.lastName))]),_vm._v(" at "+_vm._s(_vm.date)+" ")])])])]),_c('div',{staticClass:"flex-none pr-3"},[(!_vm.isEdit)?_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"ml-auto mr-2",attrs:{"variant":"outline-info","size":"sm"},on:{"click":() => {
            _vm.isEdit = true;
            _vm.newCommentText = _vm.comment.text;
          }}},[_vm._v(" Edit ")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-info"},on:{"click":function($event){return _vm.$emit('delete', _vm.comment)}}},[_vm._v(" Delete ")])],1):_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"ml-auto mr-2",attrs:{"variant":"outline-info","size":"sm"},on:{"click":function($event){_vm.isEdit = false}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"size":"sm","variant":"info"},on:{"click":() => {
            _vm.isEdit = false;
            _vm.isLoading = true;
            _vm.$emit('update', { commentId: _vm.comment.id, text: _vm.newCommentText });
          }}},[_vm._v(" Save ")])],1)]),_c('b-overlay',{attrs:{"show":_vm.isLoading,"no-wrap":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }