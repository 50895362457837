<template>
  <div class="chart-js" :class="type">
    <div class="virtual-tooltip-element" ref="special" />
    <div :style="chartStyle" class="chart">
      <canvas ref="canvas" />
    </div>
    <b-tooltip
      ref="tooltip"
      :target="() => $refs.special"
      placement="top"
      fallback-placement="clockwise"
      triggers="manual"
      boundary="window"
      :show="tooltipIsShow"
    >
      <div class="chart-custom-tooltip" v-html="tooltipContent"></div>
    </b-tooltip>
  </div>
</template>

<script>
import { Chart as ChartJS } from "chart.js";

// NOTE: We need to store chart data outside Vue component for skip observe Proxy type from Chart js (anotation plugin)
let chart = null;

export default {
  name: "ChartJSBase",

  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartOptions: {
      type: Object,
      default: () => {},
    },

    width: {
      type: [Number, String],
      default: 400,
    },

    height: {
      type: Number,
      default: 400,
    },
  },

  data() {
    return {
      type: "bar",

      tooltipIsShow: false,
      tooltipContent: "",
    };
  },

  computed: {
    chartStyle() {
      return {
        "--chart-width":
          typeof this.width === "string" ? this.width : `${this.width}px`,
        "--chart-height": `${this.height}px`,
      };
    },
  },

  watch: {
    chartData: {
      deep: true,
      handler: "updateData",
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      if ("datasets" in this.chartData && this.chartData.datasets.length > 0) {
        chart = new ChartJS(this.$refs.canvas.getContext("2d"), {
          type: this.type,
          data: this.chartData,
          options: {
            ...this.chartOptions,
            plugins: {
              ...this.chartOptions?.plugins,
              tooltip: {
                ...this.chartOptions?.plugins?.tooltip,
                enabled: false, // off default chart.js tooltip
                external: this.renderCustomTooltip, // add custom tooltip
              },
            },
          },
        });
      }
    },

    updateData(newChartData) {
      if ("datasets" in newChartData && newChartData?.datasets?.length > 0) {
        if (!chart) {
          return this.init();
        }

        chart.data.labels = newChartData.labels;
        chart.data.datasets = newChartData.datasets;
        chart.update();
      }
    },

    renderCustomTooltip(context) {
      const tooltipModel = context.tooltip;

      // if tooltip is not visible, need off custom tooltip
      if (!tooltipModel.opacity) {
        this.tooltipIsShow = false;

        return;
      }

      // get content from chart tooltip options
      // custom tooltip support HTML content
      // NOTE: right now we get content only from 'body' callback (but we can use afterBody, beforeBody, and mach more)
      const lines = tooltipModel.body?.[0]?.lines || [];
      this.tooltipContent = lines.join("<br/>") || "";

      // move virtual element to pointer position
      this.$refs.special.style.transform = `translate(${tooltipModel.caretX}px, ${tooltipModel.caretY}px)`;

      // show custom tooltip on virtual element
      this.tooltipIsShow = true;

      // some time we have a lag to show tooltip (fast move), then we manually update tooltip
      // TODO: understand why and fix it, for now we use setTimeout 200 is good (just tested on my computer)
      // set 200, because it's not lag on eye and enough for work, (looks like 50-150 is ok, but has some time a lag)
      // IDEA: looks like it happen because of transition (hide animation)
      setTimeout(() => {
        if (
          !this.$refs?.tooltip?.localShow &&
          tooltipModel.getActiveElements().length > 0
        ) {
          this.$refs?.tooltip?.$_toolpop.doShow();
        }
      }, 200);
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  --chart-width: 250px;
  --chart-height: 250px;
  width: var(--chart-width) !important;
  height: var(--chart-height) !important;
}
.virtual-tooltip-element {
  width: 1px;
  height: 10px;
  z-index: -1;
  position: relative;
  will-change: transform;
  transform: translate(0, 0);
}

.chart-custom-tooltip {
  text-align: left;
}
</style>
