<template>
  <div class="virtual-tooltip-element" :style="style">
    <component
      :is="component"
      ref="tooltip"
      :target="() => $el"
      :show="tooltipIsShow"
      v-bind="$attrs"
    >
      <slot :close="close" />
    </component>
  </div>
</template>

<script>
export default {
  props: {
    x: { type: Number, default: 0 },
    y: { type: Number, default: 0 },
    elementHeight: { type: Number, default: 2 },
    component: {
      type: String,
      default: "b-popover",
      validator: (value) => ["b-popover", "b-tooltip"].includes(value),
    },
  },

  data() {
    return {
      tooltipIsShow: false,
    };
  },

  computed: {
    style() {
      return {
        transform: `translate(${this.x}px, ${this.y}px)`,
        height: `${this.elementHeight / 2}px`,
      };
    },
  },

  methods: {
    // NOTE used from parent
    close() {
      this.tooltipIsShow = false;
    },

    // NOTE used from parent
    open() {
      this.tooltipIsShow = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.virtual-tooltip-element {
  width: 1px;
  height: 1px;
  z-index: -1;
  position: absolute;
  will-change: transform;
  transform: translate(0px, 0px);
}
</style>
