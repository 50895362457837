import api from "@/api/api";

export const getUsage = () => {
  return api.get("/usage");
};

export const getLanguages = () => {
  return api.get("/languages");
};

export const getFeatures = () => {
  return api.get("/webflow/features");
};

export const getBlogPostById = (blogPostId) => {
  return api.get(`/webflow//blog/${blogPostId}`);
};

/**
 *
 * @param {*} formData
 * @returns {Promise<string[]>} Array of uploaded files URLs https://documenter.getpostman.com/view/33763505/2sA35G21ov#0c91e8aa-5892-4298-88c0-3a65fc69029f
 */
export const upload = (formData) => {
  return api.put("/upload/spaces", formData, {
    headers: {
      "Content-type": "multipart/form-data",
    },
  });
};
