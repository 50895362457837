<script>
import { freeToolGrade } from "@/api/requests/freeTools";
import UploadEssayStep from "@/components/free-tools/grade/UploadEssayStep.vue";
import FeedbackStep from "@/components/free-tools/grade/FeedbackStep.vue";
import LoadingStep from "@/components/free-tools/grade/LoadingStep.vue";
import FreeToolsWrapper from "@/views/free-tools/free-tools-wrapper.vue";

export default {
  name: "FreeToolGrade",
  components: {
    FreeToolsWrapper,
    LoadingStep,
    FeedbackStep,
    UploadEssayStep,
  },
  data() {
    return {
      selectedEssayOption: "text",
      selectedGradingLevel: "middleschool",
      selectedGradingIntensity: "moderate",
      expired: false,
      blocked: false,
      essayContents: "",
      files: [],
      selectedRubric: null,
      step: 0,
      rubrics: [],
      feedback: null,
      errors: null,
    };
  },
  methods: {
    async grade() {
      try {
        const payload = {
          gradeLevel: this.selectedGradingLevel,
          intensity: this.selectedGradingIntensity,
          rubricId: this.selectedRubric.id,
        };
        if (this.selectedEssayOption === "text") {
          payload.text = this.essayContents;
        } else {
          await this.$refs.uploadStep.upload();
          payload.files = this.files.map((file) => file.fileUrl);
        }
        this.step = 1;
        const {
          data: { feedback, errors, content },
        } = await freeToolGrade(payload);
        this.feedback = feedback.replaceAll("\n", "");
        this.errors = errors.replaceAll("\n", "");
        this.essayContents = content;

        this.step = 2;
      } catch (error) {
        this.step = 0;
      } finally {
        this.$nextTick(() => {
          this.$refs.freeToolsWrapper.notifyResize();
        });
      }
    },

    reset() {
      this.files = [];
      this.essayContents = "";
      this.step = 0;
      this.selectedEssayOption = "text";
    },
  },
};
</script>

<template>
  <FreeToolsWrapper ref="freeToolsWrapper">
    <UploadEssayStep
      v-if="step === 0"
      :selectedEssayOption="selectedEssayOption"
      @selectEssayOption="selectedEssayOption = $event"
      :essayContents="essayContents"
      @setEssayContent="essayContents = $event"
      :files="files"
      @file-added="files = [$event]"
      @file-removed="files = []"
      @file-uploaded="files = $event"
      :selectedGradingLevel="selectedGradingLevel"
      @selectGradingLevel="selectedGradingLevel = $event"
      @selectRubric="selectedRubric = $event"
      :selectedGradingIntensity="selectedGradingIntensity"
      @selectGradingIntensity="selectedGradingIntensity = $event"
      @grade="grade"
      ref="uploadStep"
    />
    <LoadingStep v-else-if="step === 1" />
    <FeedbackStep
      v-else-if="step === 2"
      :essayContents="essayContents"
      :feedback="feedback"
      :errors="errors"
      @reset="reset"
    />
  </FreeToolsWrapper>
</template>

<style scoped lang="scss">
.loading-text {
  font-family: "Lora", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}
</style>
