<template>
  <div :class="{ isExporting }">
    <b-overlay
      :show="loading || innerLoading"
      spinner-small
      rounding="sm"
      no-wrap
    >
      <template #overlay>
        <div class="d-flex flex-column align-items-center">
          <b-spinner small variant="info" />
          <div>Generating…</div>
        </div>
      </template>
    </b-overlay>

    <div class="d-flex align-items-center">
      <h5 class="mr-auto">Writing report breakdown</h5>
    </div>

    <empty-state
      v-if="!(loading || innerLoading) && errorsBreakdown.length == 0"
      class="mt-5"
      title="No error breakdown found"
    >
      <img
        src="../../assets/icons/chart.svg"
        width="48"
        alt="EssayGrader Empty State"
      />
    </empty-state>

    <b-tabs no-fade class="error-tabs">
      <b-tab
        v-for="error in errorsBreakdown"
        :key="error.errorType"
        :title-item-class="`title-size-${error.percentage}`"
        :style="{
          '--error-background-color':
            ERROR_BREAKDOWN_TYPES[error.errorType].color,
        }"
      >
        <template #title>
          <div
            :style="{
              '--error-background-color':
                ERROR_BREAKDOWN_TYPES[error.errorType].color,
            }"
          >
            <div class="error-tab"></div>
            <div class="d-flex justify-content-between virtual-border">
              <div class="virtual-border-left" />
              <div class="virtual-border-right" />
            </div>
          </div>
        </template>

        <div class="tab-content p-4">
          <div class="d-flex align-items-center mb-2">
            <div class="error-type mr-2"></div>
            <div class="text-capitalize">
              {{ ERROR_BREAKDOWN_TYPES[error.errorType].title }}
            </div>
          </div>

          <div
            class="mb-1"
            v-html="`<strong>Top error example:</strong> ${error.topExample}`"
          ></div>

          <next-steps-box
            :title="'Suggestions To Improve'"
            :text="error.improveSuggestion"
            :isList="false"
          />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import NextStepsBox from "@/components/essay/essay/v3/next_steps_box.vue";
import { getErrorsBreakdown } from "@/api";
import EmptyState from "@/components/global/empty_state.vue";
import { errorsBreakdown } from "./example_data";
import { ERROR_BREAKDOWN_TYPES } from "@/constants";

export default {
  name: "ErrorBreakdown",

  components: {
    EmptyState,
    NextStepsBox,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    classId: {
      type: Number,
    },
    assignmentId: {
      type: Number,
    },
    studentId: {
      type: Number,
    },

    isExporting: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      ERROR_BREAKDOWN_TYPES,
      errorsBreakdown: {},
      innerLoading: false,
    };
  },

  watch: {
    classId() {
      this.init();
    },

    assignmentId() {
      this.init();
    },

    studentId() {
      this.init();
    },
  },

  created() {
    this.init();
  },

  methods: {
    setBreakdown(errorsBreakdown) {
      const roundToFive = (error) => Math.round(error.percentage / 5) * 5;
      const rounded = errorsBreakdown.map(roundToFive);

      // Calculate sum
      let sum = rounded.reduce((a, b) => a + b, 0);

      // Adjust to ensure sum is 100
      while (sum !== 100) {
        if (sum > 100) {
          const maxIndex = rounded.indexOf(Math.max(...rounded));
          rounded[maxIndex] -= 5;
          sum -= 5;
        } else {
          const minIndex = rounded.indexOf(Math.min(...rounded));
          rounded[minIndex] += 5;
          sum += 5;
        }
      }

      rounded.forEach((value, i) => (errorsBreakdown[i].percentage = value));

      this.errorsBreakdown = errorsBreakdown;
    },

    async init() {
      if (!this.classId && !this.assignmentId && !this.studentId) {
        return;
      }

      this.setBreakdown(errorsBreakdown);
      this.innerLoading = true;

      const { data } = await getErrorsBreakdown(
        this.classId,
        this.assignmentId,
        this.studentId
      );

      this.setBreakdown(data.errorsBreakdown);
      this.innerLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-content {
  .error-type {
    width: 12px;
    height: 12px;
    border-radius: 4px;
    background: var(--error-background-color);
  }

  border-radius: 4px;
  border: 1px solid #dfdfdf;
}

.error-tabs {
  margin-left: -4px;

  .virtual-border-left,
  .virtual-border-right {
    z-index: 0;
    width: 3px;
    margin-top: -3px;
    height: 17px;
    border-bottom: 1px solid #dfdfdf;
    opacity: 0;
  }

  .virtual-border-left {
    border-right: 1px solid #dfdfdf;
    margin-left: -2px;
    border-bottom-right-radius: 4px;
  }

  .virtual-border-right {
    border-left: 1px solid #dfdfdf;
    margin-right: -2px;
    border-bottom-left-radius: 4px;
  }

  .error-tab {
    width: 100%;
    height: 24px;
    border-radius: 4px;
    position: relative;
    z-index: 1;
    background-color: var(--error-background-color);
  }

  &::v-deep {
    @mixin generate-title-sizes($step: 5, $max: 100) {
      @for $i from $step through $max {
        .title-size-#{$i} {
          width: #{"#{$i}%"};
        }
      }
    }

    @include generate-title-sizes();

    .nav-tabs {
      flex-wrap: nowrap;
      border: none;
      padding: 0 3px;

      .nav-link {
        padding: 0 2px;
        border: none;

        &.active {
          .virtual-border-left,
          .virtual-border-right {
            opacity: 1;
          }
        }
      }
    }
  }
}

/* .type-spelling {
  background-color: #007bff;
}

.type-grammar {
  background-color: #f28c27;
}

.type-sentenceStructure {
  background-color: #714bd4;
}

.type-coherenceGaps {
  background-color: #17a2b8;
}

.type-clarityIssues {
  background-color: #318c46;
}

.type-logicalInconsistencies {
  background-color: #ce306c;
} */

@mixin isExporting() {
  .virtual-border {
    display: none !important;
  }

  .tab-pane {
    display: block !important;
  }

  .tab-content {
    padding: 15px 5px !important;
    border: 0;
    border-bottom: 1px solid #dfdfdf;
  }
}

.isExporting {
  @include isExporting();
}

@media (max-width: 768px) {
  @include isExporting();
}
</style>
