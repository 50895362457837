<template>
  <div class="d-flex align-items-center">
    <b-form-datepicker
      v-model="from"
      class="datepicker"
      no-close-on-select
      size="sm"
      placeholder="Select date"
      locale="en"
      :date-disabled-fn="dateFromDisabled"
      :date-format-options="{
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        weekday: 'short',
      }"
    />
    <div class="to">to</div>
    <b-form-datepicker
      v-model="to"
      ref="toPicker"
      class="datepicker"
      no-close-on-select
      size="sm"
      placeholder="Select date"
      locale="en"
      :date-disabled-fn="dateToDisabled"
      :date-format-options="{
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        weekday: 'short',
      }"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({ from: "", to: "" }),
    },

    resetSignal: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      from: "",
      to: "",
    };
  },

  watch: {
    resetSignal() {
      this.from = "";
      this.to = "";
    },

    from() {
      this.emit();
      this.from && this.$refs?.toPicker?.$refs?.control?.show();
    },

    to() {
      this.emit();
    },
  },

  created() {
    this.from = this.value?.from ? new Date(this.value?.from) : "";
    this.to = this.value?.to ? new Date(this.value?.to) : "";
  },

  methods: {
    emit() {
      this.$emit("input", {
        from: this.from ? new Date(this.from).getTime() : "",
        to: this.to ? new Date(this.to).getTime() : "",
      });
    },

    dateFromDisabled(ymd) {
      if (!this.to) return false;

      return new Date(ymd).getTime() > new Date(this.to).getTime();
    },

    dateToDisabled(ymd) {
      if (!this.from) return false;

      return new Date(ymd).getTime() < new Date(this.from).getTime();
    },
  },
};
</script>

<style lang="scss" scoped>
.to {
  width: 30px;
  text-align: center;
}

.datepicker {
  width: 170px;
}
</style>
