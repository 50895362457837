<script>
export default {
  name: "DashboardCard",
  emits: ["click"],
  props: {
    to: String,
    iconUrl: String,
    href: String,
    label: String,
    onClick: {
      type: Function,
      required: false,
      default: null,
    },
  },
  methods: {
    handleClick(event) {
      if (this.onClick) {
        this.onClick(event);
      }
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-content">
      <slot name="preview" />
      <div class="description">
        <slot name="content" />
      </div>
    </div>
    <b-button variant="info" :to="to">{{ label }}</b-button>
  </div>
</template>

<style scoped lang="scss">
.card {
  padding: 38px 30px 30px 30px;
  border: 1px solid var(--grey-300);
  border-radius: 6px;
  display: flex;
  gap: 21px;
  max-width: 398px;
  .card-content {
    display: flex;
    gap: 24px;
  }
  .description {
    display: flex;
    flex-direction: column;
    justify-content: end;
    max-width: 225px;
  }
  img {
    max-width: 74px;
  }
  p {
    margin: 0;
    font-size: 14px;
    color: #00000066;
  }
  h3 {
    font-size: 20px;
  }
}
</style>
