<script>
import { nextTick } from "vue";
import Breadcrumbs from "../components/global/breadcrumbs.vue";
import EssaysTable from "../components/essay/essays_table.vue";
import MoveEssayModal from "../components/essay/modals/move_essay_modal.vue";
import { getClass, deleteClass, deleteEssay } from "../api";
import formatDate from "../utils/formatDate";
import fetchEssays from "@/mixins/fetchEssays";

const TABLE_FIELDS = [
  {
    key: "status",
    label: "Status",
    columnClass: "w-25",
  },
  {
    key: "name",
    label: "Essay",
    tdClass: "text-truncate",
    columnClass: "w-25",
  },
  {
    key: "createdAt",
    label: "Graded on",
    columnClass: "w-25",
    formatter: (value) => formatDate(value),
  },
  {
    key: "actions",
    label: "",
    tdClass: "text-right",
    columnClass: "w-25",
  },
];

export default {
  name: "ClassView",
  components: {
    Breadcrumbs,
    EssaysTable,
    MoveEssayModal,
  },
  mixins: [fetchEssays],
  TABLE_FIELDS,
  data() {
    return {
      loading: true,
      error: "",
      name: "",
      size: "",
      description: "",
      createdDate: "",
      currentPage: 1,
      canDelete: false,
      classId: null,
      essayToMoveId: null,
      selectedAll: false,
    };
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: "Classes",
          to: "/classes",
        },
        {
          text: this.name || "View Class",
          active: true,
        },
      ];
    },
  },

  watch: {
    essays() {
      this.canDelete = this.essays.length === 0;
    },
  },

  methods: {
    async fetchClassWithEssays() {
      await nextTick();

      this.loading = true;

      try {
        const { data } = await getClass(this.$route.params.id);
        await this.fetchEssays(); // automatically fetches essays and essays this.limit, this.count, this.queuedCount

        this.name = data.classData.name;
        this.description = data.classData.description;
        this.size = data.classData.studentsCount ?? data.classData.size ?? 0;
        this.createdDate = formatDate(data.classData.createdAt);
        this.classId = data.classData.id;

        this.loading = false;
        this.error = "";
      } catch (error) {
        this.loading = false;
        this.error = error.response?.data?.error;
      }
    },
    gradedDate(date) {
      return formatDate(date);
    },
    async deleteSingleClass() {
      try {
        this.loading = true;

        await deleteClass(this.classId);

        this.$router.push("/classes?deleted=true");

        this.loading = false;
        this.error = "";
      } catch (error) {
        this.loading = false;
        this.error = error.response?.data?.error;
      }
    },
    showMoveEssayModal(essayId) {
      this.essayToMoveId = essayId;
      this.$bvModal.show("move-essay-modal");
    },
    rerouteToClasses() {
      this.$router.push("/classes");
    },
    setMoveError(error) {
      this.error = error;
    },
    async deleteSingleEssay(essayId) {
      try {
        this.loading = true;

        await deleteEssay(essayId);

        await this.fetchClassWithEssays();

        this.showDeleteSuccess();
      } catch (error) {
        this.loading = false;
        this.error = error.response?.data?.error;
      }
    },
    showDeleteSuccess() {
      this.$bvToast.toast(`The essay(s) were deleted successfully.`, {
        title: "Essay(s) deleted",
        variant: "success",
        autoHideDelay: 3000,
        solid: true,
      });
    },
  },
  async mounted() {
    // filter essays by class id as default
    this.defaultFilters = { ClassId: this.$route.params.id };
    this.fetchClassWithEssays();
  },
};
</script>

<template>
  <b-container>
    <section v-if="loading || error">
      <b-row>
        <b-col>
          <div v-if="loading" class="text-center">
            <b-spinner class="mb-3"></b-spinner>
            <p>Processing request...</p>
          </div>
          <b-alert v-else variant="danger" show>{{ error }}</b-alert>
        </b-col>
      </b-row>
    </section>
    <section v-else>
      <breadcrumbs :items="breadcrumbs" />
      <b-row>
        <b-col lg="12" md="8">
          <div class="d-flex justify-content-between">
            <div>
              <h1 class="mb-0">{{ name }}</h1>
              <b-badge class="mr-2" pill variant="grey">
                <b-icon icon="people"></b-icon>
                {{ size }} students
              </b-badge>
              <b-badge class="mr-2" pill variant="grey">
                <b-icon icon="file-text"></b-icon>
                {{ count }} essays
              </b-badge>
              <span class="light-grey-text">Created on {{ createdDate }}</span>
              <p class="mt-3 my-3 class-description">
                {{ description }}
              </p>
            </div>

            <div>
              <b-button :to="`/class/edit/${classId}`" class="mr-2">
                Edit
              </b-button>
              <b-button
                variant="danger"
                :disabled="!canDelete"
                @click="deleteSingleClass"
              >
                Delete
              </b-button>
              <b-icon
                v-if="!canDelete"
                v-b-popover.hover.top="
                  'Classes can only be deleted when no essays exist for the class.'
                "
                class="ml-2 question-icon"
                icon="question-circle"
              ></b-icon>
            </div>
          </div>
          <div v-if="essays.length > 0 || isFiltered">
            <essays-table
              :essays="essays"
              :fields="$options.TABLE_FIELDS"
              :available-filters="['name', 'rubric']"
              :sortBy="sortBy"
              :sortDesc="sortDesc"
              :tableLoading="tableLoading"
              :total="+count"
              :limit="+limit"
              :current-page="+currentPage"
              :selected-all="selectedAll"
              @selectedAll="selectedAll = $event"
              @onChangePage="(currentPage = $event) && fetchEssays()"
              @move="showMoveEssayModal"
              @delete="deleteSingleEssay"
              @getData="fetchEssays"
            />
          </div>

          <div v-else class="essay-box-empty">
            <h3>Empty...</h3>
            <p>No essays have been graded for this class.</p>
            <b-button variant="info" to="/">Grade new essay</b-button>
          </div>
        </b-col>
      </b-row>
    </section>

    <move-essay-modal
      :essayId="essayToMoveId"
      @essayMoved="rerouteToClasses"
      @moveError="setMoveError"
    />
  </b-container>
</template>
