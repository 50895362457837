var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ isExporting: _vm.isExporting }},[_c('b-overlay',{attrs:{"show":_vm.loading || _vm.innerLoading,"spinner-small":"","rounding":"sm","no-wrap":""},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('b-spinner',{attrs:{"small":"","variant":"info"}}),_c('div',[_vm._v("Generating…")])],1)]},proxy:true}])}),_vm._m(0),(!(_vm.loading || _vm.innerLoading) && _vm.errorsBreakdown.length == 0)?_c('empty-state',{staticClass:"mt-5",attrs:{"title":"No error breakdown found"}},[_c('img',{attrs:{"src":require("../../assets/icons/chart.svg"),"width":"48","alt":"EssayGrader Empty State"}})]):_vm._e(),_c('b-tabs',{staticClass:"error-tabs",attrs:{"no-fade":""}},_vm._l((_vm.errorsBreakdown),function(error){return _c('b-tab',{key:error.errorType,style:({
        '--error-background-color':
          _vm.ERROR_BREAKDOWN_TYPES[error.errorType].color,
      }),attrs:{"title-item-class":`title-size-${error.percentage}`},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{style:({
            '--error-background-color':
              _vm.ERROR_BREAKDOWN_TYPES[error.errorType].color,
          })},[_c('div',{staticClass:"error-tab"}),_c('div',{staticClass:"d-flex justify-content-between virtual-border"},[_c('div',{staticClass:"virtual-border-left"}),_c('div',{staticClass:"virtual-border-right"})])])]},proxy:true}],null,true)},[_c('div',{staticClass:"tab-content p-4"},[_c('div',{staticClass:"d-flex align-items-center mb-2"},[_c('div',{staticClass:"error-type mr-2"}),_c('div',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm.ERROR_BREAKDOWN_TYPES[error.errorType].title)+" ")])]),_c('div',{staticClass:"mb-1",domProps:{"innerHTML":_vm._s(`<strong>Top error example:</strong> ${error.topExample}`)}}),_c('next-steps-box',{attrs:{"title":'Suggestions To Improve',"text":error.improveSuggestion,"isList":false}})],1)])}),1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('h5',{staticClass:"mr-auto"},[_vm._v("Writing report breakdown")])])
}]

export { render, staticRenderFns }