import CellName from "@/components/essay/table/cell_name.vue";

export default (flag) => ({
  name: "FlagUsedAiSuggestions",

  components: {
    CellName,
  },

  data: function () {
    return {
      flag,
    };
  },

  render: function (h) {
    return h("ul", [
      h("li", [
        "There is a ",
        h("b", `${this.flag.summary.aiUsageScore}%`),
        " chance this text was entirely written by AI.",
      ]),
      h("li", [
        h("b", this.flag.summary.sentencesAffected),
        " sentences were likely written by AI.",
      ]),
      h("li", [
        "Burstiness score (the amount of variation in the perplexity of the document) of ",
        h("b", this.flag.summary.burstinessScore),
      ]),
    ]);
  },
});
