<script>
import DashboardCard from "@/views/dashboard/components/dashboard_card.vue";
import CommunityUpdate from "@/components/dashboard/community_updates/rewardful_announcement.vue";
import { mapGetters } from "vuex";
// import DashboardWhatsNew from "@/views/dashboard/components/dashboard_whats_new.vue";

export default {
  name: "DashboardView",

  components: {
    DashboardCard,
    // DashboardWhatsNew,
    CommunityUpdate,
  },

  data() {
    return {
      hasUpgraded: "",
    };
  },

  methods: {
    handleClick(event) {
      console.log(event);
    },
  },

  computed: {
    ...mapGetters("config", ["features"]),
    user() {
      return this.$store.state.user;
    },
    goTo() {
      switch (true) {
        case this.features.createGrade.includes("upload"):
          return "/upload-essays";
        case this.features.createGrade.includes("google"):
          return "/imports/google-classroom";
        case this.features.createGrade.includes("canvas"):
          return "/imports/canvas";
        default:
          return "/upload-essays";
      }
    },
  },

  async mounted() {
    // user was re-routed after upgrading plans
    if (this.$route.query.upgraded || this.$route.query.downgraded) {
      const upgradedPlan = this.$route.query.upgraded;
      const downgradedPlan = this.$route.query.downgraded;
      const plan = upgradedPlan || downgradedPlan;
      this.hasUpgraded = `Congrats, you have successfully ${
        downgradedPlan ? "downgraded" : "upgraded"
      } to the ${plan} plan!`;
    }
  },
};
</script>

<template>
  <div class="welcome-container">
    <b-container>
      <section>
        <b-row>
          <b-col>
            <b-alert v-if="hasUpgraded" variant="success" show>
              {{ hasUpgraded }}
            </b-alert>

            <header class="welcome-header">
              <h1 class="welcome-text">
                Welcome, {{ user?.firstName || user?.email }}
              </h1>
              <p class="welcome-sub-title">What would you like to do?</p>
            </header>
          </b-col>
        </b-row>
        <b-row no-gutters class="action-container">
          <b-col>
            <dashboard-card label="Upload" :to="goTo">
              <template #preview>
                <img
                  src="../../assets/dashboard/grade-student.svg"
                  alt="Rubric Logo"
                />
              </template>
              <template #content>
                <h3>Grade students</h3>
                <p>
                  Grade instantly by uploading or importing your students’
                  essays
                </p>
              </template>
            </dashboard-card>
          </b-col>
          <b-col>
            <dashboard-card to="/rubric/new" label="Create">
              <template #preview>
                <img
                  src="../../assets/dashboard/rubric.svg"
                  alt="Rubric Logo"
                />
              </template>
              <template #content>
                <h3>Create rubric</h3>
                <p>Use your own rubric to grade exactly the way you need</p>
              </template>
            </dashboard-card>
          </b-col>
        </b-row>
      </section>
    </b-container>
    <!--  <dashboard-whats-new />-->
    <section class="community-update-container">
      <CommunityUpdate />
    </section>
  </div>
</template>

<style scoped lang="scss">
.welcome-container {
  padding-top: 62px;
  min-height: calc(100vh - var(--main-header-height));
  background: url("@/assets/welcome-background.svg") top center no-repeat,
    #f8f9fa;
  background-size: 100% auto;
  font-family: Inter;

  .container {
    max-width: 892px;
    margin-bottom: 156px;
  }
  header {
    margin-bottom: 54px;
  }
  header h1 {
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    text-align: center;
    margin: 0 0 4px 0;
  }

  .welcome-sub-title {
    margin: 0;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }
  .action-container {
    gap: 16px;
  }
  @media (max-width: 760px) {
    .container {
      max-width: 492px;
    }
  }
}
.community-update-container {
  max-width: 1110px;
  margin: 0 auto;
}
</style>
