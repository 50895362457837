<script>
export default {
  name: "SubscriptionCancelModal",
  props: {
    cancelSubscription: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      otherText: "",
      loading: false,
    };
  },

  methods: {
    handleClick() {
      if (this.otherText.length === 0) {
        return;
      }

      this.loading = true;

      this.cancelSubscription({
        reasons: [
          {
            reason: "other",
            text: this.otherText,
          },
        ],
      });
    },
  },
};
</script>

<template>
  <b-modal
    id="subscription_cancel_modal"
    title="Thank you for using EssayGrader"
    size="lg"
    ok-variant="danger"
    centered
    no-stacking
    hide-footer
    data-testid="cancel-subscription-modal"
  >
    <p>
      We work hard to make your grading burdens easier and release new features
      every day to meet your needs. Would you mind sharing the reasons you're
      canceling so we can improve? Is there a feature we're missing that you'd
      like us to add?
    </p>

    <b-form-textarea
      v-model="otherText"
      :rows="4"
      max-rows="12"
      placeholder="Your feedback..."
      data-testid="feedback-textarea"
    />

    <div class="d-flex justify-content-end mt-3">
      <b-button
        variant="info"
        @click="() => !loading && handleClick()"
        :disabled="loading || otherText.length === 0"
        data-testid="submit-button"
      >
        <b-spinner v-if="loading" small class="mr-2"></b-spinner>
        Submit
      </b-button>
    </div>
  </b-modal>
</template>
