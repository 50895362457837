<template>
  <div
    class="base-item pb-3 mb-3 position-relative"
    :class="{ 'item-active': activeHighlightIndex === index }"
    @click="!isEdit && $emit('updateActiveHighlightIndex', index)"
  >
    <div class="actions d-flex align-items-center justify-content-end mb-2">
      <!-- h3 for semantic, but should looks like h5 -->
      <h3 class="h5">
        <slot name="title">
          <span>{{ formatTitle(item) }}</span>
        </slot>
      </h3>

      <div
        v-if="isEdit && isRealItem && item?.criteria?.maxGrade"
        class="ml-auto mr-3"
      >
        <div class="d-flex align-items-center">
          <div class="mr-2">{{ newGrade }}</div>
          <b-form-input
            v-model="newGrade"
            type="range"
            min="0"
            :max="item.criteria.maxGrade"
            step="0.1"
          ></b-form-input>
          <div class="ml-2">{{ item.criteria.maxGrade }}</div>
        </div>
      </div>

      <div
        v-if="!specialForCopyPaste"
        class="d-flex"
        :class="{ 'ml-auto': !(isEdit && isRealItem) }"
      >
        <b-button
          v-if="$listeners.update"
          variant="outline-info"
          size="sm"
          class="ml-auto mr-2"
          @click="() => (isEdit ? cancel() : edit())"
        >
          {{ isEdit ? "Cancel" : "Edit" }}
        </b-button>
        <b-button
          v-if="(index < 0 && isEdit) || ($listeners.delete && isRealItem)"
          size="sm"
          variant="outline-info"
          @click="() => (isEdit ? save() : deleteItem())"
        >
          {{ isEdit ? "Save" : "Delete" }}
        </b-button>
      </div>
    </div>

    <div class="content">
      <div v-if="isEdit">
        <b-form-textarea
          v-model="newAnalysisText"
          placeholder="Enter analysis..."
          rows="2"
          max-rows="4"
        />
      </div>

      <slot v-else name="content" :item="item" :index="index">
        <div class="d-flex">
          <eg-svg-text
            v-if="isRealItem"
            class="index flex-shrink-0 mr-1"
            :text="index + 1"
            :size="10"
          />
          <div
            v-if="typeof item.analysis === 'string'"
            v-html="item.analysis"
          />
          <component v-else :is="item.analysis"></component>
        </div>
      </slot>

      <div v-if="item[nextStepsKey]">
        <div v-if="isEdit" class="mt-2">
          <b-form-textarea
            v-model="newNextText"
            placeholder="Enter next steps..."
            rows="2"
            max-rows="4"
          />
        </div>

        <!-- TODO: bad practice to compare against the title, change this -->
        <next-steps-box
          v-else
          :title="nextStepTitle"
          :text="item[nextStepsKey]"
          :isList="item.isList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EgSvgText from "@/components/global/svgText/eg_svg_text.vue";
import NextStepsBox from "@/components/essay/essay/v3/next_steps_box.vue";
import deleteConfirmationModalMixin from "@/mixins/deleteConfirmationModal";

export default {
  components: {
    NextStepsBox,
    EgSvgText,
  },

  inject: ["specialForCopyPaste"],

  // this.showDeleteConfirmationModal
  mixins: [deleteConfirmationModalMixin],

  props: {
    item: {
      type: Object,
      required: true,
    },

    activeHighlightIndex: {
      type: Number,
      default: -1,
    },

    index: {
      type: Number,
      required: true,
    },

    formatTitle: {
      type: Function,
      default: (item) => item.title || "",
    },

    nextStepsKey: {
      type: String,
      default: "suggestions",
    },

    nextStepTitle: {
      type: String,
      default: "Suggestions:",
    },
  },

  data() {
    return {
      isEdit: false,
      newAnalysisText: "",
      newNextText: "",
      newGrade: 0,
    };
  },

  computed: {
    isRealItem() {
      return this.index >= 0;
    },
  },

  methods: {
    edit() {
      this.isEdit = true;
      this.newAnalysisText = this.item.analysis;
      this.newNextText = this.item.suggestions;
      this.newGrade = this.item.grade;
    },

    cancel() {
      this.isEdit = false;
      this.newAnalysisText = "";
      this.newNextText = "";
      this.newGrade = "";
    },

    save() {
      const data = {
        itemId: this.item.id,
        analysis: this.newAnalysisText,
        suggestions: this.newNextText,
        grade: Number(this.newGrade),
      };

      if (this.newGrade) {
        data.grade = Number(this.newGrade);
      }

      this.$emit("update", {
        data,
        resolve: () => {
          this.cancel();
        },
      });
    },

    async deleteItem() {
      const canDelete = await this.showDeleteConfirmationModal({
        title: "Are you sure you want to delete this section?",
        message:
          "This action cannot be undone, but you can regrade the submission to regenerate the feedback.",
      });

      if (!canDelete) return;

      this.$emit("delete", { itemId: this.item.id });
    },
  },
};
</script>

<style lang="scss" scoped>
.item-active {
  h5 {
    background: #fffbd9;
    margin-left: -7px;
    padding-left: 7px;
    padding-right: 7px;
    display: inline-block;
  }
}

h5 {
  cursor: pointer;
}

.content {
  cursor: pointer;
}

.index {
  color: var(--Text-Secondary, #6c757d);
  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
}

.base-item {
  border-bottom: 1px solid #dfdfdf;
}

input[type="range"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 100%;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border-radius: 7px;
}

/* Track: webkit browsers */
input[type="range"]::-webkit-slider-runnable-track {
  height: 8px;
  background: #f3e8fc;
  border-radius: 7px;
}

/* Track: Mozilla Firefox */
input[type="range"]::-moz-range-track {
  height: 8px;
  background: #f3e8fc;
  border-radius: 7px;
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  background-color: #714bd4;
  border-radius: 50%;
  border: 1px solid;
  border-color: #714bd4;
}

/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  background-color: #714bd4;
  border-radius: 50%;
  border: 1px solid;
  border-color: #714bd4;
}
</style>
