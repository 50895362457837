<template>
  <ul class="nav start-grading-selector mb-3">
    <li class="nav-item" v-if="features.createGrade.includes('upload')">
      <b-button
        to="/upload-essays"
        class="nav-link"
        :class="{ active: $route.name === 'upload-essays' }"
        type="button"
      >
        <b-icon icon="folder-fill" />
        Add manually
      </b-button>
    </li>
    <li class="nav-item" v-if="features.createGrade.includes('google')">
      <b-button
        to="/imports/google-classroom"
        class="nav-link"
        type="button"
        :class="{ active: $route.name === 'imports-google-classroom' }"
      >
        <img src="@/assets/icons/google-classroom.svg" alt="Google Classroom" />
        Import Google classroom
      </b-button>
    </li>
    <li class="nav-item" v-if="features.createGrade.includes('canvas')">
      <b-button
        to="/imports/canvas"
        class="nav-link"
        type="button"
        :class="{ active: $route.name === 'imports-canvas' }"
      >
        <img src="@/assets/icons/canvas.svg" alt="Canvas" />
        Import Canvas
      </b-button>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "StartGradingSelector",
  computed: {
    ...mapGetters("config", ["features"]),
  },
};
</script>

<style scoped lang="scss">
.start-grading-selector {
  display: flex;
  gap: 16px;
  .nav-item {
    display: flex;
    width: 255px;
  }
  .btn-secondary:not(.disabled) {
    display: flex;
    max-width: 255px;
    min-width: 100%;
    height: 56px;
    gap: 8px;
    align-items: center;
    background-color: var(--grey-100);
    border-color: var(--grey-300);
    padding: 16px;
    img {
      width: 24px;
      height: 24px;
    }
    &:focus {
      box-shadow: 0 0 0 0.2rem var(--purple-100);
    }

    &:active:focus,
    &:active {
      background-color: var(--purple-100);
      box-shadow: 0 0 0 0.2rem var(--purple-100);
    }
  }
  .btn-secondary:not(:disabled).active {
    background-color: var(--purple-100);
    border-color: var(--purple-600);
  }
}
</style>
