<template>
  <div class="upload-essay-view">
    <b-container>
      <section>
        <div>
          <b-row>
            <b-col>
              <b-alert v-if="error" variant="danger" show>{{ error }}</b-alert>
              <StartGradingContainer>
                <essay-submission-form
                  ref="form"
                  :selected-rubric="selectedRubric"
                  @essaySubmitted="getFeedback"
                  @selectRubric="
                    selectedRubric = $event;
                    selectRubric($event);
                  "
                  :submitting="submitting"
                />
              </StartGradingContainer>
            </b-col>
          </b-row>
        </div>
      </section>
    </b-container>
    <rubric-library
      v-model="selectedRubric"
      @input="selectRubric"
      show-add-new
    />
  </div>
</template>

<script>
import leaveMixin from "@/mixins/leaveMixin";
import EssaySubmissionForm from "@/components/dashboard/essay_submission_form.vue";
import StartGradingContainer from "@/components/start-grading/start_grading_container.vue";

import RubricLibrary from "@/components/rubrics/rubric_library.vue";
import { feedback } from "@/api";
import { LSK_SUGGESTION_FORM_SELECTED_RUBRIC } from "@/constants";

export default {
  name: "UploadEssaysView",

  components: {
    EssaySubmissionForm,
    RubricLibrary,
    StartGradingContainer,
  },

  mixins: [leaveMixin],

  data() {
    return {
      error: "",
      hasUpgraded: "",
      submitting: false,
      selectedRubric: JSON.parse(
        localStorage.getItem(LSK_SUGGESTION_FORM_SELECTED_RUBRIC)
      ),
    };
  },

  computed: {
    featureFlags() {
      return this.$store.state.featureFlags;
    },
  },

  methods: {
    selectRubric(rubric) {
      localStorage.setItem(
        LSK_SUGGESTION_FORM_SELECTED_RUBRIC,
        JSON.stringify(rubric)
      );
    },
    // override for leave guard
    getIsDirty() {
      const isDirty = this.$refs.form.isDirty;

      return isDirty === undefined ? true : isDirty;
    },

    async getFeedback(payload) {
      try {
        this.submitting = true;
        await feedback(payload);
        this.submitting = false;

        this.error = "";
        // always ignore leave guard
        this.forceIsDirty = false;
        this.$router.push("/essays");
      } catch (error) {
        this.submitting = false;
        // error handled on the interceptors
        return;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.upload-essay-view {
  min-height: 100vh;
  background-color: var(--grey-100);
}
</style>
