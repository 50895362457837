const getConfigForSubdomain = (subdomain) => {
  switch (subdomain) {
    case "tot":
    case "tot-beta":
    case "egcanvas":
      return {
        createGrade: ["upload", "canvas"],
        login: ["canvas"],
        bulkImports: true,
      };
    default:
      return {
        createGrade: ["upload", "google", "canvas"],
        login: ["native", "google"],
        bulkImports: false,
      };
  }
};

export default {
  namespaced: true,
  state: () => ({
    features: {
      createGrade: ["upload", "google", "canvas"],
      login: ["native", "google"],
      bulkImports: false,
    },
  }),
  mutations: {
    SET_FEATURES(state, features) {
      state.features = features;
    },
  },
  actions: {
    initConfig({ commit }, forceConfigName = "") {
      const envSubdomain = process.env.VUE_APP_FORCE_SUBDOMAIN;

      if (envSubdomain) {
        commit("SET_FEATURES", getConfigForSubdomain(envSubdomain));
        return;
      }

      const subdomains = window.location.host
        .replace("www.", "")
        .replace(".com", "")
        .split(".");

      if (forceConfigName || subdomains.length > 2) {
        commit(
          "SET_FEATURES",
          getConfigForSubdomain(forceConfigName || subdomains[0])
        );
      }
    },
  },
  getters: {
    features: (state) => state.features,
  },
};
