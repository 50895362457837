<template>
  <div>
    <b-overlay
      :show="loading || innerLoading"
      spinner-small
      rounding="sm"
      no-wrap
    >
      <template #overlay>
        <div class="d-flex flex-column align-items-center">
          <b-spinner small variant="info" />
          <div>Generating…</div>
        </div>
      </template>
    </b-overlay>

    <div class="d-flex align-items-center">
      <h5 class="mr-auto">Recommendations to improve lesson plan</h5>
    </div>

    <empty-state
      v-if="
        !(loading || innerLoading) &&
        !(recommendations.focusOn && recommendations.spendLessTimeOn)
      "
      class="mt-5"
      title="No recommendations found"
    >
      <img
        src="../../assets/icons/chart.svg"
        width="48"
        alt="EssayGrader Empty State"
      />
    </empty-state>

    <div class="mt-3" v-if="recommendations?.focusOn">
      <div class="d-flex align-items-center">
        <img class="mr-2" src="../../assets/icons/bullseye-arrow.svg" alt="" />
        <h5 class="subtitle">Focus on...</h5>
      </div>
      <recommendation-improve-block :items="recommendations?.focusOn" />
    </div>

    <div class="mt-3" v-if="recommendations?.spendLessTimeOn">
      <div class="d-flex align-items-center">
        <img class="mr-2" src="../../assets/icons/party.svg" alt="" />
        <h5 class="subtitle green">Spend less time on...</h5>
      </div>
      <recommendation-improve-block :items="recommendations?.spendLessTimeOn" />
    </div>
  </div>
</template>

<script>
import { getRecommendations } from "@/api";
import EmptyState from "@/components/global/empty_state.vue";
import RecommendationImproveBlock from "./recommendation_improve_block";
import { recommendations } from "./example_data";

export default {
  components: {
    RecommendationImproveBlock,
    EmptyState,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    classId: {
      type: Number,
    },
    assignmentId: {
      type: Number,
    },
    studentId: {
      type: Number,
    },
  },

  data() {
    return {
      innerLoading: false,
      recommendations: {},
    };
  },

  watch: {
    classId() {
      this.init();
    },

    assignmentId() {
      this.init();
    },

    studentId() {
      this.init();
    },
  },

  created() {
    this.init();
  },

  methods: {
    async init() {
      if (!this.classId && !this.assignmentId && !this.studentId) {
        return;
      }

      this.recommendations = recommendations;
      this.innerLoading = true;

      const { data } = await getRecommendations(
        this.classId,
        this.assignmentId
      );

      this.recommendations = data.recommendations;
      this.innerLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  color: #714bd4;
  font-family: Inter;
  margin-bottom: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;

  &.green {
    color: #318c46;
  }
}
</style>
