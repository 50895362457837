<template>
  <b-dropdown
    ref="filterDropdowns"
    class="essay-actions-dropdown"
    :class="{ active }"
    :disabled="disabled"
    no-caret
    :right="right"
    variant="outline-secondary"
    size="sm"
  >
    <template #button-content>
      <slot name="button-content">
        <div class="d-flex align-items-center">
          {{ buttonLabel }}
          <b-icon class="fs-10 ml-1" icon="caret-down-fill"></b-icon>
        </div>
      </slot>
    </template>
    <div class="filter-content-wrapper">
      <div class="d-flex align-items-center mb-3">
        <div>
          {{ title }}
        </div>
        <div class="ml-auto">
          <b-button size="sm" class="ml-1" variant="link" @click="applyFilter">
            <b-icon variant="info" :icon="'check2'"></b-icon>
          </b-button>
          <b-button
            size="sm"
            class="ml-1 text-dark"
            variant="link"
            @click="deleteFilter"
          >
            <b-icon icon="trash"></b-icon>
          </b-button>
        </div>
      </div>
      <slot :reset-signal="resetSignal" />
    </div>
  </b-dropdown>
</template>

<script>
export default {
  name: "FilterDropdown",

  props: {
    buttonLabel: {
      type: String,
    },

    title: {
      type: String,
    },

    active: {
      type: Boolean,
      default: false,
    },

    right: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      resetSignal: 0,
    };
  },

  methods: {
    closeDropdown() {
      this.$refs.filterDropdowns.hide();
    },

    applyFilter() {
      this.$emit("apply");
      this.closeDropdown();
    },

    deleteFilter() {
      this.$emit("delete");
      this.resetSignal++;
      this.closeDropdown();
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-content-wrapper {
  padding: 18px 23px;
  min-width: 340px;
  width: max-content;
}

.essay-actions-dropdown::v-deep .btn:focus {
  box-shadow: none;
  text-decoration: none;
}

.essay-actions-dropdown.active::v-deep > .btn {
  color: hsla(257, 61%, 56%, 1);
  border-color: hsla(257, 61%, 56%, 1);
}

.export-dropdown {
  border-radius: 4px;
  background: var(--brand-secondary, #f3e8fc);

  &::v-deep {
    .btn {
      border-color: transparent;
    }
  }
}
</style>
