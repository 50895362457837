<template>
  <div class="comment position-relative d-flex py-3 gap-3">
    <div class="w-100 pl-3">
      <div v-if="isEdit" class="text mt-2">
        <b-form-textarea
          v-model="newCommentText"
          placeholder="Enter comment..."
          rows="2"
          max-rows="4"
        />
      </div>
      <div v-else class="d-flex">
        <div class="index mr-2">{{ index + 1 }}</div>
        <div>
          <div class="text mb-2">{{ comment.text }}</div>
          <div class="info-text">
            <span>Commented by </span>
            <b>{{ comment.author.firstName }} {{ comment.author.lastName }}</b>
            at
            {{ date }}
          </div>
        </div>
      </div>
    </div>

    <div class="flex-none pr-3">
      <div v-if="!isEdit" class="d-flex">
        <b-button
          variant="outline-info"
          size="sm"
          class="ml-auto mr-2"
          @click="
            () => {
              isEdit = true;
              newCommentText = comment.text;
            }
          "
        >
          Edit
        </b-button>
        <b-button
          size="sm"
          variant="outline-info"
          @click="$emit('delete', comment)"
        >
          Delete
        </b-button>
      </div>
      <div v-else class="d-flex mt-2">
        <b-button
          variant="outline-info"
          size="sm"
          class="ml-auto mr-2"
          @click="isEdit = false"
        >
          Cancel
        </b-button>
        <b-button
          size="sm"
          variant="info"
          @click="
            () => {
              isEdit = false;
              isLoading = true;
              $emit('update', { commentId: comment.id, text: newCommentText });
            }
          "
        >
          Save
        </b-button>
      </div>
    </div>
    <b-overlay :show="isLoading" no-wrap> </b-overlay>
  </div>
</template>

<script>
import formatDate from "@/utils/formatDate";

export default {
  props: {
    index: {
      type: Number,
      required: true,
    },

    comment: {
      type: Object,
      required: true,
    },

    commentLoading: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    commentLoading() {
      // commentLoading can only turnoff inner loading status (for easy manage loading status each comment)
      if (!this.commentLoading) {
        this.isLoading = false;
      }
    },
  },

  data() {
    return {
      isLoading: false,
      isActive: false,
      newCommentText: this.comment.text || "",
      isEdit: false,
    };
  },

  computed: {
    date() {
      return formatDate(this.comment.createdAt);
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-none {
  flex: none;
}
.title {
  color: var(--Text-Secondary, #6c757d);
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  padding-bottom: 8px;
  border-bottom: 1px solid #dfdfdf;

  .name {
    font-weight: 700;
  }
}

.index {
  color: var(--Text-Secondary, #6c757d);
  font-family: Inter;
  font-size: 10px;
  font-weight: 700;
  line-height: 16px;
}

.comment {
  cursor: pointer;
  gap: 10px;

  &:not(:nth-child(1)) {
    border-top: 1px solid #dfdfdf;
  }

  &:hover {
    background-color: var(--Text-Light, #f8f9fa);
  }
}

.text {
  color: var(--Text-Body, #212529);
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.info-text {
  color: var(--Text-Secondary, #6c757d);
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  b {
    color: var(--Text-Secondary, #6c757d);
  }
}
</style>
