<template>
  <b-form-group>
    <template #label>
      <div>
        Grading intensity
        <span class="red-text" v-if="required">*</span>
        <p class="text-muted small mb-0">
          The level that AI will grade essays at using this rubric. The
          difficulty level can be overridden when grading essays, if necessary.
        </p>
      </div>
    </template>
    <div :class="horizontal ? 'd-flex' : 'd-block'">
      <ToggleButton
        v-for="radioOption in radioOptions"
        :key="radioOption.value"
        :class="horizontal && 'mr-3 btn d-flex align-center'"
        :horizontal="true"
        :value="radioOption.value"
        :label="radioOption.label"
        @click="handleChange(radioOption.value)"
        :pressed="value === radioOption.value"
      >
        <span :style="{ color: radioOption.color, fontSize: '11px' }">
          <b-icon :icon="radioOption.icon" />
        </span>
      </ToggleButton>
    </div>
  </b-form-group>
</template>

<script>
import ToggleButton from "@/components/global/eg_toggle_button.vue";
import { INTENSITIES } from "@/constants";
export default {
  props: {
    value: {
      type: String,
      default: "moderate",
    },
    horizontal: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  components: { ToggleButton },
  data() {
    return {
      radioOptions: INTENSITIES,
    };
  },
  methods: {
    handleChange(valueId) {
      this.$emit("input", valueId);
    },
  },
};
</script>
