import openFeatureModalIfNeeded from "@/components/global/globalModals/openFeatureModalIfNeeded";
import { createRubric, getUser } from "@/api";
import initCrisp from "@/init/crisp";
import { initCanny } from "@/init/canny";
import {
  LSK_SUGGESTION_FORM_SELECTED_ASSIGNMENT,
  LSK_SUGGESTION_FORM_SELECTED_CLASS,
  LSK_SUGGESTION_FORM_SELECTED_RUBRIC,
  LSK_SUGGESTION_FORM_SHOW_ADDITIONAL_GRADING_OPTIONS,
} from "@/constants";

export default {
  inject: ["growthBook"],
  methods: {
    resetLocalStorageSavedFormData() {
      [
        LSK_SUGGESTION_FORM_SELECTED_ASSIGNMENT,
        LSK_SUGGESTION_FORM_SELECTED_CLASS,
        LSK_SUGGESTION_FORM_SELECTED_RUBRIC,
        LSK_SUGGESTION_FORM_SHOW_ADDITIONAL_GRADING_OPTIONS,
      ].forEach((key) => localStorage.removeItem(key));
    },
    async redirectToRoute(vm, user) {
      this.resetLocalStorageSavedFormData();
      this.growthBook?.setAttributes({ email: user?.email });

      const { data } = await getUser("me");
      await this.$store.dispatch("setUser", data);
      initCrisp(data);
      initCanny(data);

      const newRubricData = localStorage.getItem("share:newRubricData");
      const useRubricData = localStorage.getItem("share:useRubricData");

      if (newRubricData) {
        localStorage.removeItem("share:newRubricData");
        const rubricData = JSON.parse(newRubricData);
        const { data } = await createRubric(rubricData);
        await this.$router.push(`/rubric/edit/${data.id}`);
      } else if (useRubricData) {
        localStorage.removeItem("share:useRubricData");
        localStorage.setItem(
          LSK_SUGGESTION_FORM_SELECTED_RUBRIC,
          useRubricData
        );
        await this.$router.push({ path: "/upload-essays" });
      } else {
        await vm.$router.replace({ name: "dashboard" });
      }

      openFeatureModalIfNeeded(vm);
    },
  },
};
