<template>
  <b-container>
    <section class="p-3">
      <b-row v-if="!premiumPlan">
        <b-col md="12">
          <h1>Free Premium Trial</h1>
          <hr />
          <p>
            Enjoy a free trial of our most powerful plan while you evaluate
            EssayGrader.
          </p>
          <p>
            Use Coupon "ENTERPRISETRIAL" on checkout to activate your free
            trial.
          </p>
          <p>Thank you for considering EssayGrader!</p>

          <b-button variant="info" @click="getFreeTrial">
            Get 3 months Premium free
          </b-button>
        </b-col>
      </b-row>
    </section>
  </b-container>
</template>

<script>
import { checkout } from "@/api";

export default {
  methods: {
    async getFreeTrial() {
      const session = await checkout({
        plan: "premium",
        yearly: false,
        trial: true,
      });

      window.location.href = session.data.url;
    },
  },
};
</script>
