export const errorsBreakdown = [
  {
    errorType: "grammar",
    topExample: "This lack of coherence resulted in a significantly low score.",
    improveSuggestion:
      "Consider rewriting to avoid passive voice and enhance directness.",
    percentage: 12.5,
  },
  {
    errorType: "sentenceStructure",
    topExample: "Each criterion received a lower mark.",
    improveSuggestion: "Break it into two sentences for clarity.",
    percentage: 12.5,
  },
  {
    errorType: "coherenceGaps",
    topExample:
      "The essay lacks focus, content, organization, style, and appropriate language use.",
    improveSuggestion:
      "Provide specific examples from the essay to demonstrate these issues.",
    percentage: 25,
  },
  {
    errorType: "clarityIssues",
    topExample:
      "The essay needs improvement in clarity, argument development, and logical flow.",
    improveSuggestion: "Rephrase vague statements to be more specific.",
    percentage: 37.5,
  },
  {
    errorType: "logicalInconsistencies",
    topExample: "In paragraph two, the argument was contradictory.",
    improveSuggestion:
      "Provide specific examples from the essay to substantiate claims.",
    percentage: 12.5,
  },
];

export const recommendations = {
  focusOn: [
    {
      title: "Sentence Structure Improvement",
      summary:
        "The sentence structure in the essays is weak due to a lack of variety and complexity. Many sentences are simple and repetitive, affecting the flow of the writing.",
      recommendations: [
        "Experiment with varying sentence lengths and types.",
        "Incorporate transitional phrases to enhance coherence.",
        "Use compound and complex structures to deepen the analysis.",
        "Focus on precise vocabulary and formal tone.",
      ],
    },
  ],
  spendLessTimeOn: [
    {
      title: "Repetitive Analysis",
      summary:
        "Grading suggestions repeat similar points regarding sentence structure and grammatical issues across multiple feedbacks. Focus could be streamlined.",
    },
  ],
};
