<template>
  <div class="comment position-relative">
    <div class="title">
      <div class="name">Add comment</div>
    </div>

    <div class="text mt-2">
      <b-form-textarea
        v-model="newCommentText"
        placeholder="Enter comment..."
        rows="2"
        max-rows="4"
      />
    </div>

    <div class="d-flex mt-2">
      <b-button
        variant="outline-info"
        size="sm"
        class="ml-auto mr-2"
        @click="$emit('close')"
      >
        Cancel
      </b-button>
      <b-button size="sm" variant="info" @click="$emit('save', newCommentText)">
        Save
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newCommentText: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: var(--Text-Secondary, #6c757d);
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  padding-bottom: 8px;
  border-bottom: 1px solid #dfdfdf;

  .name {
    font-weight: 700;
  }
}

.text {
  color: var(--Text-Body, #212529);
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  textarea {
    overflow-y: auto !important;
  }
}
</style>
