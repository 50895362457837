<template>
  <b-form-group>
    <template #label>Custom instructions</template>
    <div class="position-relative">
      <b-form-textarea
        v-model="localValue"
        :rows="10"
        :max-rows="24"
        class="custom-textarea"
        :placeholder="placeholder"
        @keydown="handleKeydown"
        @paste="handlePaste"
      />
      <div class="word-counter">
        {{ remainingWords }}
        words
      </div>
    </div>
  </b-form-group>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default:
        "You can leave instructions for AI to make our analysis as close as possible to your real feedback",
    },
    maxWords: {
      type: Number,
      default: 1000,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  computed: {
    wordCount() {
      return this.countWords(this.localValue);
    },
    remainingWords() {
      return Math.max(this.maxWords - this.wordCount, 0);
    },
  },
  watch: {
    value(newValue) {
      this.localValue = newValue;
    },
    localValue(newValue) {
      const words = this.splitIntoWords(newValue);

      if (words.length > this.maxWords) {
        const trimmedText = this.trimToMaxWords(newValue, this.maxWords);
        this.localValue = this.ensureEndsWithSpace(trimmedText);
      }

      this.$emit("input", this.localValue);
    },
  },
  methods: {
    handleKeydown(event) {
      if (
        ["Backspace", "Delete", "ArrowLeft", "ArrowRight"].includes(event.key)
      ) {
        return;
      }

      if (this.wordCount === this.maxWords && event.code === "Space") {
        event.preventDefault();
      }
    },
    handlePaste(event) {
      event.preventDefault();
      const pastedText = (event.clipboardData || window.clipboardData).getData(
        "text"
      );

      const combinedText = this.combineText(this.localValue, pastedText);
      const words = this.splitIntoWords(combinedText);

      if (words.length > this.maxWords) {
        const trimmedText = this.trimToMaxWords(combinedText, this.maxWords);
        this.localValue = this.ensureEndsWithSpace(trimmedText);
      } else {
        this.localValue = combinedText;
      }
    },
    trimToMaxWords(text, maxWords) {
      let wordCount = 0;
      let result = "";

      for (const line of text.split("\n")) {
        const wordsInLine = this.splitIntoWords(line);

        if (wordCount + wordsInLine.length <= maxWords) {
          result += line + "\n";
          wordCount += wordsInLine.length;
        } else {
          const remainingWords = maxWords - wordCount;
          result += wordsInLine.slice(0, remainingWords).join(" ");
          break;
        }
      }

      return result.trim();
    },
    countWords(text) {
      return text
        .trim()
        .split(/\s+/)
        .filter((word) => word.trim().length > 0).length;
    },
    splitIntoWords(text) {
      return text.split(/\s+/).filter((word) => word.trim().length > 0);
    },
    combineText(currentText, newText) {
      return (currentText + "\n" + newText).replace(/\r?\n/g, "\n");
    },
    ensureEndsWithSpace(text) {
      return text.endsWith(" ") ? text : text + " ";
    },
  },
};
</script>

<style scoped lang="scss">
.custom-textarea {
  padding-bottom: 2rem;
}

.word-counter {
  position: absolute;
  bottom: 5px;
  right: 24px;
  font-size: 0.9rem;
  color: #6c757d;
}
</style>
