<template>
  <div class="feedback-box content flags-section">
    <b-overlay
      :show="flagsManuallyLoading"
      variant="white"
      :opacity="1"
      no-fade
    >
      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="arrow-clockwise"
            variant="info"
            font-scale="7"
            animation="spin"
          ></b-icon>
          <h5 class="mt-2">Running detection reports...</h5>
        </div>
      </template>
      <flags-empty
        v-if="!flags.length"
        @clickFlagsManually="onClickFlagsManually"
      />

      <div v-else>
        <template v-if="isV3">
          <flag-close-to-grade-v3
            v-if="v3Flags.closeToGrade"
            :flag="v3Flags.closeToGrade"
            class="flag-wrapper pb-4 mb-4"
          />

          <grammar-flags-base-items
            :grouped-items="v3Flags.groupedItemsFlags"
            :types="types"
            :is-no-ranges="isNoRanges"
            :activeHighlightIndex="activeHighlightIndex"
            collapsable
            @updateActiveHighlightIndex="
              $emit('updateActiveHighlightIndex', $event)
            "
            @updateHighlights="$emit('updateHighlights', $event)"
          >
            <template #additionalContentBeforeList="{ type }">
              <base-item
                :item="getAdditionalContentItem(type)"
                :index="-2"
                next-step-title="Pro tip:"
              />
            </template>
          </grammar-flags-base-items>
        </template>

        <template v-else>
          <template v-for="(flag, index) in flags">
            <component
              :is="getComponent(flag)"
              :flag="flag"
              :key="index"
              class="flag-wrapper pb-4 mb-4"
            />
          </template>
        </template>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import Vue from "vue";
import { createFlagsManually } from "@/api";
import FlagsEmpty from "@/components/essay/essay/flags/v2/flags_empty.vue";
import FlagCloseToGrade from "@/components/essay/essay/flags/v2/flag_close_to_grade.vue";
import FlagUsedAi from "@/components/essay/essay/flags/v2/flag_used_ai.vue";
import FlagPlagiarism from "@/components/essay/essay/flags/v2/flag_plagiarism.vue";
import FlagCloseToGradeV3 from "@/components/essay/essay/flags/v3/flag_close_to_grade_v3.vue";
import GrammarFlagsBaseItems from "@/components/essay/essay/v3/grammar_flags_base_items.vue";
import BaseItem from "@/components/essay/essay/v3/base_item.vue";
import factoryFlagUsedAiAnalysis from "@/components/essay/essay/flags/v3/flag_used_ai_analysis";
import factoryFlagUsedAiSuggestions from "@/components/essay/essay/flags/v3/flag_used_ai_suggestions";
import factoryFlagPlagiarismAnalysis from "@/components/essay/essay/flags/v3/flag_plagiarism_analysis";
import factoryFlagPlagiarismSuggestions from "@/components/essay/essay/flags/v3/flag_plagiarism_suggestions";

const TYPES = {
  closeToGrade: {
    componentV2: FlagCloseToGrade,
  },
  usedAI: {
    componentV2: FlagUsedAi,
    color: "#027BFF",
    background: "#E6F2FF",
    title: "AI Detection Results",
    analysis: (flag) => Vue.extend(factoryFlagUsedAiAnalysis(flag)),
    suggestions: (flag) => Vue.extend(factoryFlagUsedAiSuggestions(flag)),
  },
  plagiarism: {
    componentV2: FlagPlagiarism,
    color: "#CE316C",
    background: "#FFEAEC",
    title: "Plagiarism Detection Results",
    analysis: (flag) => Vue.extend(factoryFlagPlagiarismAnalysis(flag)),
    suggestions: (flag) => Vue.extend(factoryFlagPlagiarismSuggestions(flag)),
  },
};

export default {
  components: {
    FlagsEmpty,
    FlagCloseToGrade,
    FlagUsedAi,
    FlagPlagiarism,
    FlagCloseToGradeV3,
    GrammarFlagsBaseItems,
    BaseItem,
  },

  props: {
    essay: {
      type: Object,
      required: true,
    },

    activeHighlightIndex: {
      type: Number,
      default: -1,
    },
  },

  data() {
    return {
      types: TYPES,
      flagsManuallyLoading: false,
    };
  },

  computed: {
    flags() {
      return this.essay.flags || [];
    },

    flagsCount() {
      return this.flags.length;
    },

    isNoRanges() {
      return (this.essay?.tags || []).includes("no-ranges");
    },

    proPlan() {
      return this.$store.getters.proPlan;
    },

    premiumPlan() {
      return this.$store.getters.premiumPlan;
    },

    isV3() {
      const isV3 = (flag) => (flag?.tags || []).includes("v3");

      return this.flags.some(isV3);
    },

    v3Flags() {
      return this.flags.reduce(
        (acc, flag) => {
          if (flag.type === "closeToGrade") {
            acc.closeToGrade = flag;

            return acc;
          }

          acc.groupedItemsFlags[flag.type] = flag.ranges
            ? flag.ranges.map((range, index) => ({
                id: flag.type + index,
                type: flag.type,
                analysis: this.essay.content.slice(range[0], range[1]),
                ranges: [range],
              }))
            : [];

          return acc;
        },
        {
          closeToGrade: null,
          groupedItemsFlags: {},
        }
      );
    },
  },

  methods: {
    getAdditionalContentItem(type) {
      const flag = this.essay.flags.find((f) => f.type === type);

      return {
        analysis: TYPES[type].analysis(flag),
        suggestions: TYPES[type].suggestions(flag),
      };
    },

    getComponent(flag) {
      return TYPES[flag.type].componentV2;
    },

    async onClickFlagsManually() {
      if (!(this.proPlan || this.premiumPlan)) {
        this.$bvModal.show("upgrade-modal");

        return;
      }

      this.flagsManuallyLoading = true;

      const {
        data: { flags },
      } = await createFlagsManually(this.essay.id);

      this.$emit("updateEssay", { ...this.essay, flags });
      this.flagsManuallyLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.flags-section {
  white-space: normal;
}

.feedback-box {
  white-space: pre-wrap;
  padding: 25px;
  border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  height: 100%;
}

.feedback-box.content,
.feedback-box.ql-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
  padding-top: 0px;
}

.feedback-box.content {
  padding-top: 43.5px;
}

.flag-wrapper {
  //   // TODO: Temporarily hide the border as for normal users they only see 1 flag.
  /* border-bottom: 1px solid #dfdfdf; */

  &:v-deep {
    .flag-title {
      color: var(--Text-Body, #212529);
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
    }
  }
}
</style>
