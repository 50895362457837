<template>
  <b-modal
    id="new-features-modal"
    :title="`✨ New Features`"
    size="lg"
    centered
    scrollable
    @show="showContent = true"
  >
    <template #modal-footer="{ close }">
      <div>
        <b-button variant="info" @click="close">Close</b-button>
      </div>
    </template>
    <div>
      <div class="text">
        <new-feature-content v-if="showContent" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import openFeatureModalIfNeeded from "@/components/global/globalModals/openFeatureModalIfNeeded";
import NewFeatureContent from "@/components/global/new_feature_content.vue";

export default {
  data() {
    return {
      showContent: false,
    };
  },

  components: { NewFeatureContent },

  mounted() {
    openFeatureModalIfNeeded(this);
  },
};
</script>

<style lang="scss" scoped>
::v-deep #new-features-modal .modal-dialog {
  max-height: calc(100% - 150px);
  margin-top: 75px;
  margin-bottom: 75px;
  min-height: calc(100% - 150px);
}

.feature-heading {
  width: 90%;
}

.image {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.type {
  position: absolute;
  top: 22px;
  right: 20px;
}

.type-069e6f95ddb552976243120f7aba1a88 {
  background-color: var(--info);
  color: var(--white);
}
.type-3a7c836ddc3ef38e19e4b12b31a8a96a {
  background-color: var(--teal);
  color: var(--white);
}

.date {
  font-size: 12px;
  color: var(--gray);
}
</style>
