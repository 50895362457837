<script>
import upgradePlanMixin from "@/mixins/upgradePlan";
import trackAndRedirectToUpgrade from "@/mixins/trackAndRedirectToUpgrade";

export default {
  name: "GlobalNavbar",
  mixins: [
    /*
    this.upgradeHandler
    */
    upgradePlanMixin,
    trackAndRedirectToUpgrade,
  ],
  data() {
    return {
      coupon: "20DISC",
    };
  },
  computed: {
    emptyNavbar() {
      return !this.$store.state.loggedIn;
    },
    basicPlan() {
      return this.$store.getters.basicPlan;
    },
    litePlan() {
      return this.$store.getters.litePlan;
    },
    manualCustomer() {
      return this.$store.getters.manualCustomer;
    },
    isTrial() {
      return this.$store.getters.isTrial;
    },
    trialEndsInDays() {
      return this.$store.getters.trialEndsInDays;
    },
    trialProgress() {
      return this.$store.getters.trialProgress;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    },
    gotoCanny() {
      const authenticatedLink = window.Canny(
        "authenticateCannyLink",
        "https://essaygrader.canny.io/feature-requests"
      );
      window.open(authenticatedLink, "_blank", "noopener"); // open Canny in a new tab
    },
    openCrispChat() {
      window.$crisp.push(["do", "chat:open"]);
    },
  },
};
</script>

<template>
  <b-navbar toggleable="lg" type="dark" variant="info">
    <b-navbar-brand to="/">
      <img src="../../assets/navbar-logo.png" alt="Essay Grader" />
    </b-navbar-brand>

    <b-navbar-toggle v-if="!emptyNavbar" target="nav-collapse" />

    <b-collapse id="nav-collapse" is-nav v-if="!emptyNavbar">
      <b-navbar-nav
        class="d-flex align-items-xl-center align-items-lg-center align-items-sm-start"
      >
        <b-nav-item to="/" class="mr-3">
          <b-icon icon="house-door-fill"></b-icon> Home
        </b-nav-item>
        <b-nav-item to="/essays" class="mr-3">
          <b-icon icon="stack"></b-icon> Essays
        </b-nav-item>
        <b-nav-item to="/rubrics" class="mr-3">
          <b-icon icon="table"></b-icon> Rubrics
        </b-nav-item>
        <b-nav-item to="/insight" class="mr-3">
          <b-icon icon="graph-up"></b-icon> Metrics
        </b-nav-item>
        <b-nav-item-dropdown class="mr-3">
          <template #button-content>
            <b-icon icon="person-square" class="mr-1"></b-icon> Teaching
          </template>
          <b-dropdown-item to="/assignments"> Assignments </b-dropdown-item>
          <b-dropdown-item to="/classes"> Classes </b-dropdown-item>
          <b-dropdown-item to="/summarize"> Summarize </b-dropdown-item>
          <b-dropdown-item to="/ai-detector"> AI Detector </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown class="mr-3">
          <template #button-content>
            <b-icon icon="life-preserver" class="mr-1"></b-icon> Help
          </template>
          <b-dropdown-item href="https://www.essaygrader.ai/help-center"
            >Tutorials & FAQs</b-dropdown-item
          >
          <b-dropdown-item @click="openCrispChat">
            Chat Support
          </b-dropdown-item>
          <b-dropdown-item @click="gotoCanny">
            Request a Feature
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <!-- <b-nav-item to="/ai-detector" class="mr-3">
        <b-nav-item to="/ai-detector" class="mr-3">
          <b-icon icon="upc-scan"></b-icon> AI Detector
        </b-nav-item> -->
        <b-nav-item v-if="false && basicPlan">
          <b-button
            variant="yellow"
            class="text-date"
            @click="trackAndRedirectToUpgrade('upgrade_clicked_bfcm_navbar')"
          >
            🎁 <b>Black Friday</b>: Save up to 65% off our yearly plans 🎉
          </b-button>
        </b-nav-item>
      </b-navbar-nav>

      <b-navbar-nav
        class="ml-auto d-flex align-items-xl-center align-items-lg-center align-items-sm-start"
      >
        <b-nav-item v-if="isTrial" class="mr-2 trial-item">
          <b-button
            variant="yellow"
            size="sm"
            class="text-date"
            @click="trackAndRedirectToUpgrade('upgrade_clicked_bfcm_navbar')"
          >
            Trial ends in <b>{{ trialEndsInDays }} days</b>: Upgrade 🎉
            <b-progress
              :value="trialProgress"
              :max="100"
              height="2px"
              class="mt-1"
              variant="info"
            ></b-progress>
          </b-button>
        </b-nav-item>
        <b-nav-item-dropdown right>
          <template #button-content>
            <b-icon icon="person-circle"></b-icon>
          </template>
          <b-dropdown-item to="/account">Account</b-dropdown-item>
          <b-dropdown-item to="/account/integrations">
            Integrations
          </b-dropdown-item>
          <!-- <b-dropdown-item to="/support">Support</b-dropdown-item> -->
          <!-- <b-dropdown-item @click="gotoCanny">
            Request a Feature
          </b-dropdown-item> -->
          <b-dropdown-item
            href="https://forms.gle/SZ7n3khm5iSRbMii8"
            target="_blank"
          >
            Feedback
          </b-dropdown-item>
          <b-dropdown-item @click="() => $bvModal.show('new-features-modal')">
            New Features
          </b-dropdown-item>
          <b-dropdown-item @click="logout">Logout</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<style lang="css" scoped>
.navbar-brand {
  font-size: 1rem;
}

.trial-item .nav-link {
  padding: 1.5px;
}
</style>

<style lang="css">
:root {
  --navbar-height: 56px;
}
</style>
